<template>
  <!-- 회원리스트 -->
  <div>
    <!-- 해더 -->
    <Header />

    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />

          <!-- 내용 -->
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>주문 상세</h3>
                </div>
                <div class="module-body">
                  <div class="profile-head">
                    <div class="table">
                      <table
                        class="table table-striped table-bordered table-condensed"
                      >
                        <colgroup>
                          <col width="*" />
                          <col width="*" />
                          <col width="*" />
                          <col width="*" />
                          <col width="*" />
                          <col width="*" />
                        </colgroup>
                        <thead>
                          <tr>
                            <th>품목별 주문번호</th>
                            <th>상품명/옵션</th>
                            <th>수량</th>
                            <th>판매가</th>
                            <th>배송비</th>
                            <th>주문상태</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                type="text"
                                id="type"
                                :disabled="true"
                                :value="orderId"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                id="GoodsOption"
                                :disabled="true"
                                :value="magazineTitle"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                id="amount"
                                :disabled="true"
                                :value="orderNum"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                id="price"
                                :disabled="true"
                                :value="orderPrice"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                id="delivery"
                                :disabled="true"
                                :value="deliveryFeeCdName"
                              />
                            </td>
                            <td>
                              <select
                                name=""
                                id=""
                                v-model="orderState"
                                style="width: 140px"
                              >
                                <option
                                  v-for="(gubun, i) in gubunList"
                                  :key="'g' + i"
                                  :value="gubun.cdId"
                                >
                                  {{ gubun.cdName }}
                                </option>
                              </select>
                              <!-- <input
                                type="text"
                                id="OrderStatu"
                                :disabled="true"
                                :value="orderState"
                              /> -->
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="module-body">
                  <h4>포토북 정보</h4>
                  <div class="table">
                    <table
                      class="table table-striped table-bordered table-condensed"
                    >
                      <colgroup>
                        <col width="*" />
                        <col width="*" />
                        <col width="*" />
                        <col width="*" />
                        <col width="*" />
                        <col width="*" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>제목</th>
                          <th>기간</th>
                          <th>사이즈</th>
                          <th>페이지수</th>
                          <th>커버</th>
                          <th>속지</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <input
                              type="text"
                              :disabled="true"
                              :value="magazineTitle"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              :disabled="true"
                              :value="magazineFrom + '~' + magazineTo"
                            />
                          </td>
                          <td>
                            <select
                              name=""
                              id=""
                              v-model="sizeType"
                              :disabled="true"
                              style="width: 140px"
                            >
                              <option
                                v-for="(sz, i) in sizeList"
                                :key="'c' + i"
                                :value="sz.cdId"
                              >
                                {{ sz.cdName }}
                              </option>
                            </select>
                          </td>
                          <td>
                            <input
                              style="width: 50px"
                              type="text"
                              :disabled="true"
                              :value="pageCount"
                            />
                          </td>
                          <td>
                            <select
                              name=""
                              id=""
                              v-model="coverType"
                              :disabled="true"
                              style="width: 140px"
                            >
                              <option
                                v-for="(cover, i) in coverList"
                                :key="'c' + i"
                                :value="cover.cdId"
                              >
                                {{ cover.cdName }}
                              </option>
                            </select>
                          </td>
                          <td>
                            <select
                              name=""
                              id=""
                              v-model="innerType"
                              :disabled="true"
                              style="width: 140px"
                            >
                              <option
                                v-for="(inner, i) in innerList"
                                :key="'i' + i"
                                :value="inner.cdId"
                              >
                                {{ inner.cdName }}
                              </option>
                            </select>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="table">
                    <table
                      class="table table-striped table-bordered table-condensed"
                    >
                      <colgroup>
                        <col width="10%" />
                        <col width="Auto%" />
                      </colgroup>
                      <th>다운로드</th>
                      <td>
                        <a
                          style="cursor: pointer"
                          :href="url"
                          target="_blank"
                          >{{ pdfFile }}</a
                        >
                      </td>
                    </table>
                  </div>
                  <div class="module">
                    <h4>포토북 제작현황</h4>
                    <table
                      class="table table-striped table-bordered table-condensed"
                    >
                      <colgroup>
                        <col width="10%" />
                        <col width="Auto%" />
                      </colgroup>
                      <th>제작현황</th>
                      <td>
                        <div class="controls">
                          <label
                            class="radio inline"
                            v-for="(photo, i) in makeTypeList"
                            :key="'ph' + i"
                          >
                            <input
                              type="radio"
                              name="optionsRadios1"
                              id="optionsRadios1"
                              :value="photo.cdId"
                              :disabled="true"
                            />
                            {{ photo.cdName }}
                          </label>
                        </div>
                      </td>
                    </table>
                  </div>
                  <div class="module">
                    <h4>결제정보</h4>
                    <div class="table">
                      <table
                        class="table table-striped table-bordered table-condensed"
                      >
                        <tr>
                          <th>상품구매금액</th>
                          <th>총 배송비</th>
                          <th>총 실결제 금액</th>
                        </tr>
                        <tr>
                          <td>
                            <input
                              type="text"
                              :disabled="true"
                              :value="orderTotPrice"
                            />
                          </td>
                          <td>
                            <select name="" id="df" v-model="deliveryFee">
                              <option
                                v-for="(fee, i) in deliveryFeeList"
                                :key="'f' + i"
                                :value="fee.cdId"
                                :disabled="modRemoveToggle == false"
                              >
                                {{ fee.cdName }}
                              </option>
                            </select>
                          </td>
                          <td>
                            <input
                              type="text"
                              :disabled="true"
                              :value="
                                Number(orderTotPrice) +
                                Number(deliveryFeeCdName)
                              "
                            />
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <!-- 내용 -->
                  <div class="module">
                    <h4>주문자 정보</h4>
                    <div class="table">
                      <table
                        class="table table-striped table-bordered table-condensed"
                      >
                        <tr>
                          <th>주문자명 (id)</th>
                          <th>연락처</th>
                        </tr>
                        <tr>
                          <td>{{ orderName }}</td>
                          <td>{{ orderPhone }}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="module-body">
                  <h4><span class="icon-chevron-right"></span>수령자 정보</h4>
                  <div class="table">
                    <table class="table table-bordered">
                      <colgroup>
                        <col width="10%" />
                        <col width="30%" />
                      </colgroup>

                      <tr>
                        <th>배송구분</th>
                        <td colspan="1">
                          <label>{{ deleveryType }}</label>
                        </td>
                      </tr>

                      <tr>
                        <th>수령자명</th>
                        <td colspan="1">
                          <label>{{ recipient }} </label>
                        </td>
                      </tr>

                      <tr>
                        <th>연락처</th>
                        <td colspan="1">
                          <label>{{ recipientNum }} </label>
                        </td>
                      </tr>

                      <tr>
                        <th>배송지 주소</th>
                        <td colspan="1">
                          <label>{{ address1 + " (" + address2 + ")" }}</label>
                        </td>
                      </tr>

                      <tr>
                        <th>요청사항</th>
                        <td colspan="1">
                          <label>{{ requestedTerm }} </label>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <!-- //.span9-->
              </div>
            </div>
            <div class="flex_btn alignjust_right">
              <a
                v-show="!modRemoveToggle"
                class="btn btn-large btn-info"
                @click="ClickmodRemoveToggle"
                >수정</a
              >

              <a
                v-show="modRemoveToggle"
                class="btn btn-large btn-info"
                @click="saveOrder"
                >저장</a
              >
              <!-- <a class="btn btn-large btn-danger">삭제</a> -->

              <a class="btn btn-large btn-primary" @click="goBack">목록</a>
            </div>
            <!-- //.content-->
          </div>

          <!-- //.span9-->
        </div>
      </div>
      <!-- //.container-->
    </div>

    <!-- 아래 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
import axios from "axios";
import Vue from "vue";
import Swal from "sweetalert2";

export default {
  name: "Orderdetail",
  components: {
    Header,
    Footer,
    LeftMenu,
  },
  data() {
    return {
      modRemoveToggle: false,

      // order
      address1: "",
      address2: "",
      groupMagazineId: "",
      modDate: "",
      modId: "",
      orderDate: "",
      orderId: "",
      orderName: "",
      orderNum: "",
      orderPhone: "",
      orderPrice: "",
      orderState: "",
      orderTotPrice: "",
      postNo: "",
      recipient: "",
      recipientNum: "",
      regDate: "",
      regId: "",
      requestedTerm: "",
      deliveryFee: "",
      deliveryFeeCdName: "",
      coverType: "",
      innerType: "",
      makeType: "",
      sizeType: "",
      deleveryType: "",
      pdfFile: "",
      pdfFilePath: "",

      // magazine
      magazineTitle: "",
      magazineFrom: "",
      magazineTo: "",
      pageCount: "",

      gubunList: [],
      deliveryFeeList: [],
      makeTypeList: [],
      coverList: [],
      innerList: [],
      sizeList: [],
      deleveryList: [],

      // 파일 url
      url: "",
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
      [2];
    },
    ClickmodRemoveToggle() {
      this.modRemoveToggle = !this.modRemoveToggle;
    },

    saveOrder() {
      var host = Vue.prototype.$hostname;
      var data = this;
      axios
        .put(`${host}/admin/order`, {
          orderId: this.orderId,
          orderState: this.orderState,
          deliveryFee: this.deliveryFee,
        })
        .then(function (response) {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              data.$router.replace("/order/list");
            }
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
  },

  created() {
    var data = this;
    var host = Vue.prototype.$hostname;

    axios
      .get(`${host}/admin/order/${this.$route.params.orderId}`)
      .then(function (response) {
        console.log(response.data.magazine);
        // order
        data.address1 = response.data.order.address1;
        data.address2 = response.data.order.address2;
        data.groupMagazineId = response.data.order.groupMagazineId;
        data.modDate = response.data.order.modDate;
        data.modId = response.data.order.modId;
        data.orderDate = response.data.order.orderDate;
        data.orderId = response.data.order.orderId;
        data.orderName = response.data.order.orderName;
        data.orderNum = response.data.order.orderNum;
        data.orderPhone = response.data.order.orderPhone;
        data.orderPrice = response.data.order.orderPrice;
        data.orderTotPrice = response.data.order.orderTotPrice;
        data.postNo = response.data.order.postNo;
        data.recipient = response.data.order.recipient;
        data.recipientNum = response.data.order.recipientNum;
        data.regDate = response.data.order.regDate;
        data.regId = response.data.order.regId;
        data.requestedTerm = response.data.order.requestedTerm;
        data.deliveryFee = response.data.order.deliveryFee;
        data.coverType = response.data.order.coverType;
        data.innerType = response.data.order.innerType;
        data.makeType = response.data.order.makeType;
        data.sizeType = response.data.order.sizeType;
        data.pdfFile = response.data.magazine.pdfFile;
        data.pdfFilePath = response.data.magazine.pdfFilePath;

        // magazine
        data.magazineTitle = response.data.magazine.magazineTitle;
        data.magazineFrom = response.data.magazine.magazineFrom;
        data.magazineTo = response.data.magazine.magazineTo;
        data.pageCount = response.data.magazine.pageCount;
        data.orderState = response.data.order.orderState;

        // code List
        data.gubunList = response.data.gubun;
        data.deliveryFeeList = response.data.deleveryFee;
        data.makeTypeList = response.data.makeType;
        data.coverList = response.data.cover;
        data.innerList = response.data.inner;
        data.sizeList = response.data.size;
        data.deleveryList = response.data.delevery;

        // download url
        data.url = `${host}/file/download/${response.data.magazine.groupMagazineId}`;

        // 수령자 정보 > 배송구분 코드를 name으로 변환하는 로직
        for (let i = 0; i < response.data.delevery.length; i++) {
          if (
            response.data.delevery[i].cdId === response.data.order.deleveryType
          ) {
            data.deleveryType = response.data.delevery[i].cdName;
          }
        }

        for (let i = 0; i < response.data.deleveryFee.length; i++) {
          if (
            response.data.deleveryFee[i].cdId ===
            response.data.order.deliveryFee
          ) {
            data.deliveryFeeCdName = response.data.deleveryFee[i].cdName;
          }
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: error.response.data.message,
          icon: "error",
          confirmButtonText: "확인",
        });
      });
  },
};
</script>
