export default {
  namespaced: true,
  state: {
    // 사용자 ID
    adminId: "",
  },
  getters: {
    getAdminId(state) {
      return state.adminId;
    },
  },
  mutations: {
    setAdminId(state, payload) {
      state.adminId = payload;
    },
  },
};
