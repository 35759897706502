<template>
  <!-- 메인 페이지 -->
  <div>
    <!-- 해더 -->
    <Header />

    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />

          <!-- 내용 -->
          <!-- //.span3-->
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3 v-show="!ModDelToggle">상위 코드 상세 화면</h3>
                  <h3 v-show="ModDelToggle">상위 코드 수정 화면</h3>
                </div>
                <div class="module-body">
                  <div class="table">
                    <table class="table table-bordered">
                      <colgroup>
                        <col width="20%" />
                        <col width="auto" />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>상위 코드</th>
                          <td>
                            <input
                              type="text"
                              id="TopCode"
                              placeholder=""
                              class="span3"
                              v-model="topCdId"
                              :disabled="!ModDelToggle"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>상위 코드명</th>
                          <td>
                            <input
                              type="text"
                              id="TopCodeNm"
                              placeholder=""
                              class="span3"
                              v-model="topCdName"
                              :disabled="!ModDelToggle"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>사용여부</th>
                          <td>
                            <div class="controls">
                              <label class="radio inline">
                                <input
                                  type="radio"
                                  name="UseYn"
                                  id="UseYn"
                                  value="Y"
                                  v-model="useYn"
                                  :disabled="!ModDelToggle"
                                />
                                사용
                              </label>
                              <label class="radio inline">
                                <input
                                  type="radio"
                                  name="UseYn"
                                  id="UseYn"
                                  value="N"
                                  v-model="useYn"
                                  :disabled="!ModDelToggle"
                                />
                                사용중지
                              </label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="flex_btn alignjust_right">
                    <a
                      v-show="!ModDelToggle"
                      class="btn btn-large btn-info"
                      @click="ClickModDelToggle"
                      >수정</a
                    >

                    <a
                      v-show="ModDelToggle"
                      class="btn btn-large btn-primary"
                      @click="updateTopCode"
                      >저장</a
                    >
                    <a
                      v-show="!ModDelToggle"
                      class="btn btn-large btn-danger"
                      @click="deleteTopCode"
                      >삭제</a
                    >
                    <a class="btn btn-large btn-warning" @click="goBack"
                      >목록</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- //.content-->
          </div>
          <!-- //.span9-->
        </div>
      </div>
      <!-- //.container-->
    </div>

    <!-- 아래 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
import axios from "axios";
import Vue from "vue";
import Swal from "sweetalert2";

export default {
  name: "CodeDetail",
  components: {
    Header,
    Footer,
    LeftMenu,
  },
  data() {
    return {
      ModDelToggle: false,
      CodeActiveToggle: false,
      topCdId: "",
      topCdName: "",
      useYn: "",

      // 수정할 떄 사용하는 분류
      preTopCdId: "",
    };
  },
  methods: {
    goBack() {
      var data = this;
      data.$router.replace("/code/list");
      [2];
    },

    ClickModDelToggle() {
      this.ModDelToggle = !this.ModDelToggle;
    },

    updateTopCode() {
      var data = this;
      var host = Vue.prototype.$hostname;
      if (this.topCdId.length < 2) {
        Swal.fire({
          title: "코드는 2글자 이상 입력해주세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      } else if (this.topCdName.length < 2) {
        Swal.fire({
          title: "코드명은 2글자 이상 입력해주세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      }
      var params = {
        topCdId: this.topCdId,
        preTopCdId: this.preTopCdId,
        topCdName: this.topCdName,
        useYn: this.useYn,
        modId: this.$store.getters["login/getAdminId"],
      };
      axios
        .put(`${host}/admin/top-code`, params, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then(function (response) {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              data.$router.replace("/code/list");
            }
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },

    deleteTopCode() {
      var data = this;
      var host = Vue.prototype.$hostname;
      axios
        .delete(`${host}/admin/top-code/${this.topCdId}`)
        .then(function (response) {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              data.$router.replace("/code/list");
            }
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
  },
  created() {
    if (this.$route.params.pData === undefined) {
      return false;
    }
    this.$router.CodeActiveToggle = false;
    this.topCdId = this.$route.params.pData.topCdId;
    this.preTopCdId = this.$route.params.pData.topCdId;
    this.topCdName = this.$route.params.pData.topCdName;
    this.useYn = this.$route.params.pData.useYn;
  },
};
</script>
