<template>
  <!-- 회원리스트 -->
  <div>
    <!-- 해더 -->
    <Header />

    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>공지사항 등록</h3>
                </div>
                <div class="module-body">
                  <div class="profile-head pb_20">
                    <div class="table">
                      <table class="table table-bordered">
                        <colgroup>
                          <col width="15%" />
                          <col width="85%" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>제목</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="title"
                                  placeholder="5글자 이상 입력해주세요"
                                  class="span3"
                                  v-model="nTitle"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>노출여부</th>
                            <td>
                              <div class="controls">
                                <label class="radio inline">
                                  <input
                                    type="radio"
                                    name="optionsRadios1"
                                    id="open_yn"
                                    value="Y"
                                    checked=""
                                    v-model="nOpnYn"
                                  />
                                  노출증
                                </label>
                                <label class="radio inline">
                                  <input
                                    type="radio"
                                    name="optionsRadios1"
                                    id="open_yn"
                                    value="N"
                                    v-model="nOpnYn"
                                  />
                                  노출중지
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th>첨부파일</th>
                            <td>
                              <div class="controls">
                                <input
                                  id="file"
                                  name="file"
                                  type="file"
                                  accept="image/*, application/pdf, application/zip, .txt, .doc, .docx, .xls, .xlsx, .hwp, .txt, .pptx"
                                  @change="checkFile"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>내용</th>
                            <td>
                              <textarea
                                cols="100"
                                rows="10"
                                style="resize: none"
                                id="nContent"
                                v-model="nContent"
                                placeholder="10글자 이상 입력해주세요"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div class="flex_btn alignjust_right">
                        <a
                          class="btn btn-large btn-warning"
                          @click="createNotice"
                          >저장</a
                        >
                        <a @click="goBack" class="btn btn-large btn-primary"
                          >목록</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- //.content-->
          </div>
          <!-- //.span9-->
          <!-- 내용 -->
        </div>
      </div>
      <!-- //.container-->
    </div>

    <!-- 아래 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
import axios from "axios";
import Swal from "sweetalert2";
import Vue from "vue";

export default {
  name: "NoticeDetail",
  components: {
    Header,
    Footer,
    LeftMenu,
  },
  data() {
    return {
      nTitle: "",
      nOpnYn: "Y",
      nContent: "",

      // 업데이트 할 때 사용되는 변수들
      // 확장자 검사 변수 허용된 확장자면 true
      extensionCheck: false,

      // 사용자가 올린 확장자명
      filesEx: "",
    };
  },
  methods: {
    goBack() {
      var data = this;
      data.$router.replace("/boarder/NoticeList");
      [2];
    },

    // 파일 확장자, 사이즈 체크
    checkFile() {
      // files 로 해당 파일 정보 얻기.
      var file = document.getElementById("file").files;

      // 파일 사이즈
      let maxSize = 15 * 1024 * 1024; // 15MB
      let fileLength = file.length;
      let addFileCapacity = 0;

      // file[0].name 은 파일명 입니다.
      // 정규식으로 확장자 체크
      if (
        !/\.(gif|jpg|jpeg|png|tiff|tif|zip|txt|doc|docx|xls|xlsx|hwp|txt|pptx|pdf)$/i.test(
          file[0].name
        )
      ) {
        Swal.fire({
          title:
            "gif, jpg, jpeg, png, tiff, tif, zip, txt, doc, docx, xls, xlsx, hwp, txt, pptx, pdf 파일만 선택해 주세요.\n\n현재 파일 : " +
            file[0].name,
          icon: "warning",
          confirmButtonText: "확인",
        });
        document.getElementById("file").value = null;
      }

      for (let i = 0; i < fileLength; i++) {
        addFileCapacity = addFileCapacity + file[i].size;
      }
      if (addFileCapacity > maxSize) {
        Swal.fire({
          title: "파일 용량 제한은 15MB입니다.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        document.getElementById("file").value = null;
        return false;
      }

      // 체크를 통과했다면 종료.
      return;
    },

    createNotice() {
      const formData = new FormData();

      // 파일이 없을경우 예외가 발생해서 예외처리를 했음
      let file = null;
      try {
        file = document.getElementById("file").files[0];
        formData.append("file", file);
      } catch {
        console.log("");
      }

      if (this.nTitle.length < 5) {
        Swal.fire({
          title: "제목은 5글자 이상 입력해주세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      } else if (this.nContent.length < 10) {
        Swal.fire({
          title: "내용은 10글자 이상 입력해주세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      }

      var data = this;
      var host = Vue.prototype.$hostname;
      formData.append("file", file);
      formData.append("nTitle", this.nTitle);
      formData.append("nOpnYn", this.nOpnYn);
      formData.append("nContent", this.nContent);
      formData.append("regId", this.$store.getters["login/getAdminId"]);
      axios
        .post(`${host}/admin/notice`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              data.$router.replace("/boarder/NoticeList");
            }
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
  },
};
</script>
