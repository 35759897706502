<template>
  <!-- 회원리스트 -->
  <div>
    <!-- 해더 -->
    <Header />
    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>FAQ 상세</h3>
                </div>
                <div class="module-body">
                  <div class="profile-head pb_20">
                    <div class="table">
                      <table class="table table-bordered">
                        <colgroup>
                          <col width="20%" />
                          <col width="80%" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>구분</th>
                            <td>
                              <div>
                                <select
                                  :disabled="modRemoveToggle == false"
                                  @change="gubonChange($event)"
                                >
                                  <option
                                    v-for="(data, i) in gubunList"
                                    :key="data + i"
                                    :value="data.cdId"
                                    :selected="gubun === data.cdId"
                                  >
                                    {{ data.cdName }}
                                  </option>
                                </select>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>제목</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="f_title"
                                  placeholder=""
                                  class="span3"
                                  v-model="fTitle"
                                  :disabled="modRemoveToggle == false"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>등록일</th>
                            <td>
                              <div>
                                <input
                                  class="w43"
                                  type="date"
                                  id="regDate"
                                  v-bind:value="
                                    $moment(regDate).format('YYYY-MM-DD')
                                  "
                                  :disabled="true"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>노출여부</th>
                            <td>
                              <div class="controls">
                                <label class="radio inline">
                                  <input
                                    type="radio"
                                    name="fOpnYn"
                                    id="fOpnYn"
                                    value="Y"
                                    v-model="fOpnYn"
                                    :disabled="modRemoveToggle == false"
                                  />
                                  노출중
                                </label>
                                <label class="radio inline">
                                  <input
                                    type="radio"
                                    name="fOpnYn"
                                    id="fOpnYn"
                                    value="N"
                                    v-model="fOpnYn"
                                    :disabled="modRemoveToggle == false"
                                  />
                                  노출안함
                                </label>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>첨부파일</th>
                            <td>
                              <div
                                class="controls"
                                v-if="
                                  existFile === false &&
                                  modRemoveToggle === true
                                "
                              >
                                <input
                                  id="file"
                                  name="file"
                                  type="file"
                                  accept="image/*, application/pdf, application/zip, .txt, .doc, .docx, .xls, .xlsx, .hwp, .txt, .pptx"
                                  @change="checkFile"
                                />
                              </div>
                              <div class="controls" v-if="existFile === true">
                                <a
                                  style="cursor: pointer"
                                  :href="url"
                                  target="_blank"
                                  >{{ originalFileId }}</a
                                >
                                <a
                                  style="margin-left: 20px"
                                  v-if="
                                    removeProfile === false &&
                                    modRemoveToggle === true
                                  "
                                  class="btn btn-large btn-danger"
                                  id="btn_del"
                                  @click="
                                    (existFile = false), (removeProfile = true)
                                  "
                                >
                                  파일 삭제 또는 수정
                                </a>
                                <span style="margin-left: 100px"
                                  >파일명을 클릭하면 다운로드 됩니다.</span
                                >
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>내용</th>
                            <td>
                              <textarea
                                cols="110"
                                rows="10"
                                v-model="fContent"
                                :disabled="modRemoveToggle == false"
                                style="resize: none"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="flex_btn alignjust_right">
                        <a
                          v-show="!modRemoveToggle"
                          class="btn btn-large btn-info"
                          @click="ClickmodRemoveToggle"
                          >수정</a
                        >

                        <a
                          v-show="modRemoveToggle"
                          class="btn btn-large btn-info"
                          @click="updateFaq"
                          >저장</a
                        >
                        <a class="btn btn-large btn-danger" @click="deleteFaq"
                          >삭제</a
                        >

                        <a class="btn btn-large btn-primary" @click="goBack"
                          >목록</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- //.content-->
          </div>
          <!-- //.span9-->
          <!-- 내용 -->
        </div>
      </div>
      <!-- //.container-->
    </div>
    <!-- 아래 -->
    <Footer />
  </div>
</template>
<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
import axios from "axios";
import Vue from "vue";
import Swal from "sweetalert2";

export default {
  name: "FAQDetail",
  components: {
    Header,
    Footer,
    LeftMenu,
  },
  data() {
    return {
      modRemoveToggle: false,
      fSeq: "",
      fTitle: "",
      fContent: "",
      fileNm: "",
      filePath: "",
      fOpnYn: "",
      regId: "",
      regDate: "",
      modId: "",
      modDate: "",
      originalFileId: "",
      gubun: "",

      // 파일 다운로드 url
      url: "",

      existFile: false,
      removeProfile: false,

      // 업데이트 할 때 사용되는 변수들
      // 확장자 검사 변수 허용된 확장자면 true
      extensionCheck: false,

      // 사용자가 올린 확장자명
      filesEx: "",

      // 구분
      gubunList: [],
    };
  },
  methods: {
    goBack() {
      var data = this;
      data.$router.replace("/boarder/FAQList");
      [2];
    },

    ClickmodRemoveToggle() {
      this.modRemoveToggle = !this.modRemoveToggle;
    },

    gubonChange(event) {
      this.gubun = event.target.value;
    },

    // 파일 확장자, 사이즈 체크
    checkFile() {
      // files 로 해당 파일 정보 얻기.
      var file = document.getElementById("file").files;

      // 파일 사이즈
      let maxSize = 15 * 1024 * 1024; // 15MB
      let fileLength = file.length;
      let addFileCapacity = 0;

      // file[0].name 은 파일명 입니다.
      // 정규식으로 확장자 체크
      if (
        !/\.(gif|jpg|jpeg|png|tiff|tif|zip|txt|doc|docx|xls|xlsx|hwp|txt|pptx|pdf)$/i.test(
          file[0].name
        )
      ) {
        Swal.fire({
          title:
            "gif, jpg, jpeg, png, tiff, tif, zip, txt, doc, docx, xls, xlsx, hwp, txt, pptx, pdf 파일만 선택해 주세요.\n\n현재 파일 : " +
            file[0].name,
          icon: "warning",
          confirmButtonText: "확인",
        });
        document.getElementById("file").value = null;
      }

      for (let i = 0; i < fileLength; i++) {
        addFileCapacity = addFileCapacity + file[i].size;
      }
      if (addFileCapacity > maxSize) {
        Swal.fire({
          title: "파일 용량 제한은 15MB입니다.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        document.getElementById("file").value = null;
        return false;
      }

      // 체크를 통과했다면 종료.
      return;
    },

    updateFaq() {
      const formData = new FormData();

      // 파일이 없을경우 예외가 발생해서 예외처리를 했음
      let file = null;
      try {
        file = document.getElementById("file").files[0];
        formData.append("file", file);
      } catch {
        console.log("");
      }
      if (this.fTitle.length < 5) {
        Swal.fire({
          title: "제목은 5글자 이상 입력해주세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      } else if (this.fContent.length < 10) {
        Swal.fire({
          title: "내용은 10글자 이상 입력해주세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      }

      formData.append("fSeq", this.fSeq);
      formData.append("fTitle", this.fTitle);
      formData.append("fContent", this.fContent);
      formData.append("fileNm", this.fileNm);
      formData.append("filePath", this.filePath);
      formData.append("fOpnYn", this.fOpnYn);
      formData.append("existFile", this.existFile);
      formData.append("originalFileId", this.originalFileId);
      formData.append("removeProfile", this.removeProfile);
      formData.append("gubun", this.gubun);
      formData.append("modId", this.$store.getters["login/getAdminId"]);

      var data = this;
      var host = Vue.prototype.$hostname;
      axios
        .put(`${host}/admin/faq`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              data.$router.replace("/boarder/FAQList");
            }
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },

    deleteFaq() {
      var data = this;
      var host = Vue.prototype.$hostname;
      axios
        .delete(`${host}/admin/faq/${encodeURI(this.fSeq)}`)
        .then(function (response) {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              data.$router.replace("/boarder/FAQList");
            }
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
  },
  created() {
    var host = Vue.prototype.$hostname;
    this.fSeq = this.$route.params.pData.fseq;
    this.fTitle = this.$route.params.pData.ftitle;
    this.fContent = this.$route.params.pData.fcontent;
    this.fileNm = this.$route.params.pData.fileNm;
    this.filePath = this.$route.params.pData.filePath;
    this.fOpnYn = this.$route.params.pData.fopnYn;
    this.regId = this.$route.params.pData.regId;
    this.regDate = this.$route.params.pData.regDate;
    this.modId = this.$route.params.pData.modId;
    this.modDate = this.$route.params.pData.modDate;
    this.originalFileId = this.$route.params.pData.originalFileId;
    this.existFile =
      this.$route.params.pData.originalFileId !== null ? true : false;
    this.gubun = this.$route.params.pData.gubun;
    this.gubunList = this.$route.params.gubunList;
    this.url = `${host}/file/download/faq/${this.$route.params.pData.fseq}`;
  },
};
</script>
