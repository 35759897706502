<template>
  <!-- 회원리스트 -->
  <div>
    <!-- 해더 -->
    <Header />

    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />

          <!-- 내용 -->
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>주문 등록</h3>
                </div>
                <div class="module-body">
                  <div class="profile-head">
                    <div class="table">
                      <table
                        class="
                          table table-striped table-bordered table-condensed
                        "
                      >
                        <colgroup>
                          <col width="10%" />
                          <col width="10%" />
                          <col width="10%" />
                          <col width="10%" />
                          <col width="10%" />
                          <col width="10%" />
                        </colgroup>
                        <thead>
                          <tr>
                            <th>품목별 주문번호</th>
                            <th>상품명/옵션</th>
                            <th>수량</th>
                            <th>판매가</th>
                            <th>배송비</th>
                            <th>주문상태</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><input type="text" id="type" Value="1" /></td>
                            <td>
                              <input
                                type="text "
                                id="GoodsOption"
                                Value="aaa@naver.com"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                id="amount"
                                class="w35"
                                Value="홍길동"
                              />
                            </td>
                            <td>
                              <input type="text" id="price" Value="10000" />
                            </td>
                            <td>
                              <input type="text" id="delivery" Value="200000" />
                            </td>
                            <td>
                              <input
                                type="text"
                                id="OrderStatu"
                                value="상품 준비"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="module-body">
                  <h4>포토북 정보</h4>
                  <div class="table">
                    <table
                      class="table table-striped table-bordered table-condensed"
                    >
                      <colgroup>
                        <col width="5%" />
                        <col width="20%" />
                        <col width="20%" />
                        <col width="20%" />
                        <col width="20%" />
                        <col width="5%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>제목</th>
                          <th>기간</th>
                          <th>사이즈</th>
                          <th>페이지수/th></th>
                          <th>커버</th>
                          <th>속지</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><input type="text" value="1" /></td>
                          <td><input type="text" value="aaa@naver.com" /></td>
                          <td><input type="text" value="홍길동" /></td>
                          <td><input type="text" value="2020.01.31" /></td>
                          <td><input type="text" value="2020.01.31" /></td>
                          <td><input type="text" value="이용중" /></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="table">
                    <table
                      class="table table-striped table-bordered table-condensed"
                    >
                      <colgroup>
                        <col width="10%" />
                        <col width="Auto%" />
                      </colgroup>
                      <th>다운로드</th>
                      <td><label>adfadf.PDF </label></td>
                    </table>
                  </div>
                  <div class="module">
                    <h4>포토북 제작현황</h4>
                    <table
                      class="table table-striped table-bordered table-condensed"
                    >
                      <colgroup>
                        <col width="10%" />
                        <col width="Auto%" />
                      </colgroup>
                      <th>제작현황</th>
                      <td>
                        <div class="controls">
                          <label class="radio inline">
                            <input
                              type="radio"
                              name="optionsRadios1"
                              id="optionsRadios1"
                              value="option1"
                              checked=""
                            />
                            제작 대기중
                          </label>
                          <label class="radio inline">
                            <input
                              type="radio"
                              name="optionsRadios1"
                              id="optionsRadios2"
                              value="option2"
                            />
                            제작접수
                          </label>
                          <label class="radio inline">
                            <input
                              type="radio"
                              name="optionsRadios1"
                              id="optionsRadios3"
                              value="option3"
                            />
                            제작중
                          </label>
                          <label class="radio inline">
                            <input
                              type="radio"
                              name="optionsRadios1"
                              id="optionsRadios3"
                              value="option3"
                            />
                            제작완료
                          </label>
                        </div>
                      </td>
                    </table>
                  </div>
                  <div class="module">
                    <h4>포토북 정보</h4>
                    <div class="table">
                      <table
                        class="
                          table table-striped table-bordered table-condensed
                        "
                      >
                        <tr>
                          <th>상품구매금액</th>
                          <th>총 배송비</th>
                          <th>총 실결제 금액</th>
                        </tr>
                        <tr>
                          <td><input type="text" value="10,000" /></td>
                          <td><input type="text" value="10,000" /></td>
                          <td><input type="text" value="10,000" /></td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="flex_btn alignjust_right">
                  <a class="btn btn-large btn-warning">저장</a>
                  <a @click="goBack" class="btn btn-large btn-primary">목록</a>
                </div>
              </div>
            </div>
            <!-- //.content-->
          </div>
          <!-- //.span9-->
        </div>
      </div>
      <!-- //.container-->
    </div>

    <!-- 아래 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";

export default {
  name: "Orderdetail",
  components: {
    Header,
    Footer,
    LeftMenu,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
      [2];
    },
  },
};
</script>