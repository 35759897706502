<template>
  <!-- 회원리스트 -->
  <div>
    <!-- 해더 -->
    <Header />

    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />
          <!-- 내용 -->
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>약관 상세</h3>
                </div>
                <div class="module-body">
                  <div class="profile-head pb_20">
                    <div class="table">
                      <table class="table table-bordered">
                        <colgroup>
                          <col width="20%" />
                          <col width="80%" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>분류</th>
                            <td id="type">
                              <div>
                                <select
                                  @change="gubonChange($event)"
                                  v-model="gubun"
                                  :disabled="modRemoveToggle == false"
                                >
                                  <option
                                    v-for="(data, i) in gubunList"
                                    :key="data + i"
                                    :value="data.cdId"
                                  >
                                    {{ data.cdName }}
                                  </option>
                                </select>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th>제목</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="title"
                                  placeholder=""
                                  class="span3"
                                  v-model="title"
                                  :readonly="modRemoveToggle == false"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>내용</th>
                            <td>
                              <textarea
                                cols="110"
                                rows="10"
                                id="content"
                                v-model="content"
                                :readonly="modRemoveToggle == false"
                                style="resize: none"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="flex_btn alignjust_right">
                        <a
                          v-show="!modRemoveToggle"
                          class="btn btn-large btn-info"
                          @click="ClickmodRemoveToggle"
                          >수정</a
                        >

                        <a
                          v-show="modRemoveToggle"
                          class="btn btn-large btn-info"
                          @click="updatePolicy"
                          >저장</a
                        >
                        <a
                          class="btn btn-large btn-danger"
                          @click="deletePolicy"
                          >삭제</a
                        >

                        <a class="btn btn-large btn-primary" @click="goBack"
                          >목록</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- //.content-->
          </div>
          <!-- //.span9-->

          <!-- 내용 -->
        </div>
      </div>
      <!-- //.container-->
    </div>

    <!-- 아래 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
import axios from "axios";
import Swal from "sweetalert2";
import Vue from "vue";

export default {
  name: "PolicyDetail",
  components: {
    Header,
    Footer,
    LeftMenu,
  },

  data() {
    return {
      modRemoveToggle: false,
      no: "",
      title: "",
      content: "",
      gubun: "",
      gubunList: [],
    };
  },

  methods: {
    goBack() {
      this.$router.push("/policy/list");
    },

    ClickmodRemoveToggle() {
      this.modRemoveToggle = !this.modRemoveToggle;
    },

    gubonChange(event) {
      this.gubun = event.target.value;
    },

    updatePolicy() {
      var host = Vue.prototype.$hostname;
      var data = this;
      var params = {
        no: this.no,
        title: this.title,
        gubun: this.gubun,
        content: this.content,
      };
      axios
        .put(`${host}/admin/policy`, params, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then(function (response) {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              data.$router.replace("/policy/list");
            }
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },

    deletePolicy() {
      var host = Vue.prototype.$hostname;
      var data = this;
      axios
        .delete(`${host}/admin/policy/${this.no}`)
        .then(function (response) {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              data.$router.replace("/policy/list");
            }
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
  },

  created() {
    if (this.$route.params.policy === undefined) {
      return false;
    }

    this.no = this.$route.params.policy.no;
    this.gubunList = this.$route.params.gubunList;
    this.title = this.$route.params.policy.title;
    this.content = this.$route.params.policy.content;
    this.gubun = this.$route.params.policy.gubun;
  },
};
</script>
