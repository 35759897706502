export default {
  namespaced: true,
  state: {
    cpn: 1,
    babyName: "",
    parentsName: "",
    openYn: "all",
    startRegDate: "",
    endRegDate: "",
  },
  getters: {
    getCpn(state) {
      return state.cpn;
    },
    getBabyName(state) {
      return state.babyName;
    },
    getParentsName(state) {
      return state.parentsName;
    },
    getOpenYn(state) {
      return state.openYn;
    },
    getStartRegDate(state) {
      return state.startRegDate;
    },
    getEndRegDate(state) {
      return state.endRegDate;
    },
  },
  mutations: {
    setCpn(state, payload) {
      state.cpn = payload;
    },
    setBabyName(state, payload) {
      state.babyName = payload;
    },
    setParentsName(state, payload) {
      state.parentsName = payload;
    },
    setOpenYn(state, payload) {
      state.openYn = payload;
    },
    setStartRegDate(state, payload) {
      state.startRegDate = payload;
    },
    setEndRegDate(state, payload) {
      state.endRegDate = payload;
    },
  },
};
