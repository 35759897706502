<template>
  <!-- 회원리스트 -->
  <div>
    <!-- 해더 -->
    <Header />
    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />
          <!-- 내용 -->
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>매거진 상세</h3>
                </div>
                <div class="module-body">
                  <h4>등록자 정보</h4>
                  <div class="profile-head pb_20">
                    <div class="table">
                      <table class="table table-bordered">
                        <colgroup>
                          <col width="15%" />
                          <col width="30%" />
                          <col width="15%" />
                          <col width="40%" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>양육자 이름</th>
                            <td>
                              <label type="text" id="user_name">
                                {{ userName }}
                              </label>
                            </td>
                            <th>이메일</th>
                            <td>
                              <label type="text" id="user_email">
                                {{ userEmail }}
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="module-body">
                  <h4>포토북</h4>
                  <div class="profile-head pb_20">
                    <div class="table">
                      <table class="table table-bordered">
                        <colgroup>
                          <col width="15%" />
                          <col width="30%" />
                          <col width="15%" />
                          <col width="40%" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>양육자 이름</th>
                            <td>
                              <label type="text " id="user_name">
                                {{ userName }}
                              </label>
                            </td>
                            <th>이메일</th>
                            <td>
                              <label type="text" id="user_email">
                                {{ userEmail }}
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <th>사이즈</th>
                            <td>
                              <label type="text " id="Size">
                                {{ size }}
                              </label>
                            </td>
                            <th>페이지 수</th>
                            <td>
                              <label type="text " id="PageAmount">
                                {{ pageCount }}
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="module-body">
                  <h4>사진/동영상</h4>
                  <div class="flex_btn alignjust_center mt_20">
                    <div class="col-sm-4" style="margin-right: 50%">
                      <button
                        @click.prevent="slidePrev"
                        type="button"
                        class="btn_type3 btn_rndtype3"
                      >
                        <template v-if="carouselData == 0"> 표지 </template>
                        <template v-else>
                          {{ carouselData - 1 }}페이지
                        </template>
                      </button>
                    </div>
                    <div class="col-sm-4"><!-- blank --></div>
                    <div class="col-sm-4" v-if="carouselData <= pageCount">
                      <button
                        @click.prevent="slideNext"
                        type="button"
                        class="btn_type3 btn_rndtype3"
                      >
                        <template v-if="carouselData == 0"> 뒷면 </template>
                        <template v-else> {{ carouselData }}페이지 </template>
                      </button>
                    </div>
                  </div>
                  <div class="wrap_diarypreviewbox">
                    <hooper
                      ref="carousel"
                      @slide="updateCarousel"
                      :progress="true"
                      group="group1"
                      :itemsToShow="2"
                      :itemsToSlide="2"
                    >
                      <!-- 표지 -->
                      <slide>
                        <div class="bookBox diarypreviewbox paper_lt">
                          <div class="imgBox type1">
                            <img
                              style="width: 300px; height: 200px"
                              @click="setCoverImage(coverInfo.encodedImage)"
                              v-bind:src="
                                'data:image/jpeg;base64,' +
                                coverInfo.encodedImage
                              "
                              alt=""
                              @load="onImgLoad"
                            />
                          </div>
                          <!-- <div>
                            <textarea
                              name="diaryMemo"
                              class="form-control"
                              cols="30"
                              rows="3"
                              readonly
                              style="background: white"
                            ></textarea>
                          </div> -->
                        </div>
                      </slide>
                      <!-- //표지 -->
                      <!-- 뒷면 -->
                      <slide>
                        <div class="bookBox diarypreviewbox">
                          <div class="imgBox type1">
                            <img
                              style="opacity: 0; width: 300px; height: 200px"
                              src="@/assets/images/temp/bg_lightgray.jpg"
                              alt=""
                              @load="onImgLoad"
                            />
                          </div>
                        </div>
                      </slide>
                      <!-- //뒷면 -->
                      <slide
                        v-for="(file, i) in fileList"
                        :key="file + i"
                        :class="imgBoxType"
                      >
                        <div class="bookBox diarypreviewbox">
                          <div>
                            <img
                              style="width: 300px; height: 200px"
                              v-if="file.extension === 'img'"
                              v-bind:src="
                                'data:image/jpeg;base64,' + file.imageBytes
                              "
                              @load="onImgLoad"
                            />
                            <video
                              v-else
                              style="width: 300px; height: 200px"
                              controls="controls"
                              controlsList="nodownload"
                              v-bind:src="`${host}/admin/magazine/video?fileName=${encodeURI(
                                file.diaryPhotoPath
                              )}/${encodeURI(file.diaryPhotoId)}`"
                            ></video>
                          </div>
                          <div>
                            <textarea
                              name="diaryMemo"
                              class="form-control"
                              readonly
                              :value="file.diaryMemo"
                              style="background: white"
                            ></textarea>
                          </div>
                        </div>
                      </slide>
                      <!-- <hooper-navigation slot="hooper-addons"></hooper-navigation> -->
                    </hooper>
                  </div>
                </div>
                <div class="module-body">
                  <div class="profile-head pb_20">
                    <div class="table">
                      <table class="table">
                        <colgroup>
                          <col width="15%" />
                          <col width="30%" />
                          <col width="15%" />
                          <col width="40%" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>상태변경</th>
                            <td>
                              <div class="controls">
                                <label class="radio inline">
                                  <input
                                    type="radio"
                                    name="optionsRadios1"
                                    id="open_yn"
                                    value="Y"
                                    v-model="openYn"
                                    :disabled="modRemoveToggle == false"
                                  />
                                  노출중
                                </label>
                                <label class="radio inline">
                                  <input
                                    type="radio"
                                    name="optionsRadios1"
                                    id="open_yn"
                                    value="N"
                                    v-model="openYn"
                                    :disabled="modRemoveToggle == false"
                                  />
                                  노출중지
                                </label>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="flex_btn alignjust_right">
                    <a
                      v-show="!modRemoveToggle"
                      class="btn btn-large btn-info"
                      @click="ClickmodRemoveToggle"
                      >수정</a
                    >

                    <a
                      v-show="modRemoveToggle"
                      class="btn btn-large btn-info"
                      @click="updateMagazine"
                      >저장</a
                    >
                    <a class="btn btn-large btn-danger" @click="deleteMagazine"
                      >삭제</a
                    >

                    <a class="btn btn-large btn-primary" @click="goBack"
                      >목록</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- //.content-->
          </div>
          <!-- //.span9-->
        </div>
      </div>
      <!-- //.container-->
    </div>
    <!-- 아래 -->
    <Footer />
  </div>
</template>
<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
import axios from "axios";
import Vue from "vue";
import Swal from "sweetalert2";
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";
export default {
  name: "MagazineDetail",
  components: {
    Header,
    Footer,
    LeftMenu,
    Hooper,
    Slide,
  },
  data() {
    return {
      modRemoveToggle: false,
      fonts: require("../../services/constant.js")._fonts(),

      magazineId: "",
      userName: "",
      userEmail: "",
      openYn: "",
      pageCount: "",
      groupMagazineId: "",
      innerDesign: "",
      size: "",

      preOpenYn: "",

      fileList: [],

      host: Vue.prototype.$hostname,

      coverInfo: {
        encodedImage: "",
      },
      carouselData: 0,

      imgBoxType: "imgBox type1",
      textBoxType: "textBox type1",
    };
  },
  methods: {
    goBack() {
      var data = this;
      data.$router.replace("/magazine/list");
      [2];
    },

    ClickmodRemoveToggle() {
      this.modRemoveToggle = !this.modRemoveToggle;
    },

    updateMagazine() {
      if (this.openYn === this.preOpenYn) {
        this.modRemoveToggle = !this.modRemoveToggle;
        return false;
      }

      var host = Vue.prototype.$hostname;
      var data = this;
      axios
        .put(`${host}/admin/magazine`, {
          magazineId: this.magazineId,
          openYn: this.openYn,
          modId: this.$store.getters["login/getAdminId"],
        })
        .then(function (response) {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            confirmButtonText: "확인",
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
      data.$router.replace("/magazine/list");
    },

    deleteMagazine() {
      var host = Vue.prototype.$hostname;
      var data = this;
      axios
        .delete(`${host}/admin/magazine?magazineId=${this.magazineId}`)
        .then(function (response) {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              data.$router.replace("/magazine/list");
            }
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },

    getCoverImage() {
      return axios
        .get(`${this.$hostname}/magazine/thumbnail/image`, {
          params: {
            groupMagazineId: this.groupMagazineId,
          },
        })
        .then((response) => {
          return response ? response.data : null;
        });
    },

    // 매거진 구성 사진 얻어오기
    getImage() {
      let formData = this.formData;
      // parameter(babyId, groupMagazineId)
      return axios
        .post(`${this.$hostname}/diary/preview/thumbnail/image`, formData)
        .then((response) => {
          return response ? response.data : null;
        });
    },
    // 배경속지 ID를 가져온다.
    getBackgId() {
      return axios
        .post(`${this.$hostname}/magazine/find`, this.formData)
        .then((response) => {
          return response ? response.data : null;
        });
    },
    // 이미지가 로드되고나서.
    onImgLoad() {
      this.isLoaded = true;

      if (this.isLoaded == true) {
        // container
        let containerDiv = document.getElementsByClassName("container")[0];
        // containerDiv.clientWidth - padding(15 + 15) + margin(10+10)
        let coverWidth = containerDiv.clientWidth - 10;
        // width : height = 8 : 5, height = width*5/8;
        let coverHeight = (coverWidth * 5) / 8;

        let sectionDiv = document.getElementsByClassName("hooper")[0];
        sectionDiv.style.height = coverHeight + "px";

        // 선택한 앨범 배경색으로 바꾸기
        let bookBox = document.getElementsByClassName("bookBox");
        let bookBoxLength = bookBox.length;

        for (let i = 0; i < bookBoxLength; i++) {
          if (i % 2 == 0) {
            bookBox[i].className = "bookBox diarypreviewbox paper_lt";
          } else {
            bookBox[i].className = "bookBox diarypreviewbox paper_rt";
          }
        }
        // imgBox radius
        let imgBox = document.querySelectorAll(".imgBox");
        let imgBoxLength = imgBox.length;

        imgBox[0].style.borderRadius = "20px";
        imgBox[1].style.borderRadius = "20px";

        for (let i = 2; i < imgBoxLength; i++) {
          imgBox[i].style.borderTopLeftRadius = "20px";
          imgBox[i].style.borderTopRightRadius = "20px";
        }
      }
    },
    slidePrev() {
      this.$refs.carousel.slidePrev();
    },
    slideNext() {
      if (this.carouselData < this.pageCount) {
        this.$refs.carousel.slideNext();
      } else {
        return;
      }
    },
    updateCarousel(payload) {
      this.carouselData = payload.currentSlide;
    },
    setCoverImage(encodedImage) {
      this.detailInfo.diaryId = "";
      this.detailInfo.diaryMemo = "";
      this.detailInfo.encodedImage = encodedImage;
    },
    setDetailInfo(diaryId, diaryMemo) {
      this.detailInfo.diaryId = diaryId;
      this.detailInfo.encodedImage = this.coverInfo.encodedImage;
      this.detailInfo.diaryMemo = diaryMemo;
    },
  },
  created() {
    let selectBgId;

    if (
      this.$route.params.innerDesign == "1" ||
      this.$route.params.innerDesign == "1_1"
    ) {
      // type1: dark gray color
      selectBgId = "type1";
    } else if (
      this.$route.params.innerDesign == "2" ||
      this.$route.params.innerDesign == "2_1"
    ) {
      // type2: normal gray color
      selectBgId = "type2";
    } else if (
      this.$route.params.innerDesign == "3" ||
      this.$route.params.innerDesign == "3_1"
    ) {
      // type3: light gray color
      selectBgId = "type3";
    } else if (
      this.$route.params.innerDesign == "4" ||
      this.$route.params.innerDesign == "4_1"
    ) {
      // type4: light green color
      selectBgId = "type4";
    } else if (
      this.$route.params.innerDesign == "5" ||
      this.$route.params.innerDesign == "5_1"
    ) {
      // type5: light pink color
      selectBgId = "type5";
    } else if (
      this.$route.params.innerDesign == "6" ||
      this.$route.params.innerDesign == "6_1"
    ) {
      // type6: light purple color
      selectBgId = "type6";
    }

    this.imgBoxType = "imgBox " + selectBgId;
    this.textBoxType = "textBox " + selectBgId;

    if (this.$route.params.magazineId === undefined) {
      return false;
    }

    this.magazineId = this.$route.params.magazineId;
    this.userName = this.$route.params.userName;
    this.userEmail = this.$route.params.userEmail;
    this.openYn = this.$route.params.openYn;
    this.preOpenYn = this.$route.params.openYn;
    this.pageCount = this.$route.params.pageCount;
    this.groupMagazineId = this.$route.params.groupMagazineId;
    this.size = this.$route.params.size;
    this.coverInfo.encodedImage = this.$route.params.encodedImage;
    this.innerDesign = this.$route.params.innerDesign;

    var host = Vue.prototype.$hostname;
    var data = this;
    axios
      .get(`${host}/admin/magazine/${this.$route.params.magazineId}`)
      .then(function (response) {
        console.log(response);
        data.fileList = response.data.payload;
      })
      .catch(function (error) {
        if (error.response.status) {
          // 권한이 없을 경우
          if (
            error.response.status === 403 &&
            error.response.data.message === "Forbidden"
          ) {
            Swal.fire({
              title: data.$store.getters["customer/getNoAuthority"],
              icon: "error",
              confirmButtonText: "확인",
            });
            data.$router.push("/");
            return false;

            // 로그인을 안했을 경우
          } else if (
            error.response.status === 403 &&
            data.$store.getters["token/getToken"].length === 0
          ) {
            Swal.fire({
              title: data.$store.getters["customer/getNoLoginMessage"],
              icon: "error",
              confirmButtonText: "확인",
            });
            data.$router.push("/login");
            return false;

            // 새로고침을 눌렀을 경우
          } else if (
            error.response.status === 403 &&
            data.$store.getters["token/getToken"].length !== 0
          ) {
            data.$router.push("/login");
            return false;
          }
        }
        // 새로고침 했을 때
        if (error.response.status === 400) {
          return false;
        }
        Swal.fire({
          title: error.response.data.message,
          icon: "error",
          confirmButtonText: "확인",
        });
      });
  },
};
</script>
<style>
.hooper-slide {
  background-color: #e2e2e2;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 10px;
}
.form-control {
  margin-top: 50px;
  width: 100%;
  height: 100px;
}
</style>
