<template>
  <!-- 회원리스트 -->

  <div>
    <!-- 해더 -->

    <Header />

    <!-- /navbar -->

    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->

          <LeftMenu />

          <!-- 내용 -->

          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>아기 수정화면</h3>
                </div>

                <div class="module-body">
                  <h4>
                    <span class="icon-chevron-right"></span>
                    아기 수정
                  </h4>

                  <div class="table">
                    <table class="table table-bordered">
                      <colgroup>
                        <col width="15%" />
                        <col width="85%" />
                      </colgroup>

                      <tbody>
                        <tr>
                          <th>프로필 이미지</th>

                          <td colspan="3">
                            <img
                              v-if="removeProfile == 'N'"
                              v-bind:src="
                                'data:image/jpeg;base64,' + imageBytes
                              "
                              alt="사진"
                            />
                            <input
                              v-if="removeProfile === 'Y'"
                              :disabled="modRemoveToggle == false"
                              @change="checkFile(this)"
                              id="file"
                              name="file"
                              type="file"
                              accept="image/gif, image/png, image/jpg, image/jpeg, image/tiff"
                            />
                            <a
                              v-if="
                                removeProfile === 'N' &&
                                modRemoveToggle === true
                              "
                              class="btn btn-large btn-danger"
                              id="img_del"
                              @click="
                                removeProfile === 'N'
                                  ? (removeProfile = 'Y')
                                  : (removeProfile = 'N')
                              "
                            >
                              이미지 삭제 또는 수정
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <th>양육자 이메일</th>

                          <td>
                            <input
                              :disabled="modRemoveToggle == false"
                              type="text"
                              id="email"
                              v-model="parentsEmail"
                              class="w60"
                              placeholder="양육자 이메일을 검색해 주세요."
                              @keyup.enter="searchParents"
                              @change="emailCheck = false"
                            />
                            <a
                              @click="searchParents"
                              v-if="modRemoveToggle === true"
                              style="
                                border-radius: 5px;
                                margin-left: 15px;
                                border: 1px solid skyblue;
                                background-color: skyblue;
                                color: black;
                                padding: 5px;
                              "
                              >찾기</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <th>양육자 이름</th>

                          <td>
                            <span>
                              {{ parentsName }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>아기이름</th>
                          <td>
                            <input
                              :disabled="modRemoveToggle == false"
                              type="text"
                              id="babyName"
                              v-model="babyName"
                              placeholder="한글만 입력이 가능합니다."
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>성별</th>
                          <td>
                            <div class="controls">
                              <label class="radio inline">
                                <input
                                  :disabled="modRemoveToggle == false"
                                  type="radio"
                                  name="babyGender"
                                  id="babyGender"
                                  value="01"
                                  v-model="babyGender"
                                />
                                남아
                              </label>

                              <label class="radio inline">
                                <input
                                  :disabled="modRemoveToggle == false"
                                  type="radio"
                                  name="babyGender"
                                  id="babyGender"
                                  value="02"
                                  v-model="babyGender"
                                />
                                여아
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr></tr>
                        <tr>
                          <th>양육자 구분</th>
                          <td>
                            <div>
                              <select
                                :disabled="modRemoveToggle == false"
                                @change="gubonChange($event)"
                                style="width: 100px"
                                v-model="careGubun"
                              >
                                <option
                                  v-for="(data, i) in gubunList"
                                  :key="data + i"
                                  :value="data.cdId"
                                >
                                  {{ data.cdName }}
                                </option>
                              </select>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>태어난 날</th>
                          <td>
                            <input
                              :disabled="modRemoveToggle == false"
                              type="date"
                              v-model="babyBirth"
                              id="babyBirth"
                              style="width: 100px"
                            />
                            <select
                              :disabled="modRemoveToggle == false"
                              name="hour"
                              id="birthtimeselect2"
                              style="width: 100px"
                              v-model="hour"
                            >
                              <option value="">시간</option>
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="13">13</option>
                              <option value="14">14</option>
                              <option value="15">15</option>
                              <option value="16">16</option>
                              <option value="17">17</option>
                              <option value="18">18</option>
                              <option value="19">19</option>
                              <option value="20">20</option>
                              <option value="21">21</option>
                              <option value="22">22</option>
                              <option value="23">23</option>
                            </select>

                            <select
                              name="minute"
                              id="birthtimeselect3"
                              style="width: 100px"
                              :disabled="modRemoveToggle == false"
                              v-model="minute"
                            >
                              <option value="">분</option>
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="13">13</option>
                              <option value="14">14</option>
                              <option value="15">15</option>
                              <option value="16">16</option>
                              <option value="17">17</option>
                              <option value="18">18</option>
                              <option value="19">19</option>
                              <option value="20">20</option>
                              <option value="21">21</option>
                              <option value="22">22</option>
                              <option value="23">23</option>
                              <option value="24">24</option>
                              <option value="25">25</option>
                              <option value="26">26</option>
                              <option value="27">27</option>
                              <option value="28">28</option>
                              <option value="29">29</option>
                              <option value="30">30</option>
                              <option value="31">31</option>
                              <option value="32">32</option>
                              <option value="33">33</option>
                              <option value="34">34</option>
                              <option value="35">35</option>
                              <option value="36">36</option>
                              <option value="37">37</option>
                              <option value="38">38</option>
                              <option value="39">39</option>
                              <option value="40">40</option>
                              <option value="41">41</option>
                              <option value="42">42</option>
                              <option value="43">43</option>
                              <option value="44">44</option>
                              <option value="45">45</option>
                              <option value="46">46</option>
                              <option value="47">47</option>
                              <option value="48">48</option>
                              <option value="49">49</option>
                              <option value="50">50</option>
                              <option value="51">51</option>
                              <option value="52">52</option>
                              <option value="53">53</option>
                              <option value="54">54</option>
                              <option value="55">55</option>
                              <option value="56">56</option>
                              <option value="57">57</option>
                              <option value="58">58</option>
                              <option value="59">59</option>
                            </select>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="flex_btn alignjust_right">
                    <a
                      v-show="!modRemoveToggle"
                      class="btn btn-large btn-info"
                      @click="ClickmodRemoveToggle"
                    >
                      수정
                    </a>
                    <a
                      v-show="modRemoveToggle"
                      class="btn btn-large btn-warning"
                      @click="updateBaby"
                      >저장</a
                    >
                    <a
                      class="btn btn-large btn-danger"
                      id="btn_del"
                      @click="deleteBaby"
                    >
                      삭제
                    </a>

                    <a @click="goBack" class="btn btn-large btn-primary">
                      목록
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <!-- //.content-->
          </div>

          <!-- //.span9-->
        </div>
      </div>

      <!-- //.container-->
    </div>

    <!-- 아래 -->

    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
import axios from "axios";
import Swal from "sweetalert2";
import Vue from "vue";
const moment = require("moment");

export default {
  name: "BabyReg",
  components: {
    Header,
    Footer,
    LeftMenu,
  },

  data() {
    return {
      modRemoveToggle: false,

      babyId: "",
      parentsEmail: "",
      parentsName: "",
      babyName: "",
      babyBirth: "",
      babyGender: "",
      careGubun: "",
      hour: "",
      minute: "",
      imageBytes: "",

      // 업데이트 할 때 사용되는 변수들
      preParentsEmail: "",
      preParentsName: "",
      gubunList: [],

      emailCheck: true,

      removeProfile: "N", // 이미지 삭제, 변경 여부
    };
  },
  methods: {
    goBack() {
      var data = this;
      data.$router.replace("/baby/list");
      [2];
    },

    ClickmodRemoveToggle() {
      this.modRemoveToggle = !this.modRemoveToggle;
    },

    gubonChange(event) {
      this.careGubun = event.target.value;
    },

    searchParents() {
      // 이메일 검사
      if (
        !/^[A-Za-z0-9_\\.\\-]+@[A-Za-z0-9\\-]+\.[A-Za-z0-9\\-]+/.test(
          this.parentsEmail
        )
      ) {
        Swal.fire({
          title: "이메일 형식을 확인하세요.",
          icon: "error",
          confirmButtonText: "확인",
        });
        return false;
      }
      var host = Vue.prototype.$hostname;
      var data = this;
      axios
        .get(`${host}/admin/baby-parents/${encodeURI(this.parentsEmail)}`)
        .then(function (response) {
          Swal.fire({
            title: "양육자 이름: " + response.data.payload.userName,
            icon: "success",
            confirmButtonText: "확인",
          });
          data.emailCheck = true;
          data.parentsName = response.data.payload.userName;
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
          data.parentsName = "이메일 찾기에 성공하면 이름이 나옵니다.";
        });
    },

    // 파일 확장자, 사이즈 체크
    checkFile() {
      // files 로 해당 파일 정보 얻기.
      var file = document.getElementById("file").files;

      // 파일 사이즈
      let maxSize = 15 * 1024 * 1024; // 15MB
      let fileLength = file.length;
      let addFileCapacity = 0;

      // file[0].name 은 파일명 입니다.
      // 정규식으로 확장자 체크
      if (!/\.(gif|jpg|jpeg|png|tiff|tif)$/i.test(file[0].name)) {
        Swal.fire({
          title:
            "gif, jpg, jpeg, png, tiff, tif 파일만 선택해 주세요.\n\n현재 파일 : " +
            file[0].name,
          icon: "warning",
          confirmButtonText: "확인",
        });
        document.getElementById("file").value = null;
      }

      for (let i = 0; i < fileLength; i++) {
        addFileCapacity = addFileCapacity + file[i].size;
      }
      if (addFileCapacity > maxSize) {
        Swal.fire({
          title: "파일 용량 제한은 15MB입니다.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        document.getElementById("file").value = null;
        return false;
      }

      // 체크를 통과했다면 종료.
      return;
    },

    updateBaby() {
      const formData = new FormData();
      // 파일 첨부 검사
      if (this.removeProfile === "Y") {
        if (document.getElementById("file").files.length === 0) {
          Swal.fire({
            title: "이미지를 첨부해주세요.",
            icon: "warning",
            confirmButtonText: "확인",
          });
          return false;
        }
      }

      // 이메일, 아기이름 검사
      if (this.emailCheck === false) {
        Swal.fire({
          title: "양육자 이메일을 체크해주세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      }

      // 태어난 날 유효성 검사
      if (this.babyBirth.length === 0) {
        Swal.fire({
          title: "태어난 날 연도를 선택해주세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      } else if (this.hour.length === 0) {
        Swal.fire({
          title: "태어난 날 시간을 선택해주세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      } else if (this.minute.length === 0) {
        Swal.fire({
          title: "태어난 날 분을 선택해주세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      }

      let file = null;
      try {
        file = document.getElementById("file").files[0];
      } catch {
        console.log("");
      }

      var data = this;
      var host = Vue.prototype.$hostname;
      formData.append("file", file);
      formData.append("babyId", this.babyId);
      formData.append("userEmail", this.parentsEmail);
      formData.append("babyName", this.babyName);
      formData.append("babyBirth", this.babyBirth.replace(/[^0-9]/g, ""));
      formData.append("babyGender", this.babyGender);
      formData.append("careGubun", this.careGubun);
      formData.append("hour", this.hour);
      formData.append("minute", this.minute);
      formData.append("modId", this.$store.getters["login/getAdminId"]);
      formData.append("preUserEmail", this.preParentsEmail);

      axios
        .put(`${host}/admin/baby`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              data.$router.replace("/baby/list");
            }
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },

    deleteBaby() {
      var data = this;
      var host = Vue.prototype.$hostname;
      axios
        .delete(
          `${host}/admin/baby/${encodeURI(this.babyId)}/${encodeURI(
            this.parentsEmail
          )}`
        )
        .then(function (response) {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              data.$router.replace("/baby/list");
            }
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
  },
  created() {
    if (this.$route.params.babyId === undefined) {
      return false;
    }

    var host = Vue.prototype.$hostname;
    var data = this;
    axios
      .get(`${host}/admin/baby/${encodeURI(this.$route.params.babyId)}`)
      .then(function (response) {
        data.babyId = response.data.payload.babyId;
        data.parentsEmail = response.data.payload.parentsEmail;
        data.preParentsEmail = response.data.payload.parentsEmail;
        data.parentsName = response.data.payload.parentsName;
        data.preParentsName = response.data.payload.parentsName;
        data.babyName = response.data.payload.babyName;
        data.babyBirth = moment(response.data.payload.babyBirth).format(
          "YYYY-MM-DD"
        );
        data.babyGender = response.data.payload.babyGender;
        data.careGubun = response.data.payload.careGubun;
        data.hour = response.data.payload.hour;
        data.minute = response.data.payload.minute;
        data.imageBytes = response.data.payload.imageBytes;
        data.removeProfile =
          response.data.payload.imageBytes === null ? "Y" : "N";
        data.gubunList = response.data.gubunList;
      })
      .catch(function (error) {
        if (error.response.status) {
          // 권한이 없을 경우
          if (
            error.response.status === 403 &&
            error.response.data.message === "Forbidden"
          ) {
            Swal.fire({
              title: data.$store.getters["customer/getNoAuthority"],
              icon: "error",
              confirmButtonText: "확인",
            });
            data.$router.push("/");
            return false;

            // 로그인을 안했을 경우
          } else if (
            error.response.status === 403 &&
            data.$store.getters["token/getToken"].length === 0
          ) {
            Swal.fire({
              title: data.$store.getters["customer/getNoLoginMessage"],
              icon: "error",
              confirmButtonText: "확인",
            });
            data.$router.push("/login");
            return false;

            // 새로고침을 눌렀을 경우
          } else if (
            error.response.status === 403 &&
            data.$store.getters["token/getToken"].length !== 0
          ) {
            data.$router.push("/login");
            return false;
          }
        }
        // 새로고침 했을 때
        if (error.response.status === 400) {
          return false;
        }
        Swal.fire({
          title: error.response.data.message,
          icon: "error",
          confirmButtonText: "확인",
        });
      });
  },
};
</script>
<style>
#img_del {
  margin-left: 10px;
}
</style>
