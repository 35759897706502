var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('LeftMenu'),_c('div',{staticClass:"span9"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"module"},[_vm._m(0),_c('div',{staticClass:"module-body"},[_c('div',{staticClass:"profile-head pb_20"},[_c('div',{staticClass:"table"},[_c('table',{staticClass:"table table-bordered"},[_vm._m(1),_c('tbody',[_c('tr',[_c('th',[_vm._v("제목")]),_c('td',[_c('div',{staticClass:"controls"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.nTitle),expression:"nTitle"}],staticClass:"span3",attrs:{"type":"text","id":"title","placeholder":"","disabled":_vm.modRemoveToggle == false},domProps:{"value":(_vm.nTitle)},on:{"input":function($event){if($event.target.composing)return;_vm.nTitle=$event.target.value}}})])])]),_c('tr',[_c('th',[_vm._v("노출여부")]),_c('td',[_c('div',{staticClass:"controls"},[_c('label',{staticClass:"radio inline"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.nOpnYn),expression:"nOpnYn"}],attrs:{"type":"radio","name":"optionsRadios1","id":"open_yn","value":"Y","checked":"","disabled":_vm.modRemoveToggle == false},domProps:{"checked":_vm._q(_vm.nOpnYn,"Y")},on:{"change":function($event){_vm.nOpnYn="Y"}}}),_vm._v(" 노출 ")]),_c('label',{staticClass:"radio inline"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.nOpnYn),expression:"nOpnYn"}],attrs:{"type":"radio","name":"optionsRadios1","id":"open_yn","value":"N","disabled":_vm.modRemoveToggle == false},domProps:{"checked":_vm._q(_vm.nOpnYn,"N")},on:{"change":function($event){_vm.nOpnYn="N"}}}),_vm._v(" 노출중지 ")])])])]),_c('tr',[_c('th',[_vm._v("첨부파일")]),_c('td',[(
                                _vm.existFile === false &&
                                _vm.modRemoveToggle === true
                              )?_c('div',{staticClass:"controls"},[_c('input',{attrs:{"id":"file","name":"file","type":"file","accept":"image/*, application/pdf, application/zip, .txt, .doc, .docx, .xls, .xlsx, .hwp, .txt, .pptx"},on:{"change":_vm.checkFile}})]):_vm._e(),(_vm.existFile === true)?_c('div',{staticClass:"controls"},[_c('a',{staticStyle:{"cursor":"pointer"},attrs:{"href":_vm.url,"target":"_blank"}},[_vm._v(_vm._s(_vm.originalFileId))]),(
                                  _vm.removeProfile === false &&
                                  _vm.modRemoveToggle === true
                                )?_c('a',{staticClass:"btn btn-large btn-danger",staticStyle:{"margin-left":"20px"},attrs:{"id":"btn_del"},on:{"click":function($event){(_vm.existFile = false), (_vm.removeProfile = true)}}},[_vm._v(" 파일 삭제 또는 수정 ")]):_vm._e(),_c('span',{staticStyle:{"margin-left":"100px"}},[_vm._v("파일명을 클릭하면 다운로드 됩니다.")])]):_vm._e()])]),_c('tr',[_c('th',[_vm._v("내용")]),_c('td',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.nContent),expression:"nContent"}],staticStyle:{"resize":"none"},attrs:{"cols":"110","rows":"10","disabled":_vm.modRemoveToggle == false,"id":"f_content"},domProps:{"value":(_vm.nContent)},on:{"input":function($event){if($event.target.composing)return;_vm.nContent=$event.target.value}}})])])])]),_c('div',{staticClass:"flex_btn alignjust_right"},[_c('a',{directives:[{name:"show",rawName:"v-show",value:(!_vm.modRemoveToggle),expression:"!modRemoveToggle"}],staticClass:"btn btn-large btn-info",on:{"click":_vm.ClickmodRemoveToggle}},[_vm._v("수정")]),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.modRemoveToggle),expression:"modRemoveToggle"}],staticClass:"btn btn-large btn-info",on:{"click":_vm.updateNotice}},[_vm._v("저장")]),_c('a',{staticClass:"btn btn-large btn-danger",on:{"click":_vm.deleteNotice}},[_vm._v("삭제")]),_c('a',{staticClass:"btn btn-large btn-primary",on:{"click":_vm.goBack}},[_vm._v("목록")])])])])])])])])],1)])]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-head"},[_c('h3',[_vm._v("공지사항 상세")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col',{attrs:{"width":"15%"}}),_c('col',{attrs:{"width":"85%"}})])
}]

export { render, staticRenderFns }