<template>
  <!-- 회원리스트 -->
  <div>
    <!-- 해더 -->
    <Header />
    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />
          <!-- 내용 -->
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>매거진 등록</h3>
                </div>
                <div class="module-body">
                  <h4>등록자 정보</h4>
                  <div class="profile-head pb_20">
                    <div class="table">
                      <table class="table table-bordered">
                        <colgroup>
                          <col width="15%" />
                          <col width="30%" />
                          <col width="15%" />
                          <col width="40%" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>양육자 이름</th>
                            <td>
                              <input
                                type="text"
                                id="user_name"
                                Value="김이름(엄마)"
                              />
                            </td>
                            <th>이메일</th>
                            <td>
                              <input
                                type="text"
                                id="user_email"
                                Value="abct@naver.com"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="module-body">
                  <h4>포토북</h4>
                  <div class="profile-head pb_20">
                    <div class="table">
                      <table class="table table-bordered">
                        <colgroup>
                          <col width="15%" />
                          <col width="30%" />
                          <col width="15%" />
                          <col width="40%" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>양육자 이름</th>
                            <td>
                              <input
                                type="text "
                                id="user_name"
                                v-mode="userName"
                              />
                            </td>

                            <th>이메일</th>
                            <td>
                              <input
                                type="text "
                                v-mode="userEmail"
                                id="user_email"
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>사이즈</th>
                            <td>
                              <input type="text " id="Size" v-mode="Size" />
                            </td>

                            <th>페이지 수</th>
                            <td>
                              <input
                                type="text "
                                id="PageAmount"
                                v-mode="PageAmount"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <h5>사진/동영상</h5>
                <table width="300" height="200"></table>
                <div class="module-body">
                  <h4>상태변경</h4>
                  <div class="profile-head pb_20">
                    <div class="table">
                      <table class="table">
                        <colgroup>
                          <col width="15%" />
                          <col width="30%" />
                          <col width="15%" />
                          <col width="40%" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>상태변경</th>
                            <td colspan="3">
                              <div class="controls">
                                <label class="radio inline">
                                  <input
                                    type="radio"
                                    name="optionsRadios1"
                                    id="open_yn"
                                    value="Y"
                                    v-model="openYn"
                                  />
                                  노출중
                                </label>
                                <label class="radio inline">
                                  <input
                                    type="radio"
                                    name="optionsRadios1"
                                    id="open_yn"
                                    value="N"
                                    v-model="openYn"
                                  />
                                  노출중지
                                </label>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="flex_btn alignjust_center">
                    <a class="btn btn-large btn-warning">저장</a>
                    <a @click="goBack" class="btn btn-large btn-primary"
                      >목록</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- //.content-->
          </div>
          <!-- //.span9-->
        </div>
      </div>
      <!-- //.container-->
    </div>
    <!-- 아래 -->
    <Footer />
  </div>
</template>
<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
export default {
  name: "MagazineDetail",
  components: {
    Header,
    Footer,
    LeftMenu,
  },
  data() {
    return {
      modRemoveToggle: false,

      //등록자 정보

      userName: "양육자 이름", //양육자 이름
      userEmail: "이메일", //이메일

      //포토북

      //양육자 이름
      //이메일
      Size: "사이즈 가로 200m 세로 200m", //사이즈
      PageAmount: "22(커버 포함)2", //페이지 수

      //상태변경
      openYn: "Y", //상태 변경
    };
  },
  methods: {
    goBack() {
      var data = this;
      data.$router.replace("/magazine/list");
      [2];
    },
  },
};
</script>
