<template>
  <!-- 회원리스트 -->

  <div>
    <!-- 해더 -->

    <Header />

    <!-- /navbar -->

    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->

          <!--- <LeftMenu />  -->

          <!-- 내용 -->

          <div class="wrapper">
            <div class="container">
              <div class="row">
                <div class="module module-login span4 offset4">
                  <form class="form-vertical">
                    <div class="module-head">
                      <h3>Log In Peanut Story Admin</h3>
                    </div>

                    <div class="module-body">
                      <div class="control-group">
                        <div class="controls row-fluid">
                          <input
                            class="span12"
                            type="text"
                            id="inputEmail"
                            placeholder="ID"
                            v-model="admId"
                            @keyup.enter="login($event)"
                          />
                        </div>
                      </div>

                      <div class="control-group">
                        <div class="controls row-fluid">
                          <input
                            class="span12"
                            type="password"
                            id="inputPassword"
                            placeholder="Password"
                            v-model="admPwd"
                            @keyup.enter="login($event)"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="module-foot">
                      <div class="control-group">
                        <div class="controls clearfix">
                          <button
                            type="button"
                            class="btn btn-primary pull-right"
                            @click="login($event)"
                          >
                            Login
                          </button>

                          <label class="checkbox">
                            <input type="checkbox" v-model="IdRememberCheck" />
                            Remember me
                          </label>
                        </div>
                      </div>

                      <div class="control-group">
                        ※ ID/PASSWORD 관리자 문의
                        <i class="icon-phone-sign"></i>
                        000-0000
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <!-- //.wrapper-->
        </div>
      </div>

      <!-- //.container-->
    </div>

    <!-- 아래 -->

    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import axios from "axios";
import Swal from "sweetalert2";
import Vue from "vue";

export default {
  name: "AdminLogin",
  data() {
    return {
      admId: "",
      admPwd: "",
      IdRememberCheck: false,
    };
  },
  components: {
    Header,
    Footer,
    //  LeftMenu,
  },
  methods: {
    login(event) {
      event.preventDefault();

      if (this.admId.length === 0) {
        Swal.fire({
          title: "아이디를 입력해주세요",
          icon: "error",
          confirmButtonText: "확인",
        });
        return false;
      } else if (this.admPwd.length === 0) {
        Swal.fire({
          title: "비밀번호를 입력해주세요",
          icon: "error",
          confirmButtonText: "확인",
        });
        return false;
      }

      var data = this;
      var host = Vue.prototype.$hostname;
      var params = {
        admId: this.admId,
        admPwd: this.admPwd,
      };
      axios
        // local
        .post(`${host}/login`, params)
        .then(function (response) {
          if (response.status === 200) {
            Swal.fire({
              title: "로그인 되었습니다.",
              icon: "success",
              confirmButtonText: "확인",
            }).then(() => {
              data.$router.push("/");
            });
          }
          // 60 * 60 * 24 = 1day
          data.$store.commit("login/setAdminId", data.admId);
          data.$store.commit("token/setToken", response.data);

          // 로그인에 성공하면 헤더에 Authorization를 붙여줌 이걸로 페이지를 방문할 때마다 JWT 인증을함
          axios.defaults.headers.common["Authorization"] = response.data;

          // 아이디 저장
          if (data.IdRememberCheck) {
            data.$cookies.set("rememberId", data.admId, 60 * 60 * 25 * 30);
            data.$cookies.set("IdRememberCheck", true, 60 * 60 * 25 * 30);
          } else {
            data.$cookies.remove("rememberId");
            data.$cookies.remove("IdRememberCheck");
          }
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            Swal.fire({
              title: "아이디 또는 비밀번호를 확인해주세요",
              text: "계정이 잠겨 있을 수도 있습니다.",
              icon: "error",
              confirmButtonText: "확인",
            });
          }
        });
    },
  },
  // 쿠키가 있으면 가져와 값으로 넣음 (아이디 저장)
  created() {
    this.admId = this.$cookies.get("rememberId");
    this.IdRememberCheck = this.$cookies.get("IdRememberCheck");

    // 로그인이 되어 있으면 로그인페이지로 이동 못하게 처리
    if (axios.defaults.headers.common["Authorization"]) {
      this.$router.push("/");
    }
    // 새로고침 눌렀을 때 로그인이 풀리는 현상 처리
    if (this.$store.getters["token/getToken"]) {
      // 헤더 다시 설정
      axios.defaults.headers.common["Authorization"] =
        this.$store.getters["token/getToken"];

      this.$router.go(-1);
    }
  },
};
</script>
