<template>
  <!-- 회원리스트 -->
  <div>
    <!-- 해더 -->
    <Header />

    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />
          <!-- 내용 -->
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>주문자 정보</h3>
                </div>
                <div class="table">
                  <table class="table table-bordered">
                    <colgroup>
                      <col width="50%" />
                      <col width="50%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>주문자명 (id)</th>
                        <th>연락처</th>
                      </tr>
                      <tr>
                        <td id="order_id">최인선(adfda@naver.com)</td>
                        <td id="order_phone">010-5555-5555</td>
                      </tr>
                    </thead>
                  </table>
                </div>

                <div class="module-body">
                  <h4><span class="icon-chevron-right"></span>수령자 정보</h4>
                  <div class="table">
                    <table class="table table-bordered">
                      <colgroup>
                        <col width="10%" />
                        <col width="30%" />
                      </colgroup>

                      <tr>
                        <th>배송구분</th>
                        <td colspan="1" id="deliver_type">
                          <lable>김결제</lable>
                        </td>
                      </tr>

                      <tr>
                        <th>수령자명</th>
                        <td colspan="1" id="receiver">
                          <lable>신용카드/카카오페이/네이버페이 … </lable>
                        </td>
                      </tr>

                      <tr>
                        <th>연락처</th>
                        <td colspan="1" id="recveiver_phone">
                          <lable
                            >일반전화(070-8888-6666) / 휴대전화(010-5555-5555)
                          </lable>
                        </td>
                      </tr>

                      <tr>
                        <th>배송지 주소</th>
                        <td colspan="1" id="receiver_address">
                          <lable
                            >(18416) 경기도 화성시 서동탄로 53 (능동,
                            서동탄역파크자이) 111동 305호
                          </lable>
                        </td>
                      </tr>

                      <tr>
                        <th>요청사항</th>
                        <td colspan="1" id="receiver_request">
                          <lable>문 앞에 놓아주세요. </lable>
                        </td>
                      </tr>
                    </table>
                    <div class="flex_btn alignjust_center">
                      <a @click="goBack" class="btn btn-large btn-primary"
                        >목록</a
                      >
                    </div>
                  </div>
                </div>
                <div class="flex_btn alignjust_right">
                  <a @click="goBack" class="btn btn-large btn-primary">목록</a>
                </div>
              </div>
            </div>
          </div>
          <!-- //.span9-->
        </div>
      </div>
      <!-- //.container-->
    </div>

    <!-- 아래 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";

export default {
  name: "ReceiverInfo",
  components: {
    Header,
    Footer,
    LeftMenu,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
      [2];
    },
  },
};
</script>