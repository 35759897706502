export default {
  namespaced: true,
  state: {
    // 상위 코드
    tcCpn: 1,
    tcCodeName: "",
    tcTopCode: "",

    cCpn: 1,
    cTopCode: "",
    cCode: "",
    cCodeName: "",
  },
  getters: {
    getTcCpn(state) {
      return state.tcCpn;
    },
    getTcCodeName(state) {
      return state.tcCodeName;
    },
    getTcTopCode(state) {
      return state.tcTopCode;
    },

    getCCpn(state) {
      return state.cCpn;
    },
    getCTopCode(state) {
      return state.cTopCode;
    },
    getCCode(state) {
      return state.cCode;
    },
    getCCodeName(state) {
      return state.cCodeName;
    },
  },
  mutations: {
    setTcCpn(state, payload) {
      state.tcCpn = payload;
    },
    setTcCodeName(state, payload) {
      state.tcCodeName = payload;
    },
    setTcTopCode(state, payload) {
      state.tcTopCode = payload;
    },

    setCCpn(state, payload) {
      state.cCpn = payload;
    },
    setCTopCode(state, payload) {
      state.cTopCode = payload;
    },
    setCCode(state, payload) {
      state.cCode = payload;
    },
    setCCodeName(state, payload) {
      state.cCodeName = payload;
    },
  },
};
