<template>
  <!-- 회원리스트 -->
  <div>
    <!-- 해더 -->
    <Header />

    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />

          <!-- 내용 -->
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>공지사항 관리</h3>
                </div>
                <div class="module-body">
                  <div class="profile-head pb_20">
                    <div class="table">
                      <table class="table table-bordered">
                        <colgroup>
                          <col width="10%" />
                          <col width="20%" />
                          <col width="15%" />
                          <col width="55%" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>제목</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="title"
                                  placeholder=""
                                  class="span3"
                                  v-model="boardTitle"
                                  @keyup.enter="search(1)"
                                />
                              </div>
                            </td>
                            <th>작성일</th>
                            <td>
                              <input
                                class="w43"
                                type="date"
                                id="signfor_start"
                                v-model="startRegDate"
                              />
                              ~
                              <input
                                class="w43"
                                type="date"
                                id="signfor_end"
                                v-model="endRegDate"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="flex_btn alignjust_right">
                        <a class="btn btn-large btn-warning" @click="search(1)"
                          >조회</a
                        >
                        <a
                          @click="goNoticeUpdate"
                          class="btn btn-large btn-warning"
                          >등록</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="module-body">
                  <div class="table">
                    <table
                      class="table table-striped table-bordered table-condensed"
                    >
                      <colgroup>
                        <col width="*" />
                        <col width="*" />
                        <col width="*" />
                        <col width="*" />
                        <col width="*" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th style="text-align: center">No</th>
                          <th style="text-align: center">제목</th>
                          <th style="text-align: center">조회수</th>
                          <th style="text-align: center">날짜</th>
                          <th style="text-align: center">상태</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(pData, i) in payload"
                          :key="pData + i"
                          @click="
                            goDetailPage(
                              pData.nseq,
                              pData.ntitle,
                              pData.ncontent,
                              pData.fileNm,
                              pData.filePath,
                              pData.nopnYn,
                              pData.originalFileId
                            )
                          "
                        >
                          <td>{{ pData.rownum }}</td>
                          <td>{{ pData.ntitle }}</td>
                          <td>{{ pData.vueCount }}</td>
                          <td>
                            {{ $moment(pData.regDate).format("YYYY-MM-DD") }}
                          </td>
                          <td>
                            {{ pData.nopnYn === "Y" ? "노출중" : "노출안함" }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="flex_btn alignjust_center mt_10">
                      <nav aria-label="Page navigation ">
                        <ul class="pagination">
                          <li class="page-item">
                            <a
                              class="page-link"
                              v-if="curPage - 5 >= 1"
                              @click="pageMove(curPage - 5)"
                              aria-label="Previous"
                            >
                              <i
                                class="fa fa-angle-left"
                                aria-hidden="true"
                              ></i>

                              <span class="sr-only">PREV</span>
                            </a>
                          </li>

                          <li
                            class="page-item"
                            v-for="(pageNo, i) in endPage"
                            :key="'p' + i"
                          >
                            <a
                              class="page-link"
                              v-if="pageNo >= startPage"
                              @click="pageMove(pageNo)"
                              :class="{ page_active: pageNo === curPage }"
                            >
                              {{ pageNo }}
                            </a>
                          </li>

                          <li
                            class="page-item"
                            v-if="pageCnt - startPage >= 5"
                            @click="pageMove(startPage + 5)"
                          >
                            <a class="page-link" aria-label="Next">
                              <i
                                class="fa fa-angle-right"
                                aria-hidden="true"
                              ></i>

                              <span class="sr-only">NEXT</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- //.content-->
          </div>
        </div>
      </div>
      <!-- //.container-->
    </div>

    <!-- 아래 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
import axios from "axios";
import Swal from "sweetalert2";
import Vue from "vue";
import { logout } from "@/class/logout.js";

export default {
  name: "NoticeList",
  components: {
    Header,
    Footer,
    LeftMenu,
  },
  data() {
    return {
      nSeq: "",
      nTitle: "",
      nContent: "",
      fileNm: "",
      filePath: "",
      nOpnYn: "",
      nDelYn: "",
      regId: "",
      regDate: "",
      modId: "",
      modDate: "",
      vueCount: 0,
      originalFileId: "",

      boardTitle: this.$store.getters["searchNoticeStore/getBoardTitle"],
      startRegDate: this.$store.getters["searchNoticeStore/getStartRegDate"],
      endRegDate: this.$store.getters["searchNoticeStore/getEndRegDate"],

      // 페이지
      rangeSize: 0,
      curPage: this.$store.getters["searchNoticeStore/getCpn"],
      startPage: 0,
      endPage: 0,
      pageCnt: 0,

      // 게시판 글
      payload: [],
    };
  },
  methods: {
    goDetailPage(
      Seq,
      nTitle,
      nContent,
      fileNm,
      filePath,
      nOpnYn,
      originalFileId
    ) {
      this.$router.push({
        name: "NoticeDetail",
        params: {
          nSeq: Seq,
          nTitle: nTitle,
          nContent: nContent,
          fileNm: fileNm,
          filePath: filePath,
          nOpnYn: nOpnYn,
          originalFileId: originalFileId,
        },
      });
    },
    goNoticeUpdate() {
      this.$router.push("/boarder/NoticeReg");
    },
    search(cpn) {
      // cpn = 현재 페이지
      var data = this;
      var host = Vue.prototype.$hostname;
      if (this.startRegDate.length !== 0 && this.endRegDate.length === 0) {
        Swal.fire({
          title: "작성일",
          text: "작성일 끝나는 날짜를 입력하세요",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      } else if (
        this.endRegDate.length !== 0 &&
        this.startRegDate.length === 0
      ) {
        Swal.fire({
          title: "작성일",
          text: "작성일 시작하는 날짜를 입력하세요",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      }

      if (this.startRegDate > this.endRegDate) {
        Swal.fire({
          title: "작성일",
          text: "작성일 시작날짜가 끝나는 날짜보다 더 큽니다.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      }

      this.$store.commit("searchNoticeStore/setBoardTitle", this.boardTitle);
      this.$store.commit(
        "searchNoticeStore/setStartRegDate",
        this.startRegDate
      );
      this.$store.commit("searchNoticeStore/setEndRegDate", this.endRegDate);
      this.$store.commit("searchNoticeStore/setCpn", cpn);
      axios
        .get(`${host}/admin/notice-list`, {
          params: {
            boardTitle: this.boardTitle,
            startRegDate:
              this.startRegDate.length !== 0
                ? new Date(this.startRegDate).toISOString()
                : null,

            endRegDate:
              this.endRegDate.length !== 0
                ? new Date(this.endRegDate).toISOString()
                : null,
            curPage: cpn,
          },
        })
        .then(function (response) {
          data.rangeSize = response.data.paging.rangeSize;
          data.payload = response.data.payload;
          data.startPage = response.data.paging.startPage;
          data.endPage = response.data.paging.endPage;
          data.pageCnt = response.data.paging.pageCnt;
          data.curPage = response.data.paging.curPage;
          this.$store.commit(
            "searchNoticeStore/setCpn",
            response.data.paging.curPage
          );
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },

    pageMove(cpn) {
      var data = this;
      var host = Vue.prototype.$hostname;

      this.$store.commit("searchNoticeStore/setCpn", cpn);
      axios
        .get(`${host}/admin/notice-list`, {
          params: {
            boardTitle: this.$store.getters["searchNoticeStore/getBoardTitle"],
            startRegDate:
              this.$store.getters["searchNoticeStore/getStartRegDate"],
            endRegDate: this.$store.getters["searchNoticeStore/getEndRegDate"],
            curPage: this.$store.getters["searchNoticeStore/getCpn"],
          },
        })
        .then(function (response) {
          data.rangeSize = response.data.paging.rangeSize;
          data.payload = response.data.payload;
          data.startPage = response.data.paging.startPage;
          data.endPage = response.data.paging.endPage;
          data.pageCnt = response.data.paging.pageCnt;
          data.curPage = response.data.paging.curPage;
          this.$store.commit(
            "searchNoticeStore/setCpn",
            response.data.paging.curPage
          );
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
  },

  created() {
    var data = this;
    var host = Vue.prototype.$hostname;
    axios
      .get(`${host}/admin/notice-list`, {
        params: {
          boardTitle: this.$store.getters["searchNoticeStore/getBoardTitle"],
          startRegDate:
            this.$store.getters["searchNoticeStore/getStartRegDate"],
          endRegDate: this.$store.getters["searchNoticeStore/getEndRegDate"],
          curPage: this.$store.getters["searchNoticeStore/getCpn"],
        },
      })
      .then(function (response) {
        data.rangeSize = response.data.paging.rangeSize;
        data.payload = response.data.payload;
        data.startPage = response.data.paging.startPage;
        data.endPage = response.data.paging.endPage;
        data.pageCnt = response.data.paging.pageCnt;
        data.$store.commit(
          "searchNoticeStore/setCpn",
          response.data.paging.curPage
        );
      })
      .catch(function (error) {
        if (error.response.status) {
          // 권한이 없을 경우
          if (
            error.response.status === 403 &&
            error.response.data.message === "Forbidden"
          ) {
            Swal.fire({
              title: data.$store.getters["customer/getNoAuthority"],
              icon: "error",
              confirmButtonText: "확인",
            });
            data.$router.push("/");
            return false;

            // 로그인을 안했을 경우
          } else if (
            error.response.status === 403 &&
            data.$store.getters["token/getToken"].length === 0
          ) {
            Swal.fire({
              title: data.$store.getters["customer/getNoLoginMessage"],
              icon: "error",
              confirmButtonText: "확인",
            });
            data.$router.push("/login");
            return false;

            // 새로고침을 눌렀을 경우
          } else if (
            error.response.status === 403 &&
            data.$store.getters["token/getToken"].length !== 0
          ) {
            data.$router.push("/login");
            return false;
          } else if (
            error.response.data.message.includes("The Token has expired") ===
            true
          ) {
            logout();
            data.$router.push("/login");
            return false;
          }
        }
        Swal.fire({
          title: error.response.data.message,
          icon: "error",
          confirmButtonText: "확인",
        });
      });
  },
};
</script>
