import { render, staticRenderFns } from "./MagazineDetail.vue?vue&type=template&id=2e96d458&"
import script from "./MagazineDetail.vue?vue&type=script&lang=js&"
export * from "./MagazineDetail.vue?vue&type=script&lang=js&"
import style0 from "./MagazineDetail.vue?vue&type=style&index=0&id=2e96d458&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports