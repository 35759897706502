<template>
  <!-- 회원리스트 -->
  <div>
    <!-- 해더 -->
    <Header />

    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />

          <!-- 내용 -->
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>관리자 상세</h3>
                </div>
                <div class="module-body">
                  <div class="profile-head pb_20">
                    <div class="table">
                      <table class="table table-bordered">
                        <colgroup>
                          <col width="15%" />
                          <col width="30%" />
                          <col width="15%" />
                          <col width="40%" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>ID</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="admId"
                                  name="admId"
                                  placeholder=""
                                  class="span3"
                                  v-model="admId"
                                  :readonly="true"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>이름</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="admName"
                                  placeholder=""
                                  class="span3"
                                  v-model="admName"
                                  :readonly="modRemoveToggle == false"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>전화번호(ID)</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="admPhone"
                                  placeholder=""
                                  class="span3"
                                  v-model="admPhone"
                                  :readonly="modRemoveToggle == false"
                                />
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th>비밀번호</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="password"
                                  id="admPwd"
                                  placeholder=""
                                  class="span3"
                                  v-model="admPwd"
                                  :readonly="modRemoveToggle == false"
                                />
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th>비밀번호 확인</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="password"
                                  id="admPwdChecking"
                                  placeholder=""
                                  class="span3"
                                  v-model="admPwdChecking"
                                  :readonly="modRemoveToggle == false"
                                />
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th>부서</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="admDept"
                                  placeholder=""
                                  class="span3"
                                  v-model="admDept"
                                  :readonly="modRemoveToggle == false"
                                />
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th>직책</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="admTitle"
                                  placeholder=""
                                  class="span3"
                                  v-model="admTitle"
                                  :readonly="modRemoveToggle == false"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>관리자 등급</th>
                            <td>
                              <div class="controls">
                                <select
                                  v-model="admLevel"
                                  :disabled="modRemoveToggle == false"
                                >
                                  <option
                                    v-for="(level, i) in selectLevel"
                                    :key="'level' + i"
                                    :value="level.cdId"
                                  >
                                    {{ level.cdName }}
                                  </option>
                                </select>
                              </div>
                            </td>
                          </tr>

                          <th>상태</th>
                          <td>
                            <div class="controls">
                              <label class="radio inline">
                                <input
                                  type="radio"
                                  name="optionsRadios77"
                                  id="open_yn"
                                  value="Y"
                                  v-model="admStatus"
                                  :disabled="modRemoveToggle == false"
                                />
                                접속가능
                              </label>
                              <label class="radio inline">
                                <input
                                  type="radio"
                                  name="open_yn"
                                  id="optionsRadios22"
                                  value="N"
                                  v-model="admStatus"
                                  :disabled="modRemoveToggle == false"
                                />
                                접속불가
                              </label>
                            </div>
                          </td>
                        </tbody>
                      </table>
                      <div class="flex_btn alignjust_right">
                        <a
                          v-show="!modRemoveToggle"
                          class="btn btn-large btn-info"
                          @click="ClickmodRemoveToggle"
                          >수정</a
                        >

                        <a
                          v-show="modRemoveToggle"
                          class="btn btn-large btn-info"
                          @click="modifyAdminUser"
                          >저장</a
                        >
                        <a
                          class="btn btn-large btn-danger"
                          @click="deleteAdminUser"
                          >삭제</a
                        >

                        <a class="btn btn-large btn-primary" @click="goBack"
                          >목록</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- //.content-->
          </div>
          <!-- //.span9-->
        </div>
      </div>
      <!-- //.container-->
    </div>

    <!-- 아래 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
import axios from "axios";
import Swal from "sweetalert2";
import Vue from "vue";

export default {
  name: "AdminDetail",
  components: {
    Header,
    Footer,
    LeftMenu,
  },
  data() {
    return {
      modRemoveToggle: false,
      admId: "", // adminId
      admName: "", //관리자 이름
      admPhone: "", //관리자 전화번호
      admPwd: "", //관리자 비밀번호
      admPwdChecking: "", // 관리자 비밀번호 확인
      admDept: "", //관리자 부서
      admTitle: "", //직책
      admLevel: "", //관리자 등급
      admStatus: "", //상태

      selectLevel: "", // 관리자 등급
    };
  },

  methods: {
    goBack() {
      this.$router.go(-1);
      [2];
    },

    ClickmodRemoveToggle() {
      this.modRemoveToggle = !this.modRemoveToggle;
    },

    modifyAdminUser() {
      var data = this;
      var host = Vue.prototype.$hostname;
      if (this.admName.length < 1) {
        Swal.fire({
          title: "이름을 입력하세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      }

      // 전화번호 유효성 검사
      if (!/^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/.test(this.admPhone)) {
        Swal.fire({
          title: "유효하지 않은 전화번호 입니다.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      }

      // 비밀번호를 입력했는지 확인 => 비밀번호를 입력하지 않았으면 비밀번호 제외하고 업데이트 한다.
      if (this.admPwd.length !== 0 && this.admPwdChecking.length !== 0) {
        if (this.admPwd.length < 8) {
          // 비밀번호 유효성 검사
          Swal.fire({
            title: "비밀번호를 8자리 이상 입력하세요.",
            icon: "warning",
            confirmButtonText: "확인",
          });
          return false;
        } else if (this.admPwd.search(/\s/) != -1) {
          Swal.fire({
            title: "비밀번호는 공백 없이 입력해주세요.",
            icon: "warning",
            confirmButtonText: "확인",
          });
          return false;
        } else if (this.admPwd !== this.admPwdChecking) {
          Swal.fire({
            title: "비밀번호가 같지 않습니다.",
            icon: "warning",
            confirmButtonText: "확인",
          });
          return false;
        }
      }

      if (this.admDept.length < 1) {
        Swal.fire({
          title: "부서를 입력해주세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
      }

      if (this.admTitle.length < 1) {
        Swal.fire({
          title: "직책을 입력해주세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
      }

      console.log(this.admPwd.length);
      console.log(this.admPwdChecking.length);

      var params = {
        admId: this.admId,
        admName: this.admName,
        admPwd: this.admPwd.length === 0 ? null : this.admPwd,
        admPhone: this.admPhone,
        admDept: this.admDept,
        admTitle: this.admTitle,
        admLevel: this.admLevel,
        admStatus: this.admStatus,
        modId: this.$store.getters["login/getAdminId"],
      };
      axios
        // local
        .put(`${host}/admin/admin-user`, params, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then(function (response) {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              data.$router.replace("/admin/list");
            }
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },

    deleteAdminUser() {
      var data = this;
      var host = Vue.prototype.$hostname;
      axios
        // local
        .delete(`${host}/admin/admin-user/${this.admId}`)
        .then(function (response) {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              data.$router.replace("/admin/list");
            }
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
  },
  created() {
    if (this.$route.params.admin === undefined) {
      return false;
    }

    this.selectLevel = this.$route.params.selectLevel;
    this.admId = this.$route.params.admin.admId;
    this.admName = this.$route.params.admin.admName;
    this.admPhone = this.$route.params.admin.admPhone;
    // this.admPwd = this.$route.params.admin.admPwd;
    // this.admPwdChecking = this.$route.params.admin.admPwd;
    this.admDept = this.$route.params.admin.admDept;
    this.admTitle = this.$route.params.admin.admTitle;
    this.admLevel = this.$route.params.admin.admLevel;
    this.admStatus = this.$route.params.admin.admStatus;
  },
};
</script>
