<template>
  <!-- 메인 페이지 -->
  <div>
    <!-- 해더 -->
    <Header />

    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />

          <!-- //.span3-->
          <div class="span9">
            <div class="content">
              <div class="btn-controls">
                <div class="btn-box-row row-fluid">
                  <a class="btn-box big span4 pointer" @click="goUserPage">
                    <p class="text-muted">회원</p>
                    <b>{{ userCount + "명" }}</b>
                  </a>
                  <a class="btn-box big span4 pointer" @click="goDiaryPage">
                    <p class="text-muted">등록 사진</p>
                    <b>{{ photoCount + "개" }}</b>
                  </a>
                  <a class="btn-box big span4 pointer" @click="goMagazinePage">
                    <p class="text-muted">등록 매거진</p>
                    <b>{{ magazineCount + "개" }}</b>
                  </a>
                </div>
              </div>
            </div>
            <!-- //.content-->
          </div>
          <!-- //.span9-->
        </div>
      </div>
      <!-- //.container-->
    </div>

    <!-- 아래 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
import axios from "axios";
import Vue from "vue";

export default {
  name: "DashBoard",
  data() {
    return {
      userCount: "",
      photoCount: "",
      magazineCount: "",
    };
  },
  methods: {
    goUserPage() {
      this.$router.push("/customer/list");
    },
    goDiaryPage() {
      this.$router.push("/diary/list");
    },
    goMagazinePage() {
      this.$router.push("/magazine/list");
    },
  },
  mounted() {
    var data = this;
    var host = Vue.prototype.$hostname;
    axios
      // local
      .get(`${host}/admin/`)
      .then(function (response) {
        (data.userCount = response.data.userCount),
          (data.photoCount = response.data.photoCount),
          (data.magazineCount = response.data.magazineCount);
      })
      .catch(function (error) {
        // 에러가 있을 때 로그인 페이지로 이동
        if (error.response.status) {
          if (error.response.status === 403) {
            data.$router.push("/login");
          } else if (error.response.status === 500) {
            axios.defaults.headers.common["Authorization"] = "";
            data.$store.commit("login/setAdminId", "");
            data.$store.commit("token/setToken", "");
            data.$router.push("/login");
          }
        }
      });
  },
  components: {
    Header,
    Footer,
    LeftMenu,
  },
};
</script>
<style>
a:hover {
  cursor: pointer;
}
</style>
