export default {
  namespaced: true,
  state: {
    cpn: 1,
    gubun: "",
    title: "",
    startRegDate: "",
    endRegDate: "",
  },
  getters: {
    getCpn(state) {
      return state.cpn;
    },
    getGubun(state) {
      return state.gubun;
    },
    getTitle(state) {
      return state.title;
    },
    getStartRegDate(state) {
      return state.startRegDate;
    },
    getEndRegDate(state) {
      return state.endRegDate;
    },
  },
  mutations: {
    setCpn(state, payload) {
      state.cpn = payload;
    },
    setGubun(state, payload) {
      state.gubun = payload;
    },
    setTitle(state, payload) {
      state.title = payload;
    },
    setStartRegDate(state, payload) {
      state.startRegDate = payload;
    },
    setEndRegDate(state, payload) {
      state.endRegDate = payload;
    },
  },
};
