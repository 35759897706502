/**
 * 메뉴를 이동하면 store들을 초기화하는 로직
 */
import store from "@/store/index.js";
export function storeClear() {
  // admin
  store.commit("searchAdminUserStore/setAdmName", "");
  store.commit("searchAdminUserStore/setAdmDept", "");
  store.commit("searchAdminUserStore/setAdmLevel", "");
  store.commit("searchAdminUserStore/setAdmTitle", "");
  store.commit("searchAdminUserStore/setCpn", 1);

  //아기
  store.commit("searchBabyStore/setBabyName", "");
  store.commit("searchBabyStore/setParentsName", "");
  store.commit("searchBabyStore/setGender", "all");
  store.commit("searchBabyStore/setStartRegDate", "");
  store.commit("searchBabyStore/setEndRegDate", "");
  store.commit("searchBabyStore/setCpn", 1);

  // 1:1문의
  store.commit("searchQnaStore/setBoardTitle", "");
  store.commit("searchQnaStore/setType", "");
  store.commit("searchQnaStore/setUserName", "");
  store.commit("searchQnaStore/setStatusYn", "");
  store.commit("searchQnaStore/setCpn", 1);

  // FAQ
  store.commit("searchFaqStore/setBoardTitle", "");
  store.commit("searchFaqStore/setType", "");
  store.commit("searchFaqStore/setCpn", 1);

  // 공지사항
  store.commit("searchNoticeStore/setBoardTitle", "");
  store.commit("searchNoticeStore/setStartRegDate", "");
  store.commit("searchNoticeStore/setEndRegDate", "");
  store.commit("searchNoticeStore/setCpn", 1);

  // 상위코드
  store.commit("searchCodeStore/setTcCodeName", "");
  store.commit("searchCodeStore/setTcTopCode", "");
  store.commit("searchCodeStore/setTcCpn", 1);

  // 코드
  store.commit("searchCodeStore/setCTopCode", "");
  store.commit("searchCodeStore/setCCode", "");
  store.commit("searchCodeStore/setCCodeName", "");
  store.commit("searchCodeStore/setCCpn", 1);

  // 회원관리
  store.commit("searchCustomerStore/setUserName", "");
  store.commit("searchCustomerStore/setUserEmail", "");
  store.commit("searchCustomerStore/setPremiumYn", "");
  store.commit("searchCustomerStore/setStatusYn", "");
  store.commit("searchCustomerStore/setStartRegDate", "");
  store.commit("searchCustomerStore/setEndRegDate", "");
  store.commit("searchCustomerStore/setStartLoginLastDt", "");
  store.commit("searchCustomerStore/setEndLoginLastDt", "");
  store.commit("searchCustomerStore/setCpn", 1);

  // 다이어리
  store.commit("searchDiaryStore/setBabyName", "");
  store.commit("searchDiaryStore/setParentsName", "");
  store.commit("searchDiaryStore/setOpenYn", "all");
  store.commit("searchDiaryStore/setStartRegDate", "");
  store.commit("searchDiaryStore/setEndRegDate", "");
  store.commit("searchDiaryStore/setCpn", 1);

  // 매거진
  store.commit("searchMagazineStore/getPhotoTitle", "");
  store.commit("searchMagazineStore/setParentsName", "");
  store.commit("searchMagazineStore/setStartRegDate", "");
  store.commit("searchMagazineStore/setEndRegDate", "");
  store.commit("searchMagazineStore/setCpn", 1);

  // order
  store.commit("searchOrderStore/setOrderId", "");
  store.commit("searchOrderStore/setOrderName", "");
  store.commit("searchOrderStore/setOrderState", "");

  // 약관 관리
  store.commit("searchPolicyStore/setGubun", "");
  store.commit("searchPolicyStore/setTitle", "");
  store.commit("searchPolicyStore/setStartRegDate", "");
  store.commit("searchPolicyStore/setEndRegDate", "");
  store.commit("searchPolicyStore/setCpn", 1);
}
