<template>
  <!-- 메인 페이지 -->
  <div>
    <!-- 해더 -->
    <Header />

    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />

          <!-- 내용 -->
          <!-- //.span3-->
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>상위 코드관리 등록화면</h3>
                </div>
                <div class="module-body">
                  <div class="table">
                    <table class="table table-bordered">
                      <colgroup>
                        <col width="20%" />
                        <col width="auto" />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>상위코드</th>
                          <td>
                            <input
                              type="text"
                              id="TopCode"
                              placeholder="코드를 입력하세요."
                              v-model="tcTopCode"
                              class="span3"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>상위코드명</th>
                          <td>
                            <input
                              type="text"
                              id="TopCodeName"
                              placeholder="코드명을 입력하세요."
                              class="span3"
                              v-model="topCdName"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>사용여부</th>
                          <td>
                            <div class="controls">
                              <label class="radio inline">
                                <input
                                  type="radio"
                                  name="useYn"
                                  id="useYn"
                                  value="Y"
                                  v-model="useYn"
                                />
                                사용
                              </label>
                              <label class="radio inline">
                                <input
                                  type="radio"
                                  name="useYn"
                                  id="useYn"
                                  value="N"
                                  v-model="useYn"
                                />
                                사용중지
                              </label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="flex_btn alignjust_right">
                    <a class="btn btn-large btn-primary" @click="create"
                      >저장</a
                    >
                    <a @click="goBack" class="btn btn-large btn-warning"
                      >목록</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- //.content-->
          </div>
          <!-- //.span9-->
        </div>
      </div>
      <!-- //.container-->
    </div>

    <!-- 아래 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
import axios from "axios";
import Swal from "sweetalert2";
import Vue from "vue";

export default {
  name: "CodeDetail",
  components: {
    Header,
    Footer,
    LeftMenu,
  },
  data() {
    return {
      tcTopCode: "", //코드
      topCdName: "", //코드명
      useYn: "Y", //사용여부 "Y" / "N"
    };
  },

  methods: {
    goBack() {
      var data = this;
      data.$router.replace("/code/list");

      [2];
    },

    create() {
      var data = this;
      var host = Vue.prototype.$hostname;
      if (this.tcTopCode.length < 2) {
        Swal.fire({
          title: "코드는 2글자 이상 입력해주세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      } else if (this.topCdName.length < 2) {
        Swal.fire({
          title: "코드명은 2글자 이상 입력해주세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      }
      var params = {
        topCdId: this.tcTopCode,
        topCdName: this.topCdName,
        useYn: this.useYn,
        regId: this.$store.getters["login/getAdminId"],
      };
      axios
        .post(`${host}/admin/top-code`, params, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then(function (response) {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              data.$router.replace("/code/list");
            }
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
  },
};
</script>
