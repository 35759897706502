<template>
  <!-- 회원리스트 -->

  <div>
    <!-- 해더 -->

    <Header />

    <!-- /navbar -->

    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->

          <LeftMenu />

          <!-- 내용 -->

          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>회원 등록화면</h3>
                </div>

                <div class="module-body">
                  <h4>
                    <span class="icon-chevron-right"></span>
                    기본정보
                  </h4>

                  <div class="table">
                    <table class="table table-bordered">
                      <colgroup>
                        <col width="20%" />

                        <col width="30%" />

                        <col width="20%" />

                        <col width="30%" />
                      </colgroup>

                      <tbody>
                        <tr>
                          <th>프로필 이미지</th>

                          <!-- <img src="../images/icon_user2.jpg" alt="" /> -->

                          <td colspan="3">
                            <input
                              id="file"
                              name="file"
                              type="file"
                              accept="image/*"
                              @change="checkFile"
                            />
                          </td>
                        </tr>

                        <tr>
                          <th>양육자</th>

                          <td>
                            <input
                              type="text"
                              id="user_name"
                              v-model="user_name"
                              placeholder="한글만 입력이 가능합니다."
                            />
                          </td>

                          <th>이메일</th>

                          <td>
                            <input
                              type="text "
                              v-model="user_email"
                              id="user_email"
                              placeholder="예 asd@naver.com"
                            />
                          </td>
                        </tr>

                        <tr>
                          <th>전화번호</th>

                          <td>
                            <input
                              type="tel"
                              v-model="user_phone"
                              placeholder="예: 010-1111-2222"
                            />
                          </td>

                          <th>마케팅 정보 동의</th>

                          <td>
                            <div class="controls">
                              <label class="radio inline">
                                <input
                                  type="radio"
                                  name="marking_yn"
                                  id="marking_yn"
                                  value="Y"
                                  v-model="marking_yn"
                                />
                                예
                              </label>

                              <label class="radio inline">
                                <input
                                  type="radio"
                                  name="marking_yn"
                                  id="marking_yn"
                                  value="N"
                                  v-model="marking_yn"
                                />
                                아니오
                              </label>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <th>사용용량</th>

                          <td>
                            <select name="" id="" v-model="use_volume">
                              <option value="">없음</option>
                              <option
                                v-for="(volume, i) in volumeList"
                                :key="'v' + i"
                                :value="volume.cdId"
                              >
                                {{ volume.cdName }}
                              </option>
                            </select>
                          </td>

                          <th>프리미엄 서비스</th>

                          <td>
                            <div class="controls">
                              <label class="radio inline">
                                <input
                                  type="radio"
                                  name="premium_yn"
                                  id="premium_yn"
                                  value="Y"
                                  v-model="premium_yn"
                                />
                                이용함
                              </label>

                              <label class="radio inline">
                                <input
                                  type="radio"
                                  name="premium_yn"
                                  id="premium_yn"
                                  value="N"
                                  v-model="premium_yn"
                                />
                                이용안함
                              </label>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <th>가입 방법</th>

                          <td>
                            <div class="controls">
                              <label class="radio inline">
                                <input
                                  type="radio"
                                  name="join_type"
                                  id="join_type"
                                  value="app"
                                  v-model="join_type"
                                />
                                직접가입
                              </label>

                              <label class="radio inline">
                                <input
                                  type="radio"
                                  name="join_type"
                                  id="join_type"
                                  value="kakao"
                                  v-model="join_type"
                                />
                                카카오(간편가입)
                              </label>
                            </div>
                          </td>
                          <th>비밀번호</th>
                          <td>
                            <input
                              type="text "
                              v-model="user_pwd"
                              id="user_pwd"
                              placeholder="8글자 이상 입력해주세요."
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="flex_btn alignjust_right">
                    <a class="btn btn-large btn-warning" @click="createUser"
                      >저장</a
                    >

                    <a @click="goBack" class="btn btn-large btn-primary">
                      목록
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <!-- //.content-->
          </div>

          <!-- //.span9-->
        </div>
      </div>

      <!-- //.container-->
    </div>

    <!-- 아래 -->

    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
import axios from "axios";
import Swal from "sweetalert2";
import Vue from "vue";

export default {
  name: "CustomerReg",
  components: {
    Header,
    Footer,
    LeftMenu,
  },

  data() {
    return {
      user_name: "", //양육자
      user_email: "", //이메일
      user_phone: "", //전화번호
      user_pwd: "", // 비밀번호
      marking_yn: "N", //마케팅 정보 동의 Y: 예, N:아니오
      join_type: "app", //직접가입(app) / 간편가입(카카오) kakao
      premium_yn: "N", //프리미엄 서비스 "Y:이용중","N:이용안함"
      admin_usage: "", //사용 용량
      status_yn: "Y", //상태 변경 "Y/N
      use_volume: "", //사용용량
      imageBytes: "", // 프로필 이미지
      //등록에서는 결제정보 및 가입 상태가 필요없음.

      volumeList: [],
    };
  },
  methods: {
    goBack() {
      var data = this;
      data.$router.replace("/customer/list");
      [2];
    },

    // 파일 확장자, 사이즈 체크
    checkFile() {
      // files 로 해당 파일 정보 얻기.
      var file = document.getElementById("file").files;

      // 파일 사이즈
      let maxSize = 15 * 1024 * 1024; // 15MB
      let fileLength = file.length;
      let addFileCapacity = 0;

      // file[0].name 은 파일명 입니다.
      // 정규식으로 확장자 체크
      if (!/\.(gif|jpg|jpeg|png|tiff|tif)$/i.test(file[0].name)) {
        Swal.fire({
          title:
            "gif, jpg, jpeg, png, tiff, tif 파일만 선택해 주세요.\n\n현재 파일 : " +
            file[0].name,
          icon: "warning",
          confirmButtonText: "확인",
        });
        document.getElementById("file").value = null;
      }

      for (let i = 0; i < fileLength; i++) {
        addFileCapacity = addFileCapacity + file[i].size;
      }
      if (addFileCapacity > maxSize) {
        Swal.fire({
          title: "파일 용량 제한은 15MB입니다.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        document.getElementById("file").value = null;
        return false;
      }

      // 체크를 통과했다면 종료.
      return;
    },

    createUser() {
      var data = this;
      var host = Vue.prototype.$hostname;

      if (this.user_name.length < 1) {
        Swal.fire({
          title: "이름을 입력하세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      } else if (
        // 이메일 유효성 검사
        !/^[A-Za-z0-9_\\.\\-]+@[A-Za-z0-9\\-]+\.[A-Za-z0-9\\-]+/.test(
          this.user_email
        )
      ) {
        Swal.fire({
          title: "이메일 형식을 확인하세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      }

      // 전화번호 유효성 검사
      if (!/^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/.test(this.user_phone)) {
        Swal.fire({
          title: "유효하지 않은 전화번호 입니다.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      } else if (this.user_pwd.length < 8) {
        // 비밀번호 유효성 검사
        Swal.fire({
          title: "비밀번호를 8자리 이상 입력하세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      } else if (this.user_pwd.search(/\s/) != -1) {
        Swal.fire({
          title: "비밀번호는 공백 없이 입력해주세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        alert("비밀번호는 공백 없이 입력해주세요.");
        return false;
      }
      const formData = new FormData();
      const file = document.getElementById("file");
      formData.append("file", file.files[0]);
      formData.append("userName", this.user_name);
      formData.append("userEmail", this.user_email);
      formData.append("userPhone", this.user_phone);
      formData.append("userPwd", this.user_pwd);
      formData.append("joinType", this.join_type);
      formData.append("premiumYn", this.premium_yn);
      formData.append("statusYn", this.status_yn);
      formData.append("markingYn", this.marking_yn);
      formData.append("useVolume", this.use_volume);
      formData.append("regId", this.$store.getters["login/getAdminId"]);
      formData.append("modId", this.$store.getters["login/getAdminId"]);
      axios
        .post(`${host}/admin/user`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              data.$router.replace("/customer/list");
            }
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
  },

  created() {
    this.volumeList = this.$route.params.volumeList;
  },
};
</script>
