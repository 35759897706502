import Vue from "vue";
import Vuex from "vuex";
import customerStore from "./customerStore.js";
import loginStore from "./loginStore.js";
import tokenStore from "./tokenStore.js";
import createPersistedState from "vuex-persistedstate";

// 검색 스토어
import searchCustomerStore from "./searchCustomerStore.js";
import searchBabyStore from "./searchBabyStore.js";
import searchDiaryStore from "./searchDiaryStore";
import searchMagazineStore from "./searchMagazineStore.js";
import searchNoticeStore from "./searchNoticeStore.js";
import searchFaqStore from "./searchFaqStore.js";
import searchQnaStore from "./searchQnaStore.js";
import searchPolicyStore from "./searchPolicyStore.js";
import searchAdminUserStore from "./searchAdminUserStore.js";
import searchCodeStore from "./searchCodeStore.js";
import searchOrderStore from "./searchOrderStore.js";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    customer: customerStore,
    login: loginStore,
    token: tokenStore,
    searchCustomerStore: searchCustomerStore,
    searchBabyStore: searchBabyStore,
    searchDiaryStore: searchDiaryStore,
    searchMagazineStore: searchMagazineStore,
    searchNoticeStore: searchNoticeStore,
    searchFaqStore: searchFaqStore,
    searchQnaStore: searchQnaStore,
    searchPolicyStore: searchPolicyStore,
    searchAdminUserStore: searchAdminUserStore,
    searchCodeStore: searchCodeStore,
    searchOrderStore: searchOrderStore,
  },
  plugins: [
    createPersistedState({
      paths: ["token", "login"],
    }),
  ],
});
