<template>
  <div class="navbar navbar-fixed-top">
    <div class="navbar-inner">
      <div class="container">
        <!-- <a class="brand" href="index.html">Peanut Story </a> -->
        <h1>
          <a class="brand" @click="goMain"
            ><img src="../assets/images/logo_pns.svg" alt="Peanut Story"
          /></a>
        </h1>
        <div class="nav-collapse collapse navbar-inverse-collapse">
          <ul class="nav pull-right">
            <li
              :class="LoginoutToggle == false ? 'nav-user' : 'nav-user open'"
              v-if="this.$store.getters['login/getAdminId']"
            >
              <a
                @click="ClickLoginoutToggle"
                class="dropdown-toggle"
                data-toggle="dropdown"
              >
                <span class="info"
                  ><a>{{ this.$store.getters["login/getAdminId"] }}</a> 님
                  환영합니다.</span
                >
              </a>
              <ul id="togglePage3" class="dropdown-menu">
                <li class="divider"></li>
                <li><a @click="logout">Logout</a></li>
              </ul>
            </li>
            <li v-if="!this.$store.getters['login/getAdminId']">
              <br />
              <span class="info"><a @click="goLogin">Login</a></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- /navbar-inner -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Header",
  data() {
    return {
      LoginoutToggle: false,
    };
  },
  methods: {
    ClickLoginoutToggle() {
      this.LoginoutToggle = !this.LoginoutToggle;
    },

    goLogin() {
      this.$router.push("/login");
    },
    logout() {
      this.$store.commit("login/setAdminId", "");
      this.$store.commit("token/setToken", "");
      axios.defaults.headers.common["Authorization"] = "";

      // 로그인 관련 데이터들 초기회 시키고 login 페이지로 이동
      this.$router.push("/login");
    },
    goMain() {
      this.$router.push("/");
    },
    goAdminInfo() {
      this.$router.push("/admin/info");
    },
  },
};
</script>
<style>
.brand:hover {
  cursor: pointer;
}
</style>
