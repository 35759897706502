<template>
  <!-- 회원리스트 -->
  <div>
    <!-- 해더 -->
    <Header />

    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />

          <!-- 내용 -->
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>다이어리 관리</h3>
                </div>
                <div class="module-body">
                  <div class="profile-head pb_20">
                    <div class="table">
                      <table class="table table-bordered">
                        <colgroup>
                          <col width="15%" />
                          <col width="30%" />
                          <col width="15%" />
                          <col width="40%" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>아기 이름</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="basicinput"
                                  class="span3"
                                  v-model="babyName"
                                  @keyup.enter="search(1)"
                                />
                              </div>
                            </td>
                            <th>양육자 이름</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="basicinput0"
                                  class="span3"
                                  v-model="parentsName"
                                  @keyup.enter="search(1)"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>노출상태</th>
                            <td>
                              <div class="controls">
                                <label class="radio inline">
                                  <input
                                    type="radio"
                                    name="optionsRadios1"
                                    id="optionsRadios2"
                                    value="all"
                                    v-model="openYn"
                                  />
                                  전체
                                </label>
                                <label class="radio inline">
                                  <input
                                    type="radio"
                                    name="optionsRadios1"
                                    id="optionsRadios2"
                                    value="Y"
                                    v-model="openYn"
                                  />
                                  노출
                                </label>
                                <label class="radio inline">
                                  <input
                                    type="radio"
                                    name="optionsRadios1"
                                    id="optionsRadios3"
                                    value="N"
                                    v-model="openYn"
                                  />
                                  노출중지
                                </label>
                              </div>
                            </td>

                            <th>등록일</th>
                            <td>
                              <input
                                class="w43"
                                type="date"
                                id="datepicker3"
                                v-model="startRegDate"
                              />
                              ~
                              <input
                                class="w43"
                                type="date"
                                id="datepicker4"
                                width="300"
                                v-model="endRegDate"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="flex_btn alignjust_right">
                        <a class="btn btn-large btn-warning" @click="search(1)"
                          >조회</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="module-body">
                  <div class="table">
                    <table
                      class="table table-striped table-bordered table-condensed"
                    >
                      <colgroup>
                        <col width="7%" />
                        <col width="20%" />
                        <col width="12%" />
                        <col width="12%" />
                        <col width="12%" />
                        <col width="auto" />
                        <col width="10%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th style="text-align: center">No</th>
                          <th style="text-align: center">사진/영상</th>
                          <th style="text-align: center">아기 이름</th>
                          <th style="text-align: center">양육자 이름</th>
                          <th style="text-align: center">등록일</th>
                          <th style="text-align: center">사진 날짜</th>
                          <th style="text-align: center">노출상태</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(pData, i) in payload"
                          :key="pData + i"
                          @click="
                            goDetailPage(
                              pData.diaryId,
                              pData.userName,
                              pData.babyName,
                              pData.babyId,
                              pData.babyGender,
                              pData.babyBirth,
                              pData.openYn,
                              pData.diaryPhotoPath,
                              pData.diaryPhotoId,
                              pData.userEmail
                            )
                          "
                        >
                          <td>{{ pData.rownum }}</td>
                          <!-- 이미지 담는 변수의 길이가 20 이상일 경우 이미지를 출력 (이미지일 경우 길이가 길어서 if문을 20이상으로 잡음) -->
                          <td v-if="pData.imageBytes.length >= 20">
                            <img
                              v-bind:src="
                                'data:image/jpeg;base64,' + pData.imageBytes
                              "
                              alt="사진"
                              class="list_img"
                            />
                          </td>
                          <!-- 변수명이 video일 경우 플레이 아이콘 출력 -->
                          <td v-else-if="pData.imageBytes === 'video'">
                            <img
                              src="@/assets/images/play-icon.png"
                              alt="비디오"
                              class="list_img"
                            />
                          </td>

                          <!-- 위에 조건에 해당하지 않으면 기본 유저 이미지 출력 -->
                          <td v-else>
                            <img
                              src="@/assets/images/user_01.png"
                              alt="X"
                              class="list_img"
                            />
                          </td>
                          <td>
                            {{ pData.babyName }}
                          </td>
                          <td>{{ pData.userName }}</td>
                          <td>
                            {{ $moment(pData.regDate).format("YYYY-MM-DD") }}
                          </td>
                          <td>
                            {{ $moment(pData.diaryDate).format("YYYY-MM-DD") }}
                          </td>
                          <td>
                            {{ pData.openYn === "Y" ? "노출중" : "노출중지" }}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div class="flex_btn alignjust_center mt_10">
                      <nav aria-label="Page navigation ">
                        <ul class="pagination">
                          <li class="page-item">
                            <a
                              class="page-link"
                              v-if="curPage - 5 >= 1"
                              @click="pageMove(curPage - 5)"
                              aria-label="Previous"
                            >
                              <i
                                class="fa fa-angle-left"
                                aria-hidden="true"
                              ></i>

                              <span class="sr-only">PREV</span>
                            </a>
                          </li>

                          <li
                            class="page-item"
                            v-for="(pageNo, i) in endPage"
                            :key="'p' + i"
                          >
                            <a
                              class="page-link"
                              v-if="pageNo >= startPage"
                              @click="pageMove(pageNo)"
                              :class="{ page_active: pageNo === curPage }"
                            >
                              {{ pageNo }}
                            </a>
                          </li>

                          <li
                            class="page-item"
                            v-if="pageCnt - startPage >= 5"
                            @click="pageMove(startPage + 5)"
                          >
                            <a class="page-link" aria-label="Next">
                              <i
                                class="fa fa-angle-right"
                                aria-hidden="true"
                              ></i>

                              <span class="sr-only">NEXT</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- //.content-->
          </div>
          <!-- //.span9-->
        </div>
      </div>
      <!-- //.container-->
    </div>

    <!-- 아래 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
import axios from "axios";
import Swal from "sweetalert2";
import Vue from "vue";
import { logout } from "@/class/logout.js";

export default {
  name: "DiaryList",
  components: {
    Header,
    Footer,
    LeftMenu,
  },
  data() {
    return {
      babyName: this.$store.getters["searchDiaryStore/getBabyName"],
      openYn: this.$store.getters["searchDiaryStore/getOpenYn"],
      parentsName: this.$store.getters["searchDiaryStore/getParentsName"],
      startRegDate: this.$store.getters["searchDiaryStore/getStartRegDate"],
      endRegDate: this.$store.getters["searchDiaryStore/getEndRegDate"],

      // 페이지
      rangeSize: 0,
      curPage: this.$store.getters["searchDiaryStore/getCpn"],
      startPage: 0,
      endPage: 0,
      pageCnt: 0,

      // 게시판 글
      payload: [],
    };
  },
  methods: {
    goDetailPage(
      diaryId,
      userName,
      babyName,
      babyId,
      babyGender,
      babyBirth,
      openYn,
      diaryPhotoPath,
      diaryPhotoId,
      userEmail
    ) {
      this.$router.push({
        name: "DiaryDetail",
        params: {
          diaryId: diaryId,
          parentsName: userName,
          babyName: babyName,
          babyId: babyId,
          babyGender: babyGender,
          babyBirth: babyBirth,
          openYn: openYn,
          diaryPhotoPath: diaryPhotoPath,
          diaryPhotoId: diaryPhotoId,
          userEmail: userEmail,
        },
      });
    },
    goDiaryReg() {
      this.$router.push("/diary/Reg");
    },
    search(cpn) {
      // cpn = 현재 페이지
      var data = this;
      var host = Vue.prototype.$hostname;
      if (this.startRegDate.length !== 0 && this.endRegDate.length === 0) {
        Swal.fire({
          title: "등록일",
          text: "등록일 끝나는 날짜를 입력하세요",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      } else if (
        this.endRegDate.length !== 0 &&
        this.startRegDate.length === 0
      ) {
        Swal.fire({
          title: "등록일",
          text: "등록일 시작하는 날짜를 입력하세요",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      }

      if (this.startRegDate > this.endRegDate) {
        Swal.fire({
          title: "등록일",
          text: "등록일 시작날짜가 끝나는 날짜보다 더 큽니다.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      }

      this.$store.commit("searchDiaryStore/setBabyName", this.babyName);
      this.$store.commit("searchDiaryStore/setParentsName", this.parentsName);
      this.$store.commit("searchDiaryStore/setOpenYn", this.openYn);
      this.$store.commit("searchDiaryStore/setStartRegDate", this.startRegDate);
      this.$store.commit("searchDiaryStore/setEndRegDate", this.endRegDate);
      this.$store.commit("searchDiaryStore/setCpn", cpn);
      axios
        .get(`${host}/admin/diary-list`, {
          params: {
            babyName: this.babyName,
            openYn: this.openYn,
            parentsName: this.parentsName,
            startRegDate:
              this.startRegDate.length !== 0
                ? new Date(this.startRegDate).toISOString()
                : null,

            endRegDate:
              this.endRegDate.length !== 0
                ? new Date(this.endRegDate).toISOString()
                : null,
            curPage: cpn,
          },
        })
        .then(function (response) {
          data.rangeSize = response.data.paging.rangeSize;
          data.payload = response.data.payload;
          data.startPage = response.data.paging.startPage;
          data.endPage = response.data.paging.endPage;
          data.pageCnt = response.data.paging.pageCnt;
          data.curPage = response.data.paging.curPage;
          data.$store.commit("searchDiaryStore/setCpn", cpn);
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },

    pageMove(cpn) {
      var data = this;
      var host = Vue.prototype.$hostname;

      this.$store.commit("searchDiaryStore/setCpn", cpn);
      axios
        .get(`${host}/admin/diary-list`, {
          params: {
            babyName: this.$store.getters["searchDiaryStore/getBabyName"],
            parentsName: this.$store.getters["searchDiaryStore/getParentsName"],
            gender: this.$store.getters["searchDiaryStore/getOpenYn"],
            startRegDate:
              this.$store.getters["searchDiaryStore/getStartRegDate"],
            endRegDate: this.$store.getters["searchDiaryStore/getEndRegDate"],
            curPage: this.$store.getters["searchDiaryStore/getCpn"],
          },
        })
        .then(function (response) {
          data.rangeSize = response.data.paging.rangeSize;
          data.payload = response.data.payload;
          data.startPage = response.data.paging.startPage;
          data.endPage = response.data.paging.endPage;
          data.pageCnt = response.data.paging.pageCnt;
          data.curPage = response.data.paging.curPage;
          data.$store.commit("searchDiaryStore/setCpn", cpn);
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
  },
  created() {
    var data = this;
    var host = Vue.prototype.$hostname;
    axios
      .get(`${host}/admin/diary-list`, {
        params: {
          babyName: this.$store.getters["searchDiaryStore/getBabyName"],
          parentsName: this.$store.getters["searchDiaryStore/getParentsName"],
          gender: this.$store.getters["searchDiaryStore/getOpenYn"],
          startRegDate: this.$store.getters["searchDiaryStore/getStartRegDate"],
          endRegDate: this.$store.getters["searchDiaryStore/getEndRegDate"],
          curPage: this.$store.getters["searchDiaryStore/getCpn"],
        },
      })
      .then(function (response) {
        data.rangeSize = response.data.paging.rangeSize;
        data.payload = response.data.payload;
        data.startPage = response.data.paging.startPage;
        data.endPage = response.data.paging.endPage;
        data.pageCnt = response.data.paging.pageCnt;
        data.$store.commit(
          "searchDiaryStore/setCpn",
          response.data.paging.curPage
        );
      })
      .catch(function (error) {
        if (error.response.status) {
          // 권한이 없을 경우
          if (
            error.response.status === 403 &&
            error.response.data.message === "Forbidden"
          ) {
            Swal.fire({
              title: data.$store.getters["customer/getNoAuthority"],
              icon: "error",
              confirmButtonText: "확인",
            });
            data.$router.push("/");
            return false;

            // 로그인을 안했을 경우
          } else if (
            error.response.status === 403 &&
            data.$store.getters["token/getToken"].length === 0
          ) {
            Swal.fire({
              title: data.$store.getters["customer/getNoLoginMessage"],
              icon: "error",
              confirmButtonText: "확인",
            });
            data.$router.push("/login");
            return false;

            // 새로고침을 눌렀을 경우
          } else if (
            error.response.status === 403 &&
            data.$store.getters["token/getToken"].length !== 0
          ) {
            data.$router.push("/login");
            return false;
          } else if (
            error.response.data.message.includes("The Token has expired") ===
            true
          ) {
            logout();
            data.$router.push("/login");
            return false;
          }
        }
        Swal.fire({
          title: error.response.data.message,
          icon: "error",
          confirmButtonText: "확인",
        });
      });
  },
};
</script>
