<template>
  <!-- 회원리스트 -->
  <div>
    <!-- 해더 -->
    <Header />

    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />
          <!-- 내용 -->
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>관리자 관리</h3>
                </div>
                <div class="module-body">
                  <div class="profile-head pb_20">
                    <div class="table">
                      <table class="table table-bordered">
                        <colgroup>
                          <col width="15%" />
                          <col width="30%" />
                          <col width="15%" />
                          <col width="40%" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>이름</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="admName"
                                  placeholder=""
                                  class="span3"
                                  v-model="admName"
                                  @keyup.enter="search(1)"
                                />
                              </div>
                            </td>
                            <th>관리자 등급</th>
                            <td>
                              <div class="controls">
                                <label class="radio inline">
                                  <input
                                    type="radio"
                                    name="selectLevel"
                                    id="selectLevel"
                                    value=""
                                    v-model="admLevel"
                                  />
                                  전체
                                </label>
                                <label
                                  class="radio inline"
                                  v-for="(level, i) in selectLevel"
                                  :key="'level' + i"
                                >
                                  <input
                                    type="radio"
                                    name="selectLevel"
                                    id="selectLevel"
                                    :value="level.cdId"
                                    v-model="admLevel"
                                  />
                                  {{ level.cdName }}
                                </label>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>부서</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="admDept"
                                  placeholder=""
                                  class="span3"
                                  v-model="admDept"
                                  @keyup.enter="search(1)"
                                />
                              </div>
                            </td>
                            <th>직책</th>
                            <td>
                              <div class="controls">
                                <input
                                  class="span3"
                                  type="text"
                                  id="admTitle"
                                  v-model="admTitle"
                                  @keyup.enter="search(1)"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="flex_btn alignjust_right">
                        <a class="btn btn-large btn-warning" @click="search(1)"
                          >조회</a
                        >
                        <a
                          @click="goAdminReg(selectLevel)"
                          class="btn btn-large btn-warning"
                          >등록</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="module-body">
                  <div class="table">
                    <table
                      class="table table-striped table-bordered table-condensed"
                    >
                      <colgroup>
                        <col width="5%" />
                        <col width="15%" />
                        <col width="20%" />
                        <col width="15%" />
                        <col width="15%" />
                        <col width="15%" />
                        <col width="15%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th style="text-align: center">No</th>
                          <th style="text-align: center">이름</th>
                          <th style="text-align: center">연락처</th>
                          <th style="text-align: center">부서</th>
                          <th style="text-align: center">직책</th>
                          <th style="text-align: center">관리자 등급</th>
                          <th style="text-align: center">상태</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(pData, i) in payload"
                          :key="pData + i"
                          @click="goDetailPage(pData, selectLevel)"
                        >
                          <td>{{ pData.rownum }}</td>
                          <td>{{ pData.admName }}</td>
                          <td>{{ pData.admPhone }}</td>
                          <td>{{ pData.admDept }}</td>
                          <td>{{ pData.admTitle }}</td>
                          <td>{{ pData.cdName }}</td>
                          <td>
                            {{
                              pData.admStatus === "Y"
                                ? "접속가능"
                                : "접속불가능"
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="flex_btn alignjust_center mt_10">
                      <nav aria-label="Page navigation ">
                        <ul class="pagination">
                          <li class="page-item">
                            <a
                              class="page-link"
                              v-if="curPage - 5 >= 1"
                              @click="pageMove(curPage - 5)"
                              aria-label="Previous"
                            >
                              <i
                                class="fa fa-angle-left"
                                aria-hidden="true"
                              ></i>

                              <span class="sr-only">PREV</span>
                            </a>
                          </li>

                          <li
                            class="page-item"
                            v-for="(pageNo, i) in endPage"
                            :key="'p' + i"
                          >
                            <a
                              class="page-link"
                              v-if="pageNo >= startPage"
                              @click="pageMove(pageNo)"
                              :class="{ page_active: pageNo === curPage }"
                            >
                              {{ pageNo }}
                            </a>
                          </li>

                          <li
                            class="page-item"
                            v-if="pageCnt - startPage >= 5"
                            @click="pageMove(startPage + 5)"
                          >
                            <a class="page-link" aria-label="Next">
                              <i
                                class="fa fa-angle-right"
                                aria-hidden="true"
                              ></i>

                              <span class="sr-only">NEXT</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- //.content-->
          </div>
          <!-- //.span9-->
        </div>
      </div>
      <!-- //.container-->
    </div>

    <!-- 아래 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
import axios from "axios";
import Swal from "sweetalert2";
import Vue from "vue";
import { logout } from "@/class/logout.js";

export default {
  name: "AdminList",
  components: {
    Header,
    Footer,
    LeftMenu,
  },

  data() {
    return {
      admName: this.$store.getters["searchAdminUserStore/getAdmName"], // 이름
      admDept: this.$store.getters["searchAdminUserStore/getAdmDept"], // 부서
      admLevel: this.$store.getters["searchAdminUserStore/getAdmLevel"], // 등급
      admTitle: this.$store.getters["searchAdminUserStore/getAdmTitle"], // 직책

      preAdmName: "",
      preAdmDept: "",
      preAdmLevel: "",
      preAdmTitle: "",

      // 페이지
      rangeSize: 0,
      curPage: this.$store.getters["searchAdminUserStore/getCpn"],
      startPage: 0,
      endPage: 0,
      pageCnt: 0,

      // 게시판 글
      payload: [],

      selectLevel: [],
    };
  },
  methods: {
    goDetailPage(pData, selectLevel) {
      this.$router.push({
        name: "AdminDetail",
        params: { admin: pData, selectLevel: selectLevel },
      });
    },

    goAdminReg(selectLevel) {
      this.$router.push({
        name: "AdminReg",
        params: { selectLevel: selectLevel },
      });
    },

    search(cpn) {
      var data = this;
      var host = Vue.prototype.$hostname;

      this.$store.commit("searchAdminUserStore/setAdmName", this.admName);
      this.$store.commit("searchAdminUserStore/setAdmDept", this.admDept);
      this.$store.commit("searchAdminUserStore/setAdmLevel", this.admLevel);
      this.$store.commit("searchAdminUserStore/setAdmTitle", this.admTitle);
      this.$store.commit("searchAdminUserStore/setCpn", cpn);
      axios
        .get(`${host}/admin/admin-user-list`, {
          params: {
            userName: this.admName,
            admDept: this.admDept,
            admLevel: this.admLevel,
            admTitle: this.admTitle,
            curPage: cpn,
          },
        })
        .then(function (response) {
          data.rangeSize = response.data.paging.rangeSize;
          data.payload = response.data.payload;
          data.selectLevel = response.data.level;
          data.startPage = response.data.paging.startPage;
          data.endPage = response.data.paging.endPage;
          data.pageCnt = response.data.paging.pageCnt;
          data.curPage = response.data.paging.curPage;
          data.$store.commit(
            "searchAdminUserStore/setCpn",
            response.data.paging.curPage
          );
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
    pageMove(cpn) {
      var data = this;
      var host = Vue.prototype.$hostname;

      this.$store.commit("searchAdminUserStore/setCpn", cpn);
      axios
        // local
        .get(`${host}/admin/admin-user-list`, {
          params: {
            userName: this.$store.getters["searchAdminUserStore/getAdmName"], // 이름
            admDept: this.$store.getters["searchAdminUserStore/getAdmDept"], // 부서
            admLevel: this.$store.getters["searchAdminUserStore/getAdmLevel"], // 등급
            admTitle: this.$store.getters["searchAdminUserStore/getAdmTitle"], // 직책
            curPage: this.$store.getters["searchAdminUserStore/getCpn"],
          },
        })
        .then(function (response) {
          data.rangeSize = response.data.paging.rangeSize;
          data.payload = response.data.payload;
          data.selectLevel = response.data.level;
          data.startPage = response.data.paging.startPage;
          data.endPage = response.data.paging.endPage;
          data.pageCnt = response.data.paging.pageCnt;
          data.curPage = response.data.paging.curPage;
          data.$store.commit(
            "searchAdminUserStore/setCpn",
            response.data.paging.curPage
          );
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
  },
  created() {
    var data = this;
    var host = Vue.prototype.$hostname;

    axios
      // local
      .get(`${host}/admin/admin-user-list`, {
        params: {
          userName: this.$store.getters["searchAdminUserStore/getAdmName"], // 이름
          admDept: this.$store.getters["searchAdminUserStore/getAdmDept"], // 부서
          admLevel: this.$store.getters["searchAdminUserStore/getAdmLevel"], // 등급
          admTitle: this.$store.getters["searchAdminUserStore/getAdmTitle"], // 직책
          curPage: this.$store.getters["searchAdminUserStore/getCpn"],
        },
      })
      .then(function (response) {
        data.rangeSize = response.data.paging.rangeSize;
        data.payload = response.data.payload;
        data.selectLevel = response.data.level;
        data.startPage = response.data.paging.startPage;
        data.endPage = response.data.paging.endPage;
        data.pageCnt = response.data.paging.pageCnt;
        data.$store.commit(
          "searchAdminUserStore/setCpn",
          response.data.paging.curPage
        );
      })
      .catch(function (error) {
        if (error.response.status) {
          // 권한이 없을 경우
          if (
            error.response.status === 403 &&
            error.response.data.error === "Forbidden"
          ) {
            Swal.fire({
              title: data.$store.getters["customer/getNoAuthority"],
              icon: "error",
              confirmButtonText: "확인",
            }).then(() => {
              data.$router.push("/");
            });
            return false;

            // 로그인을 안했을 경우
          } else if (
            error.response.status === 403 &&
            data.$store.getters["token/getToken"].length === 0
          ) {
            Swal.fire({
              title: data.$store.getters["customer/getNoLoginMessage"],
              icon: "error",
              confirmButtonText: "확인",
            });
            data.$router.push("/login");
            return false;

            // 새로고침을 눌렀을 경우
          } else if (
            error.response.status === 403 &&
            data.$store.getters["token/getToken"].length !== 0
          ) {
            data.$router.push("/login");
            return false;
          } else if (
            error.response.data.message.includes("The Token has expired") ===
            true
          ) {
            logout();
            data.$router.push("/login");
            return false;
          }
        }
        Swal.fire({
          title: error.response.data.message,
          icon: "error",
          confirmButtonText: "확인",
        });
      });
  },
};
</script>
