export default {
  namespaced: true,
  state: {
    userName: "",
    userEmail: "",
    noLoginMessage: "로그인이 필요합니다.",
    noAuthority: "권한이 없습니다.",
    tokenExpired: "로그인이 만료 되었습니다. 다시 로그인해주세요.",
  },
  getters: {
    getUserName(state) {
      return state.userName;
    },
    getUserEmail(state) {
      return state.userEmail;
    },
    getNoLoginMessage(state) {
      return state.noLoginMessage;
    },
    getNoAuthority(state) {
      return state.noAuthority;
    },
    getTokenExpired(state) {
      return state.tokenExpired;
    },
  },
  mutations: {
    setUserName(state, value = "") {
      state.userName = value;
    },
    setUserEmail(state, value = "") {
      state.userEmail = value;
    },
  },
};
