<template>
  <!-- 메인 페이지 -->
  <div>
    <!-- 해더 -->
    <Header />

    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />

          <!-- 내용 -->
          <!-- //.span3-->
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>코드관리 등록화면</h3>
                </div>
                <div class="module-body">
                  <div class="table">
                    <table class="table table-bordered">
                      <colgroup>
                        <col width="20%" />
                        <col width="auto" />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>상위코드명</th>
                          <td>
                            <select
                              tabindex="1"
                              data-placeholder="선택"
                              class="span8 w40"
                              id="top_code"
                              v-model="topCdId"
                            >
                              <option value="">상위코드를 선택하세요.</option>
                              <option
                                v-for="(data, i) in tcPayload"
                                :key="data + i"
                                :value="data.topCdId"
                              >
                                {{ data.topCdName }}
                              </option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <th>코드</th>
                          <td>
                            <input
                              type="text"
                              id="code"
                              placeholder="코드를 입력하세요"
                              class="span3"
                              v-model="cdId"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>코드명</th>
                          <td>
                            <input
                              type="text"
                              id="CodeName"
                              placeholder="코드명을 입력하세요"
                              class="span3"
                              v-model="cdName"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>사용여부</th>
                          <td>
                            <div class="controls">
                              <label class="radio inline">
                                <input
                                  type="radio"
                                  name="useYn"
                                  id="useYn"
                                  value="Y"
                                  v-model="useYn"
                                />
                                적용
                              </label>
                              <label class="radio inline">
                                <input
                                  type="radio"
                                  name="useYn"
                                  id="useYn"
                                  value="N"
                                  v-model="useYn"
                                />
                                적용중지
                              </label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="flex_btn alignjust_right">
                    <a class="btn btn-large btn-primary" @click="create"
                      >저장</a
                    >
                    <a class="btn btn-large btn-warning" @click="goBack"
                      >목록</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- //.content-->
          </div>
          <!-- //.span9-->
        </div>
      </div>
      <!-- //.container-->
    </div>

    <!-- 아래 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
import axios from "axios";
import Swal from "sweetalert2";
import Vue from "vue";

export default {
  name: "CodeMgDetail",
  components: {
    Header,
    Footer,
    LeftMenu,
  },
  data() {
    return {
      modRemoveToggle: false,
      cdId: "",
      topCdId: "",
      cdName: "",
      useYn: "Y",

      tcPayload: [],
    };
  },
  methods: {
    goBack() {
      var data = this;
      data.$router.replace("/code/list");
      [2];
    },

    create() {
      var data = this;
      var host = Vue.prototype.$hostname;
      if (this.topCdId === "") {
        Swal.fire({
          title: "상위코드명을 선택하세요",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      } else if (this.cdId.length < 2) {
        Swal.fire({
          title: "코드는 2글자 이상 입력해주세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      } else if (this.cdName.length < 2) {
        Swal.fire({
          title: "코드명은 2글자 이상 입력해주세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      }
      var params = {
        cdId: this.cdId,
        topCdId: this.topCdId,
        cdName: this.cdName,
        useYn: this.useYn,
        regId: this.$store.getters["login/getAdminId"],
      };
      axios
        .post(`${host}/admin/code`, params, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then(function (response) {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              data.$router.replace("/code/list");
            }
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
  },

  created() {
    this.tcPayload = this.$route.params.tcPayload;
  },
};
</script>
