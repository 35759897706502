<template>
  <!-- 회원리스트 -->
  <div>
    <!-- 해더 -->
    <Header />

    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />

          <!-- 내용 -->
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>FAQ 관리</h3>
                </div>
                <div class="module-body">
                  <div class="profile-head pb_20">
                    <div class="table">
                      <table class="table table-bordered">
                        <colgroup>
                          <col width="*" />
                          <col width="*" />
                          <col width="*" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>제목</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="title"
                                  placeholder=""
                                  class="span3"
                                  v-model="boardTitle"
                                  @keyup.enter="search(1)"
                                />
                              </div>
                            </td>
                            <th>구분</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="type"
                                  placeholder=""
                                  class="span3"
                                  v-model="type"
                                  @keyup.enter="search(1)"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="flex_btn alignjust_right">
                        <a class="btn btn-large btn-warning" @click="search(1)"
                          >조회</a
                        >
                        <a
                          @click="goFAQCreate(gubunList)"
                          class="btn btn-large btn-warning"
                          >등록</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="module-body">
                  <div class="table">
                    <table
                      class="table table-striped table-bordered table-condensed"
                    >
                      <colgroup>
                        <col width="*" />
                        <col width="*" />
                        <col width="*" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th style="text-align: center">No</th>
                          <th style="text-align: center">제목</th>
                          <th style="text-align: center">구분</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(pData, i) in payload"
                          :key="pData + i"
                          @click="goDetailPage(pData, gubunList)"
                        >
                          <td>{{ pData.rownum }}</td>
                          <td>{{ pData.ftitle }}</td>
                          <td>
                            {{ pData.cdName }}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div class="flex_btn alignjust_center mt_10">
                      <nav aria-label="Page navigation ">
                        <ul class="pagination">
                          <li class="page-item">
                            <a
                              class="page-link"
                              v-if="curPage - 5 >= 1"
                              @click="pageMove(curPage - 5)"
                              aria-label="Previous"
                            >
                              <i
                                class="fa fa-angle-left"
                                aria-hidden="true"
                              ></i>

                              <span class="sr-only">PREV</span>
                            </a>
                          </li>

                          <li
                            class="page-item"
                            v-for="(pageNo, i) in endPage"
                            :key="'p' + i"
                          >
                            <a
                              class="page-link"
                              v-if="pageNo >= startPage"
                              @click="pageMove(pageNo)"
                              :class="{ page_active: pageNo === curPage }"
                            >
                              {{ pageNo }}
                            </a>
                          </li>

                          <li
                            class="page-item"
                            v-if="pageCnt - startPage >= 5"
                            @click="pageMove(startPage + 5)"
                          >
                            <a class="page-link" aria-label="Next">
                              <i
                                class="fa fa-angle-right"
                                aria-hidden="true"
                              ></i>

                              <span class="sr-only">NEXT</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- //.content-->
          </div>
          <!-- //.span9-->
        </div>
      </div>
      <!-- //.container-->
    </div>

    <!-- 아래 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
import axios from "axios";
import Swal from "sweetalert2";
import Vue from "vue";
import { logout } from "@/class/logout.js";

export default {
  name: "FAQList",
  components: {
    Header,
    Footer,
    LeftMenu,
  },
  data() {
    return {
      startRegDate: "",
      endRegDate: "",

      // 검색조건
      boardTitle: this.$store.getters["searchFaqStore/getBoardTitle"],
      type: this.$store.getters["searchFaqStore/getType"],

      preBoardTitle: "",
      preType: "",

      // 페이지
      rangeSize: 0,
      curPage: this.$store.getters["searchFaqStore/getCpn"],
      startPage: 0,
      endPage: 0,
      pageCnt: 0,

      payload: [],
      gubunList: [],
    };
  },

  methods: {
    goFAQCreate(gubunList) {
      this.$router.push({
        name: "FAQReg",
        params: { gubunList },
      });
    },
    goDetailPage(pData, gubunList) {
      this.$router.push({
        name: "FAQDetail",
        params: { pData, gubunList },
      });
    },
    search(cpn) {
      // cpn = 현재 페이지
      var data = this;
      var host = Vue.prototype.$hostname;

      this.$store.commit("searchFaqStore/setBoardTitle", this.boardTitle);
      this.$store.commit("searchFaqStore/setType", this.type);
      this.$store.commit("searchFaqStore/setCpn", cpn);

      axios
        .get(`${host}/admin/faq-list`, {
          params: {
            boardTitle: this.boardTitle,
            type: this.Type,
            curPage: cpn,
          },
        })
        .then(function (response) {
          data.rangeSize = response.data.paging.rangeSize;
          data.payload = response.data.payload;
          data.gubunList = response.data.gubunList;
          data.startPage = response.data.paging.startPage;
          data.endPage = response.data.paging.endPage;
          data.pageCnt = response.data.paging.pageCnt;
          data.curPage = response.data.paging.curPage;
          data.$store.commit(
            "searchFaqStore/setCpn",
            response.data.paging.curPage
          );
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },

    pageMove(cpn) {
      var data = this;
      var host = Vue.prototype.$hostname;

      this.$store.commit("searchFaqStore/setCpn", cpn);
      axios
        .get(`${host}/admin/faq-list`, {
          params: {
            boardTitle: this.boardTitle,
            type: this.type,
            curPage: this.$store.getters["searchFaqStore/getCpn"],
          },
        })
        .then(function (response) {
          data.rangeSize = response.data.paging.rangeSize;
          data.payload = response.data.payload;
          data.gubunList = response.data.gubunList;
          data.startPage = response.data.paging.startPage;
          data.endPage = response.data.paging.endPage;
          data.pageCnt = response.data.paging.pageCnt;
          data.curPage = response.data.paging.curPage;
          data.$store.commit(
            "searchFaqStore/setCpn",
            response.data.paging.curPage
          );
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
  },
  created() {
    var data = this;
    var host = Vue.prototype.$hostname;
    axios
      .get(`${host}/admin/faq-list`, {
        params: {
          boardTitle: this.$store.getters["searchFaqStore/getBoardTitle"],
          type: this.$store.getters["searchFaqStore/getType"],
          curPage: this.$store.getters["searchFaqStore/getCpn"],
        },
      })
      .then(function (response) {
        data.rangeSize = response.data.paging.rangeSize;
        data.payload = response.data.payload;
        data.gubunList = response.data.gubunList;
        data.startPage = response.data.paging.startPage;
        data.endPage = response.data.paging.endPage;
        data.pageCnt = response.data.paging.pageCnt;
        data.$store.commit(
          "searchFaqStore/setCpn",
          response.data.paging.curPage
        );
      })
      .catch(function (error) {
        if (error.response.status) {
          // 권한이 없을 경우
          if (
            error.response.status === 403 &&
            error.response.data.message === "Forbidden"
          ) {
            Swal.fire({
              title: data.$store.getters["customer/getNoAuthority"],
              icon: "error",
              confirmButtonText: "확인",
            });
            data.$router.push("/");
            return false;

            // 로그인을 안했을 경우
          } else if (
            error.response.status === 403 &&
            data.$store.getters["token/getToken"].length === 0
          ) {
            Swal.fire({
              title: data.$store.getters["customer/getNoLoginMessage"],
              icon: "error",
              confirmButtonText: "확인",
            });
            data.$router.push("/login");
            return false;

            // 새로고침을 눌렀을 경우
          } else if (
            error.response.status === 403 &&
            data.$store.getters["token/getToken"].length !== 0
          ) {
            data.$router.push("/login");
            return false;
          } else if (
            error.response.data.message.includes("The Token has expired") ===
            true
          ) {
            logout();
            data.$router.push("/login");
            return false;
          }
        }
        Swal.fire({
          title: error.response.data.message,
          icon: "error",
          confirmButtonText: "확인",
        });
      });
  },
};
</script>
