<template>
  <div>
    <!-- 해더 -->
    <Header />

    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />

          <!-- 내용 -->
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>2차개발</h3>
                </div>
              </div>
            </div>
          </div>
          <!-- 내용 -->
        </div>
      </div>
    </div>

    <!-- 아래 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";

export default {
  name: "DesignList",
  components: {
    Header,
    Footer,
    LeftMenu,
  },
};
</script>