<template>
  <!-- 회원리스트 -->
  <div>
    <!-- 해더 -->
    <Header />

    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>공지사항 상세</h3>
                </div>
                <div class="module-body">
                  <div class="profile-head pb_20">
                    <div class="table">
                      <table class="table table-bordered">
                        <colgroup>
                          <col width="15%" />
                          <col width="85%" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>제목</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="title"
                                  placeholder=""
                                  class="span3"
                                  :disabled="modRemoveToggle == false"
                                  v-model="nTitle"
                                />
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th>노출여부</th>
                            <td>
                              <div class="controls">
                                <label class="radio inline">
                                  <input
                                    type="radio"
                                    name="optionsRadios1"
                                    id="open_yn"
                                    value="Y"
                                    checked=""
                                    :disabled="modRemoveToggle == false"
                                    v-model="nOpnYn"
                                  />
                                  노출
                                </label>
                                <label class="radio inline">
                                  <input
                                    type="radio"
                                    name="optionsRadios1"
                                    id="open_yn"
                                    value="N"
                                    :disabled="modRemoveToggle == false"
                                    v-model="nOpnYn"
                                  />
                                  노출중지
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th>첨부파일</th>
                            <td>
                              <div
                                class="controls"
                                v-if="
                                  existFile === false &&
                                  modRemoveToggle === true
                                "
                              >
                                <input
                                  id="file"
                                  name="file"
                                  type="file"
                                  accept="image/*, application/pdf, application/zip, .txt, .doc, .docx, .xls, .xlsx, .hwp, .txt, .pptx"
                                  @change="checkFile"
                                />
                              </div>
                              <div class="controls" v-if="existFile === true">
                                <a
                                  style="cursor: pointer"
                                  :href="url"
                                  target="_blank"
                                  >{{ originalFileId }}</a
                                >
                                <a
                                  style="margin-left: 20px"
                                  v-if="
                                    removeProfile === false &&
                                    modRemoveToggle === true
                                  "
                                  class="btn btn-large btn-danger"
                                  id="btn_del"
                                  @click="
                                    (existFile = false), (removeProfile = true)
                                  "
                                >
                                  파일 삭제 또는 수정
                                </a>
                                <span style="margin-left: 100px"
                                  >파일명을 클릭하면 다운로드 됩니다.</span
                                >
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>내용</th>

                            <td>
                              <textarea
                                cols="110"
                                rows="10"
                                v-model="nContent"
                                :disabled="modRemoveToggle == false"
                                style="resize: none"
                                id="f_content"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div class="flex_btn alignjust_right">
                        <a
                          v-show="!modRemoveToggle"
                          class="btn btn-large btn-info"
                          @click="ClickmodRemoveToggle"
                          >수정</a
                        >

                        <a
                          v-show="modRemoveToggle"
                          class="btn btn-large btn-info"
                          @click="updateNotice"
                          >저장</a
                        >
                        <a
                          class="btn btn-large btn-danger"
                          @click="deleteNotice"
                          >삭제</a
                        >

                        <a class="btn btn-large btn-primary" @click="goBack"
                          >목록</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- //.content-->
          </div>
          <!-- //.span9-->
          <!-- 내용 -->
        </div>
      </div>
      <!-- //.container-->
    </div>

    <!-- 아래 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
import axios from "axios";
import Vue from "vue";
import Swal from "sweetalert2";

export default {
  name: "NoticeDetail",
  components: {
    Header,
    Footer,
    LeftMenu,
  },
  data() {
    return {
      modRemoveToggle: false,
      nSeq: "",
      nTitle: "",
      nOpnYn: "Y",
      nContent: "",
      fileNm: "",
      filePath: "",
      originalFileId: "",

      // 파일 다운로드 url
      url: "",

      existFile: false,
      removeProfile: false,

      // 업데이트 할 때 사용되는 변수들
      // 확장자 검사 변수 허용된 확장자면 true
      extensionCheck: false,

      // 사용자가 올린 확장자명
      filesEx: "",
    };
  },
  methods: {
    goBack() {
      var data = this;
      data.$router.replace("/boarder/NoticeList");
      [2];
    },
    ClickmodRemoveToggle() {
      this.modRemoveToggle = !this.modRemoveToggle;
    },
    // 파일 확장자, 사이즈 체크
    checkFile() {
      // files 로 해당 파일 정보 얻기.
      var file = document.getElementById("file").files;

      // 파일 사이즈
      let maxSize = 15 * 1024 * 1024; // 15MB
      let fileLength = file.length;
      let addFileCapacity = 0;

      // file[0].name 은 파일명 입니다.
      // 정규식으로 확장자 체크
      if (
        !/\.(gif|jpg|jpeg|png|tiff|tif|zip|txt|doc|docx|xls|xlsx|hwp|txt|pptx|pdf)$/i.test(
          file[0].name
        )
      ) {
        Swal.fire({
          title:
            "gif, jpg, jpeg, png, tiff, tif, zip, txt, doc, docx, xls, xlsx, hwp, txt, pptx, pdf 파일만 선택해 주세요.\n\n현재 파일 : " +
            file[0].name,
          icon: "warning",
          confirmButtonText: "확인",
        });
        document.getElementById("file").value = null;
      }

      for (let i = 0; i < fileLength; i++) {
        addFileCapacity = addFileCapacity + file[i].size;
      }
      if (addFileCapacity > maxSize) {
        Swal.fire({
          title: "파일 용량 제한은 15MB입니다.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        document.getElementById("file").value = null;
        return false;
      }

      // 체크를 통과했다면 종료.
      return;
    },

    updateNotice() {
      const formData = new FormData();

      let file = null;
      try {
        file = document.getElementById("file").files[0];
        formData.append("file", file);
      } catch {
        console.log("");
      }

      if (this.nTitle.length < 5) {
        Swal.fire({
          title: "제목은 5글자 이상 입력해주세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      } else if (this.nContent.length < 10) {
        Swal.fire({
          title: "내용은 10글자 이상 입력해주세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      }

      formData.append("nSeq", this.nSeq);
      formData.append("nTitle", this.nTitle);
      formData.append("nOpnYn", this.nOpnYn);
      formData.append("nContent", this.nContent);
      formData.append("fileNm", this.fileNm);
      formData.append("filePath", this.filePath);
      formData.append("existFile", this.existFile);
      formData.append("originalFileId", this.originalFileId);
      formData.append("removeProfile", this.removeProfile);
      formData.append("modId", this.$store.getters["login/getAdminId"]);

      var data = this;
      var host = Vue.prototype.$hostname;
      axios
        .put(`${host}/admin/notice`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              data.$router.replace("/boarder/NoticeList");
            }
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },

    deleteNotice() {
      var data = this;
      var host = Vue.prototype.$hostname;
      axios
        .delete(`${host}/admin/notice/${encodeURI(this.nSeq)}`)
        .then(function (response) {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              data.$router.replace("/boarder/NoticeList");
            }
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
  },
  created() {
    var host = Vue.prototype.$hostname;
    this.nSeq = this.$route.params.nSeq;
    this.nTitle = this.$route.params.nTitle;
    this.nOpnYn = this.$route.params.nOpnYn;
    this.nContent = this.$route.params.nContent;
    this.fileNm = this.$route.params.fileNm;
    this.filePath = this.$route.params.filePath;
    this.originalFileId = this.$route.params.originalFileId;
    this.existFile = this.$route.params.originalFileId !== null ? true : false;
    this.url = `${host}/file/download/notice/${this.$route.params.nSeq}`;
  },
};
</script>
