export default {
  namespaced: true,
  state: {
    cpn: 1,
    userName: "",
    userEmail: "",
    premiumYn: "",
    statusYn: "",
    startRegDate: "",
    endRegDate: "",
    startLoginLastDt: "",
    endLoginLastDt: "",
  },
  getters: {
    getCpn(state) {
      return state.cpn;
    },
    getUserName(state) {
      return state.userName;
    },
    getUserEmail(state) {
      return state.userEmail;
    },
    getPremiumYn(state) {
      return state.premiumYn;
    },
    getStatusYn(state) {
      return state.statusYn;
    },
    getStartRegDate(state) {
      return state.startRegDate;
    },
    getEndRegDate(state) {
      return state.endRegDate;
    },
    getStartLoginLastDt(state) {
      return state.startLoginLastDt;
    },
    getEndLoginLastDt(state) {
      return state.endLoginLastDt;
    },
  },
  mutations: {
    setCpn(state, payload) {
      state.cpn = payload;
    },
    setUserName(state, payload) {
      state.userName = payload;
    },
    setUserEmail(state, payload) {
      state.userEmail = payload;
    },
    setPremiumYn(state, payload) {
      state.premiumYn = payload;
    },
    setStatusYn(state, payload) {
      state.statusYn = payload;
    },
    setStartRegDate(state, payload) {
      state.startRegDate = payload;
    },
    setEndRegDate(state, payload) {
      state.endRegDate = payload;
    },
    setStartLoginLastDt(state, payload) {
      state.startLoginLastDt = payload;
    },
    setEndLoginLastDt(state, payload) {
      state.endLoginLastDt = payload;
    },
  },
};
