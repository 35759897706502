var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('LeftMenu'),_c('div',{staticClass:"span9"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"module"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"module-body"},[_vm._m(3),_c('div',{staticClass:"table"},[_c('table',{staticClass:"table table-bordered"},[_vm._m(4),_c('tr',[_c('th',[_vm._v("상품결제 안내")]),_c('td',{attrs:{"colspan":"1"}},[_c('textarea',{staticStyle:{"resize":"none"},attrs:{"cols":"110","rows":"4","id":"pay","disabled":_vm.modRemoveToggle == false}})])]),_c('tr',[_c('th',[_vm._v("상품배송 안내")]),_c('td',{attrs:{"colspan":"1"}},[_c('textarea',{staticStyle:{"resize":"none"},attrs:{"cols":"110","rows":"4","id":"delivery","disabled":_vm.modRemoveToggle == false}})])]),_c('tr',[_c('th',[_vm._v("수거안내")]),_c('td',{attrs:{"colspan":"1"}},[_c('textarea',{staticStyle:{"resize":"none"},attrs:{"cols":"110","rows":"4","id":"collect","disabled":_vm.modRemoveToggle == false}})])]),_c('tr',[_c('th',[_vm._v("교환/반품 안내")]),_c('td',{attrs:{"colspan":"1"}},[_c('textarea',{staticStyle:{"resize":"none"},attrs:{"cols":"110","rows":"4","id":"Change/return","disabled":_vm.modRemoveToggle == false}})])])])])]),_c('div',{staticClass:"module-body"},[_vm._m(5),_vm._m(6),_c('H5',[_vm._v("! 설정 즉시 적용 되며 이전 주문건에 대해서는 적용되지 않습니다. ")])],1)])])])],1)])]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-head"},[_c('h3',[_vm._v("매거진 설정")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-body"},[_c('h4',[_c('span',{staticClass:"icon-chevron-right"}),_vm._v(" 커버")]),_c('div',{staticClass:"table"},[_c('table',{staticClass:"table table-bordered"},[_c('colgroup',[_c('col',{attrs:{"width":"15%"}}),_c('col',{attrs:{"width":"Auto"}})]),_c('tbody',[_c('tr',[_c('th',[_vm._v("소프트 커버")]),_c('td',{attrs:{"colspan":"1"}},[_c('input',{attrs:{"type":"text","id":"SoftCover","size":"1"}})]),_c('td',[_c('label',{staticClass:"radio inline"},[_vm._v(" 원")])])]),_c('tr',[_c('th',[_vm._v("하드커버")]),_c('td',{attrs:{"colspan":"1"}},[_c('input',{attrs:{"type":"text","id":"HardCover","size":"1"}})]),_c('td',[_c('label',{staticClass:"radio inline"},[_vm._v(" 원")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-body"},[_c('h4',[_c('span',{staticClass:"icon-chevron-right"}),_vm._v(" 속지")]),_c('div',{staticClass:"table"},[_c('table',{staticClass:"table table-bordered"},[_c('colgroup',[_c('col',{attrs:{"width":"20%"}}),_c('col',{attrs:{"width":"30%"}}),_c('col',{attrs:{"width":"20%"}}),_c('col',{attrs:{"width":"30%"}})]),_c('tbody',[_c('tr',[_c('th',[_vm._v("고급 유광지")]),_c('td',{attrs:{"colspan":"1"}},[_c('input',{attrs:{"type":"text","id":"U","size":"1"}})]),_c('td',[_c('label',{staticClass:"radio inline"},[_vm._v(" 원")])])]),_c('tr',[_c('th',[_vm._v("고급 무광지")]),_c('td',{attrs:{"colspan":"1"}},[_c('input',{attrs:{"type":"text","id":"Mu","size":"1"}})]),_c('td',[_c('label',{staticClass:"radio inline"},[_vm._v(" 원")])])]),_c('tr',[_c('th',[_vm._v("프리미엄 용지")]),_c('td',{attrs:{"colspan":"1"}},[_c('input',{attrs:{"type":"text","id":"premium","size":"1"}})]),_c('td',[_c('label',{staticClass:"radio inline"},[_vm._v(" 원")])])]),_c('tr',[_c('th',[_vm._v("레이플랫 용지")]),_c('td',{attrs:{"colspan":"1"}},[_c('input',{attrs:{"type":"text","id":"premiumX","size":"1"}})]),_c('td',[_c('label',{staticClass:"radio inline"},[_vm._v(" 원")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('span',{staticClass:"icon-chevron-right"}),_vm._v(" 상세이용 안내 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col',{attrs:{"width":"10%"}}),_c('col',{attrs:{"width":"Auto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('span',{staticClass:"icon-chevron-right"}),_vm._v(" 제공안내 안내 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table"},[_c('button',[_vm._v("등록 폼 필요")])])
}]

export { render, staticRenderFns }