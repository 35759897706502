<template>
  <!-- 회원리스트 -->
  <div>
    <!-- 해더 -->
    <Header />

    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />

          <!-- 내용 -->
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>다이어리 상세</h3>
                </div>
                <div class="module-body">
                  <div class="profile-head pb_20">
                    <div class="table">
                      <table class="table table-bordered">
                        <colgroup>
                          <col width="15%" />
                          <col width="30%" />
                          <col width="15%" />
                          <col width="40%" />
                        </colgroup>
                        <tbody>
                          <h5>등록자 정보</h5>
                          <tr>
                            <th>양육자 이름</th>
                            <td>
                              <div class="controls">
                                <label
                                  type="text"
                                  id="parents_Name"
                                  placeholder=""
                                  class="span3"
                                >
                                  {{ parentsName }}
                                </label>
                              </div>
                            </td>
                            <th>이메일</th>
                            <td>
                              <label class="w80" type="txt" id="email">
                                {{ userEmail }}
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="module-body">
                  <div class="table">
                    <table
                      class="table table-striped table-bordered table-condensed"
                    >
                      <colgroup>
                        <col width="*" />
                        <col width="*" />
                        <col width="*" />
                      </colgroup>
                      <thead>
                        <h5>아기 정보</h5>
                        <tr>
                          <th>아기 이름</th>
                          <th>성별</th>
                          <th>생년월일</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <label type="text" id="baby_name">
                              {{ babyName }}
                            </label>
                          </td>
                          <td>
                            <label type="text" id="baby_gender">
                              {{ babyGender === "01" ? "남자" : "여자" }}
                            </label>
                          </td>
                          <td>
                            <label class="w60" type="date" id="birthday">
                              {{
                                $moment(babyBirth).format("YYYY-MM-DD hh:mm:ss")
                              }}
                            </label>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="flex_btn alignjust_center mt_10">
                      <nav aria-label="Page navigation "></nav>
                    </div>
                  </div>
                  <div class="table">
                    <table class="table table-bordered">
                      <colgroup>
                        <col width="Auto%" />
                        <col width="Auto%" />
                      </colgroup>

                      <tbody>
                        <h5>사진/동영상</h5>
                        <template v-if="imageBytes != null">
                          <table
                            v-show="isImage"
                            width="300"
                            height="200"
                            v-if="imageBytes.length >= 20"
                          >
                            <img
                              style="margin-left: 100%"
                              v-bind:src="
                                'data:image/jpeg;base64,' + imageBytes
                              "
                              alt="사진"
                            />
                          </table>
                        </template>
                        <table v-show="isImage" width="300" height="200" v-else>
                          <img
                            src="@/assets/images/user_01.png"
                            alt="사진"
                            style="margin-left: 100%"
                          />
                        </table>

                        <table v-show="isVideo" width="300" height="200">
                          <video
                            controls="controls"
                            width="400"
                            autoplay="autoplay"
                            controlsList="nodownload"
                            style="margin-left: 100%"
                            v-bind:src="`${videoUrl}`"
                          ></video>
                        </table>

                        <tr>
                          <th>상태변경</th>
                          <td>
                            <div class="controls">
                              <label class="radio inline">
                                <input
                                  type="radio"
                                  name="optionsRadios2"
                                  id="open_yn"
                                  value="Y"
                                  v-model="openYn"
                                  :disabled="modRemoveToggle == false"
                                />
                                노출중
                              </label>
                              <label class="radio inline">
                                <input
                                  type="radio"
                                  name="optionsRadios2"
                                  id="open_yn"
                                  :disabled="modRemoveToggle == false"
                                  value="N"
                                  v-model="openYn"
                                />
                                노출 중지
                              </label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="flex_btn alignjust_right">
                  <a
                    v-show="!modRemoveToggle"
                    class="btn btn-large btn-info"
                    @click="ClickmodRemoveToggle"
                    >수정</a
                  >

                  <a
                    v-show="modRemoveToggle"
                    class="btn btn-large btn-info"
                    @click="saveDiary"
                    >저장</a
                  >
                  <a class="btn btn-large btn-danger" @click="deleteDiary"
                    >삭제</a
                  >

                  <a class="btn btn-large btn-primary" @click="goBack">목록</a>
                </div>
              </div>
            </div>
            <!-- //.content-->
          </div>
          <!-- //.span9-->
        </div>
      </div>
      <!-- //.container-->
    </div>

    <!-- 아래 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
import axios from "axios";
import Vue from "vue";
import Swal from "sweetalert2";

export default {
  name: "DiaryDetail",
  components: {
    Header,
    Footer,
    LeftMenu,
  },
  data() {
    return {
      diaryId: "",
      modRemoveToggle: false,
      parentsName: "", //양육자 이름
      userEmail: "", //이메일

      babyName: "", //아기 이름
      babyId: "", //아기 Id
      babyGender: "", //성별
      babyBirth: "", //생년월일
      openYn: "", //상태 변경
      preOpenYn: "", //상태 변경

      diaryPhotoPath: "", // 사진경로
      diaryPhotoId: "", // 사진명

      imageBytes: "",
      videoUrl: "",
      isImage: false,
      isVideo: false,
    };
  },
  methods: {
    goBack() {
      var data = this;
      data.$router.replace("/diary/list");
      [2];
    },
    ClickmodRemoveToggle() {
      this.modRemoveToggle = !this.modRemoveToggle;
    },
    saveDiary() {
      if (this.openYn === this.preOpenYn) {
        this.modRemoveToggle = !this.modRemoveToggle;
        return false;
      }
      var host = Vue.prototype.$hostname;
      var data = this;
      axios
        .put(`${host}/admin/diary`, {
          diaryId: this.diaryId,
          openYn: this.openYn,
          modId: this.$store.getters["login/getAdminId"],
        })
        .then(function (response) {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              data.$router.replace("/diary/list");
            }
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
    deleteDiary() {
      var host = Vue.prototype.$hostname;
      var data = this;
      axios
        .delete(`${host}/admin/diary/` + this.diaryId)
        .then(function (response) {
          if (response.data.delYn === "Y") {
            Swal.fire({
              title: response.data.message,
              icon: "success",
              confirmButtonText: "확인",
            }).then((result) => {
              if (result.value) {
                data.$router.replace("/diary/list");
              }
            });
          }
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
  },

  created() {
    if (this.$route.params.diaryId === undefined) {
      return false;
    }
    var host = Vue.prototype.$hostname;
    var data = this;
    this.diaryId = this.$route.params.diaryId;
    this.parentsName = this.$route.params.parentsName;
    this.userEmail = this.$route.params.userEmail;
    this.babyName = this.$route.params.babyName;
    this.babyId = this.$route.params.babyId;
    this.babyGender = this.$route.params.babyGender;
    this.babyBirth = this.$route.params.babyBirth;

    this.openYn = this.$route.params.openYn;
    this.preOpenYn = this.$route.params.openYn;
    this.diaryPhotoPath = this.$route.params.diaryPhotoPath;
    this.diaryPhotoId = this.$route.params.diaryPhotoId;

    // 확장자를 검사해서 img면 AdminDiaryController에 getDiaryImage메서드로감
    var fileLen = this.$route.params.diaryPhotoId.length;
    var lastDot = this.$route.params.diaryPhotoId.lastIndexOf(".");
    var fileExt = this.$route.params.diaryPhotoId
      .substring(lastDot, fileLen)
      .toLowerCase();
    var reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/;
    if (fileExt.match(reg)) {
      this.isImage = true;
      axios
        .get(
          `${host}/admin/diary/image?diaryPhotoPath=${encodeURI(
            this.$route.params.diaryPhotoPath
          )}&diaryPhotoId=${encodeURI(this.$route.params.diaryPhotoId)}`
        )
        .then(function (response) {
          data.imageBytes = response.data.encodedImage;
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    } else {
      // 아니면 AdminDiaryController에 getDiaryVideo메서드로감
      this.videoUrl = `${host}/admin/diary/video?fileName=${encodeURI(
        this.$route.params.diaryPhotoPath
      )}/${encodeURI(this.$route.params.diaryPhotoId)}`;
      this.isVideo = true;
    }
  },
};
</script>
