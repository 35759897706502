<template>
  <!-- 회원리스트 -->

  <div>
    <!-- 해더 -->

    <Header />

    <!-- /navbar -->

    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->

          <LeftMenu />

          <!-- 내용 -->

          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>회원관리</h3>
                </div>

                <div class="module-body">
                  <div class="profile-head pb_20">
                    <div class="table">
                      <table class="table table-bordered">
                        <colgroup>
                          <col width="15%" />

                          <col width="30%" />

                          <col width="15%" />

                          <col width="40%" />
                        </colgroup>

                        <tbody>
                          <tr>
                            <th>양육자 이름</th>

                            <td>
                              <div class="controls">
                                <input
                                  @keyup.enter="search(1)"
                                  type="text"
                                  id="parentName"
                                  class="span3"
                                  v-model="userName"
                                />
                              </div>
                            </td>

                            <th>회원 가입일</th>

                            <td>
                              <input
                                class="w43"
                                type="date"
                                id="reg_dt_Start"
                                v-model="startRegDate"
                              />
                              ~
                              <input
                                class="w43"
                                type="date"
                                id="reg_dt_End"
                                v-model="endRegDate"
                              />
                            </td>
                          </tr>

                          <tr>
                            <th>이메일</th>

                            <td>
                              <div class="controls">
                                <input
                                  @keyup.enter="search(1)"
                                  type="text"
                                  id="email"
                                  placeholder=""
                                  class="span3"
                                  v-model="userEmail"
                                />
                              </div>
                            </td>

                            <th>최근 활동일</th>

                            <td>
                              <input
                                class="w43"
                                type="date"
                                id="reg_date_Start"
                                v-model="startLoginLastDt"
                              />
                              ~
                              <input
                                class="w43"
                                type="date"
                                id="reg_date_End"
                                v-model="endLoginLastDt"
                              />
                            </td>
                          </tr>

                          <tr>
                            <th>프리미엄 서비스</th>

                            <td>
                              <div class="controls">
                                <label class="radio inline">
                                  <input
                                    type="radio"
                                    name="premiun_yn"
                                    id="premiun_yn"
                                    value=""
                                    checked=""
                                    v-model="premiumYn"
                                  />
                                  전체
                                </label>

                                <label class="radio inline">
                                  <input
                                    type="radio"
                                    name="premiun_yn"
                                    id="premiun_yn"
                                    value="Y"
                                    v-model="premiumYn"
                                  />
                                  이용중
                                </label>

                                <label class="radio inline">
                                  <input
                                    type="radio"
                                    name="premiun_yn"
                                    id="premiun_yn"
                                    value="N"
                                    v-model="premiumYn"
                                  />
                                  이용안함
                                </label>
                              </div>
                            </td>

                            <th>가입상태</th>

                            <td>
                              <div class="controls">
                                <label class="radio inline">
                                  <input
                                    type="radio"
                                    name="status_yn"
                                    id="status_yn"
                                    value=""
                                    checked=""
                                    v-model="statusYn"
                                  />
                                  전체
                                </label>

                                <label class="radio inline">
                                  <input
                                    type="radio"
                                    name="status_yn"
                                    id="status_yn"
                                    value="Y"
                                    v-model="statusYn"
                                  />
                                  이용중
                                </label>

                                <label class="radio inline">
                                  <input
                                    type="radio"
                                    name="status_yn"
                                    id="status_yn"
                                    value="N"
                                    v-model="statusYn"
                                  />
                                  이용안함
                                </label>

                                <label class="radio inline">
                                  <input
                                    type="radio"
                                    name="status_yn"
                                    id="status_yn"
                                    value="D"
                                    v-model="statusYn"
                                  />
                                  회원탈퇴
                                </label>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div class="flex_btn alignjust_right">
                        <a class="btn btn-large btn-warning" @click="search(1)">
                          조회
                        </a>

                        <a
                          class="btn btn-large btn-warning"
                          @click="goCustomerReg"
                        >
                          등록
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="module-body">
                  <div class="table">
                    <table
                      class="table table-striped table-bordered table-condensed"
                    >
                      <colgroup>
                        <col width="7%" />
                        <col width="20%" />
                        <col width="12%" />
                        <col width="12%" />
                        <col width="12%" />
                        <col width="auto" />
                        <col width="10%" />
                        <col width="10%" />
                      </colgroup>

                      <thead>
                        <tr>
                          <th style="text-align: center">No</th>
                          <th style="text-align: center">이메일</th>
                          <th style="text-align: center">양육자 이름</th>
                          <th style="text-align: center">회원 가입일</th>
                          <th style="text-align: center">최근 활동일</th>
                          <th style="text-align: center">프리미엄 서비스</th>
                          <th style="text-align: center">이용량</th>
                          <th style="text-align: center">가입상태</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="(pData, i) in payload"
                          :key="pData + i"
                          @click="goDetailPage(pData.userEmail)"
                        >
                          <td>{{ pData.rownum }}</td>

                          <td>{{ pData.userEmail }}</td>

                          <td>{{ pData.userName }}</td>

                          <td>
                            {{ $moment(pData.regDate).format("YYYY-MM-DD") }}
                          </td>

                          <td>
                            {{
                              $moment(pData.loginLastDt).format("YYYY-MM-DD")
                            }}
                          </td>

                          <td>{{ pData.premiumYn }}</td>
                          <td>{{ pData.useVolumeName }}</td>
                          <td>{{ pData.statusYn }}</td>
                        </tr>
                      </tbody>
                    </table>

                    <div class="flex_btn alignjust_center mt_10">
                      <nav aria-label="Page navigation ">
                        <ul class="pagination">
                          <li class="page-item">
                            <a
                              class="page-link"
                              v-if="curPage - 5 >= 1"
                              @click="pageMove(curPage - 5)"
                              aria-label="Previous"
                            >
                              <i
                                class="fa fa-angle-left"
                                aria-hidden="true"
                              ></i>

                              <span class="sr-only">PREV</span>
                            </a>
                          </li>

                          <li
                            class="page-item"
                            v-for="(pageNo, i) in endPage"
                            :key="'p' + i"
                          >
                            <a
                              class="page-link"
                              v-if="pageNo >= startPage"
                              @click="pageMove(pageNo)"
                              :class="{ page_active: pageNo === curPage }"
                            >
                              {{ pageNo }}
                            </a>
                          </li>

                          <li
                            class="page-item"
                            v-if="pageCnt - startPage >= 5"
                            @click="pageMove(startPage + 5)"
                          >
                            <a class="page-link" aria-label="Next">
                              <i
                                class="fa fa-angle-right"
                                aria-hidden="true"
                              ></i>

                              <span class="sr-only">NEXT</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- //.content-->
          </div>

          <!-- //.span9-->
        </div>
      </div>

      <!-- //.container-->
    </div>

    <!-- 아래 -->

    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
import axios from "axios";
import Swal from "sweetalert2";
import Vue from "vue";
import { logout } from "@/class/logout.js";

export default {
  name: "CustomerList",
  components: {
    Header,
    Footer,
    LeftMenu,
  },
  data() {
    return {
      // Search.java
      userName: this.$store.getters["searchCustomerStore/getUserName"],
      userEmail: this.$store.getters["searchCustomerStore/getUserEmail"],
      premiumYn: this.$store.getters["searchCustomerStore/getPremiumYn"],
      statusYn: this.$store.getters["searchCustomerStore/getStatusYn"],
      startRegDate: this.$store.getters["searchCustomerStore/getStartRegDate"],
      endRegDate: this.$store.getters["searchCustomerStore/getEndRegDate"],
      startLoginLastDt:
        this.$store.getters["searchCustomerStore/getStartLoginLastDt"],
      endLoginLastDt:
        this.$store.getters["searchCustomerStore/getEndLoginLastDt"],

      // 페이지
      rangeSize: 0,
      curPage: this.$store.getters["searchCustomerStore/getCpn"],
      startPage: 0,
      endPage: 0,
      pageCnt: 0,

      // 게시판 글
      payload: [],
      volumeList: [],
    };
  },
  methods: {
    goDetailPage(userEmail) {
      this.$router.push({
        name: "CustomerDetail",
        params: { userEmail: userEmail },
      });
    },
    goCustomerReg() {
      this.$router.push({
        name: "CustomerReg",
        params: {
          volumeList: this.volumeList,
        },
      });
    },
    search(cpn) {
      // cpn = 현재 페이지
      var data = this;
      var host = Vue.prototype.$hostname;
      // 회원가입 날짜
      if (this.startRegDate.length !== 0 && this.endRegDate.length === 0) {
        Swal.fire({
          title: "회원 가입일",
          text: "회원 가입일 끝나는 날짜를 입력하세요",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      } else if (
        this.endRegDate.length !== 0 &&
        this.startRegDate.length === 0
      ) {
        Swal.fire({
          title: "회원 가입일",
          text: "회원 가입일 시작하는 날짜를 입력하세요",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      }

      // 마지막 로그인 날짜
      if (
        this.startLoginLastDt.length !== 0 &&
        this.endLoginLastDt.length === 0
      ) {
        Swal.fire({
          title: "최근 활동일",
          text: "최근 활동일 끝나는 날짜를 입력하세요",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      } else if (
        this.endLoginLastDt.length !== 0 &&
        this.startLoginLastDt.length === 0
      ) {
        Swal.fire({
          title: "최근 활동일",
          text: "최근 활동일 시작하는 날짜를 입력하세요",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      }

      // 날짜 비교
      if (this.startRegDate > this.endRegDate) {
        Swal.fire({
          title: "회원 가입일",
          text: "회원 가입일 시작날짜가 끝나는 날짜보다 더 큽니다.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      }

      if (this.startLoginLastDt > this.endLoginLastDt) {
        Swal.fire({
          title: "최근 활동일",
          text: "최근 활동일 시작날짜가 끝나는 날짜보다 더 큽니다.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      }
      this.$store.commit("searchCustomerStore/setUserName", this.userName);
      this.$store.commit("searchCustomerStore/setUserEmail", this.userEmail);
      this.$store.commit("searchCustomerStore/setPremiumYn", this.premiumYn);
      this.$store.commit("searchCustomerStore/setStatusYn", this.statusYn);
      this.$store.commit(
        "searchCustomerStore/setStartRegDate",
        this.startRegDate
      );
      this.$store.commit("searchCustomerStore/setEndRegDate", this.endRegDate);
      this.$store.commit(
        "searchCustomerStore/setStartLoginLastDt",
        this.startLoginLastDt
      );
      this.$store.commit(
        "searchCustomerStore/setEndLoginLastDt",
        this.endLoginLastDt
      );
      this.$store.commit("searchCustomerStore/setCpn", cpn);

      axios

        .get(`${host}/admin/user-list`, {
          params: {
            userName: this.userName,
            userEmail: this.userEmail,
            premiumYn: this.premiumYn,
            statusYn: this.statusYn,
            startRegDate:
              this.startRegDate.length !== 0
                ? new Date(this.startRegDate).toISOString()
                : null,

            endRegDate:
              this.endRegDate.length !== 0
                ? new Date(this.endRegDate).toISOString()
                : null,

            startLoginLastDt:
              this.startLoginLastDt.length !== 0
                ? new Date(this.startLoginLastDt).toISOString()
                : null,

            endLoginLastDt:
              this.endLoginLastDt.length !== 0
                ? new Date(this.endLoginLastDt).toISOString()
                : null,

            curPage: cpn,
          },
        })
        .then(function (response) {
          data.rangeSize = response.data.paging.rangeSize;
          data.payload = response.data.payload;
          data.startPage = response.data.paging.startPage;
          data.endPage = response.data.paging.endPage;
          data.pageCnt = response.data.paging.pageCnt;
          data.curPage = response.data.paging.curPage;

          this.$store.commit("searchCustomerStore/setCpn", cpn);
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
    pageMove(cpn) {
      var data = this;
      var host = Vue.prototype.$hostname;

      this.$store.commit("searchCustomerStore/setCpn", cpn);
      axios
        // local
        .get(`${host}/admin/user-list`, {
          params: {
            userName: this.$store.getters["searchCustomerStore/getUserName"],
            userEmail: this.$store.getters["searchCustomerStore/getUserEmail"],
            premiumYn: this.$store.getters["searchCustomerStore/getPremiumYn"],
            statusYn: this.$store.getters["searchCustomerStore/getStatusYn"],
            startRegDate:
              this.$store.getters["searchCustomerStore/getStartRegDate"],
            endRegDate:
              this.$store.getters["searchCustomerStore/getEndRegDate"],
            startLoginLastDt:
              this.$store.getters["searchCustomerStore/getStartLoginLastDt"],
            endLoginLastDt:
              this.$store.getters["searchCustomerStore/getEndLoginLastDt"],
            curPage: cpn,
          },
        })
        .then(function (response) {
          data.rangeSize = response.data.paging.rangeSize;
          data.payload = response.data.payload;
          data.startPage = response.data.paging.startPage;
          data.endPage = response.data.paging.endPage;
          data.pageCnt = response.data.paging.pageCnt;
          data.curPage = response.data.paging.curPage;
          this.$store.commit(
            "searchCustomerStore/setCpn",
            response.data.paging.curPage
          );
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
  },

  created() {
    var data = this;
    var host = Vue.prototype.$hostname;
    axios
      .get(`${host}/admin/user-list`, {
        params: {
          userName: this.$store.getters["searchCustomerStore/getUserName"],
          userEmail: this.$store.getters["searchCustomerStore/getUserEmail"],
          premiumYn: this.$store.getters["searchCustomerStore/getPremiumYn"],
          statusYn: this.$store.getters["searchCustomerStore/getStatusYn"],
          startRegDate:
            this.$store.getters["searchCustomerStore/getStartRegDate"],
          endRegDate: this.$store.getters["searchCustomerStore/getEndRegDate"],
          startLoginLastDt:
            this.$store.getters["searchCustomerStore/getStartLoginLastDt"],
          endLoginLastDt:
            this.$store.getters["searchCustomerStore/getEndLoginLastDt"],
          curPage: this.$store.getters["searchCustomerStore/getCpn"],
        },
      })
      .then(function (response) {
        console.log(response);
        data.rangeSize = response.data.paging.rangeSize;
        data.payload = response.data.payload;
        data.startPage = response.data.paging.startPage;
        data.endPage = response.data.paging.endPage;
        data.pageCnt = response.data.paging.pageCnt;
        data.volumeList = response.data.volume;
        data.$store.commit(
          "searchCustomerStore/setCpn",
          response.data.paging.curPage
        );
      })
      .catch(function (error) {
        if (error.response.status) {
          // 권한이 없을 경우
          if (
            error.response.status === 403 &&
            error.response.data.message === "Forbidden"
          ) {
            Swal.fire({
              title: data.$store.getters["customer/getNoAuthority"],
              icon: "error",
              confirmButtonText: "확인",
            });
            data.$router.push("/");
            return false;

            // 로그인을 안했을 경우
          } else if (
            error.response.status === 403 &&
            data.$store.getters["token/getToken"].length === 0
          ) {
            Swal.fire({
              title: data.$store.getters["customer/getNoLoginMessage"],
              icon: "error",
              confirmButtonText: "확인",
            });
            data.$router.push("/login");
            return false;

            // 새로고침을 눌렀을 경우
          } else if (
            error.response.status === 403 &&
            data.$store.getters["token/getToken"].length !== 0
          ) {
            data.$router.push("/login");
            return false;
          } else if (
            error.response.data.message.includes("The Token has expired") ===
            true
          ) {
            logout();
            data.$router.push("/login");
            return false;
          }
        }
        Swal.fire({
          title: error.response.data.message,
          icon: "error",
          confirmButtonText: "확인",
        });
      });
  },
};
</script>
