//*************************************************************************************//
//** 파일명 : index.js
//** 작성자 : 박유신부장 -> 김기찬사원 -> 오윤숙차장
//** 작성일 : 2021.06
//** 설명
//**  - 메뉴 연결
//** 이벤트
//**  1.
//** 수정자 :
//** 수정일 :
//** 수정내용
//*************************************************************************************//

import Vue from "vue";
import VueRouter from "vue-router";

/** 메인 **/
import DashBoard from "@/views/main/DashBoard";
/** 회원 관리 */
import CustomerList from "@/views/customer/CustomerList";
import CustomerDetail from "@/views/customer/CustomerDetail";
import CustomerReg from "@/views/customer/CustomerReg";
/*  코드 */
import CodeList from "@/views/code/CodeList";
import CodeDetail from "@/views/code/CodeDetail";
import CodeReg from "@/views/code/CodeReg";
import CodeMgDetail from "@/views/code/CodeMgDetail";
import CodeMgReg from "@/views/code/CodeMgReg";
/* 아기 */
import BabyList from "@/views/baby/BabyList";
import BabyDetail from "@/views/baby/BabyDetail";
import BabyReg from "@/views/baby/BabyReg";
/* 다이어리  */
import DiaryList from "@/views/diary/DiaryList";
import DiaryDetail from "@/views/diary/DiaryDetail";
import DiaryReg from "@/views/diary/DiaryReg";
/* 매거진 */
import MagazineList from "@/views/magazine/MagazineList";
import MagazineDetail from "@/views/magazine/MagazineDetail";
import MagazineSetting from "@/views/magazine/MagazineSetting";
import MagazineReg from "@/views/magazine/MagazineReg";
/*  주문 목록  */
import orderList from "@/views/order/OrderList";
import OrderDetail from "@/views/order/OrderDetail";
import OrderReg from "@/views/order/OrderReg";
/*    */
import ReceiverInfo from "@/views/order/ReceiverInfo";
/* 관리자 목록 */
import AdminList from "@/views/admin/AdminList";
import Admindetail from "@/views/admin/AdminDetail";
import AdminInfo from "@/views/admin/AdminInfo";

/* 약관 관리  */
import PolicyList from "@/views/policy/PolicyList";
import PolicyDetail from "@/views/policy/PolicyDetail";
import PolicyReg from "@/views/policy/PolicyReg";
/* FAQ 목록  */
import FAQList from "@/views/boarder/FAQList";
import FAQDetail from "@/views/boarder/FAQDetail";
import FAQReg from "@/views/boarder/FAQReg";
/* 1:1 문의 목록 */
import BoarderList from "@/views/boarder/BoarderList";
import BoarderDetail from "@/views/boarder/BoarderDetail";
import BoarderReg from "@/views/boarder/BoarderReg";
/* 공지사항 목록 */
import NoticeList from "@/views/boarder/NoticeList";
import NoticeDetail from "@/views/boarder/NoticeDetail";
import NoticeReg from "@/views/boarder/NoticeReg";

//디자인
import DesignList from "@/views/design/DesignList";
//로그인
import AdminLogin from "@/views/admin/AdminLogin";
//
import AdminReg from "@/views/admin/AdminReg";
/*
CustomerReg
CodeReg
BabyReg
DiaryReg
MagazineReg
OrderReg
AdminReg
PolicyReg
FAQReg
BoarderReg
*/
Vue.use(VueRouter);

const routes = [
  //메인
  {
    path: "/",
    component: DashBoard,
    name: "DashBoard",
  },
  //로그인
  {
    path: "/login",
    component: AdminLogin,
    name: "AdminLogin",
  },
  //회원관리
  {
    path: "/customer/list",
    component: CustomerList,
    name: "CustomerList",
  },
  {
    path: "/customer/detail",
    component: CustomerDetail,
    name: "CustomerDetail",
  },
  {
    path: "/Customer/Reg",
    component: CustomerReg,
    name: "CustomerReg",
  },
  //아기관리
  {
    path: "/baby/list",
    component: BabyList,
    name: "BabyList",
  },
  {
    path: "/baby/detail",
    component: BabyDetail,
    name: "BabyDetail",
  },
  {
    path: "/baby/Reg",
    component: BabyReg,
    name: "BabyReg",
  },
  //다이어리관리
  {
    path: "/diary/list",
    component: DiaryList,
    name: "DiaryList",
  },
  {
    path: "/diary/detail",
    component: DiaryDetail,
    name: "DiaryDetail",
  },
  {
    path: "/diary/Reg",
    component: DiaryReg,
    name: "DiaryReg",
  },
  //매거진관리
  {
    path: "/magazine/list",
    component: MagazineList,
    name: "MagazineList",
  },
  {
    path: "/magazine/detail",
    component: MagazineDetail,
    name: "MagazineDetail",
  },
  {
    path: "/magazine/setting",
    component: MagazineSetting,
    name: "MagazineSetting",
  },
  {
    path: "/magazine/Reg",
    component: MagazineReg,
    name: "MagazineReg",
  },
  //주문관리
  {
    path: "/order/list",
    component: orderList,
    name: "orderList",
  },
  {
    path: "/order/detail",
    component: OrderDetail,
    name: "OrderDetail",
  },
  {
    path: "/order/Reg",
    component: OrderReg,
    name: "OrderReg",
  },
  {
    path: "/order/info",
    component: ReceiverInfo,
    name: "ReceiverInfo",
  },
  //관리자 관리
  {
    path: "/admin/list",
    component: AdminList,
    name: "AdminList",
  },
  {
    path: "/admin/detail",
    component: Admindetail,
    name: "AdminDetail",
  },
  {
    path: "/admin/info",
    component: AdminInfo,
    name: "AdminInfo",
  },
  {
    path: "/admin/reg",
    component: AdminReg,
    name: "AdminReg",
  },
  //약관관리
  {
    path: "/policy/list",
    component: PolicyList,
    name: "PolicyList",
  },
  {
    path: "/policy/detail",
    component: PolicyDetail,
    name: "PolicyDetail",
  },
  {
    path: "/policy/PolicyReg",
    component: PolicyReg,
    name: "PolicyReg",
  },
  //FAQ 관리
  {
    path: "/boarder/FAQList",
    component: FAQList,
    name: "FAQList",
  },
  {
    path: "/boarder/FAQDetail",
    component: FAQDetail,
    name: "FAQDetail",
  },
  {
    path: "/boarder/FAQReg",
    component: FAQReg,
    name: "FAQReg",
  },
  //1:1문의
  {
    path: "/boarder/BoarderList",
    component: BoarderList,
    name: "BoarderList",
  },
  {
    path: "/boarder/BoarderDetail",
    component: BoarderDetail,
    name: "BoarderDetail",
  },
  {
    path: "/boarder/BoarderReg",
    component: BoarderReg,
    name: "BoarderReg",
  },
  //공지사항
  {
    path: "/boarder/NoticeList",
    component: NoticeList,
    name: "NoticeList",
  },
  {
    path: "/boarder/NoticeDetail",
    component: NoticeDetail,
    name: "NoticeDetail",
  },
  {
    path: "/boarder/NoticeReg",
    component: NoticeReg,
    name: "NoticeReg",
  },
  //코드관리
  {
    path: "/code/list",
    component: CodeList,
    name: "CodeList",
  },
  {
    path: "/code/detail",
    component: CodeDetail,
    name: "CodeDetail",
  },
  {
    path: "/code/Reg",
    component: CodeReg,
    name: "CodeReg",
  },
  {
    path: "/code/mgdetail",
    component: CodeMgDetail,
    name: "CodeMgDetail",
  },
  {
    path: "/code/MgReg",
    component: CodeMgReg,
    name: "CodeMgReg",
  },
  ///////////////////////// 2차 개발 영역 ///////////////////////
  //디자인 관리
  {
    path: "/design/list",
    component: DesignList,
    name: "DesignList",
  },
  //
];

/**
 * Route(컴퍼넌트와 Path)를 정의한다.
 * web : history , android : ''
 */
export default new VueRouter({
  mode: "hash",
  routes: routes,
});
