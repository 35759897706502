import Swal from "sweetalert2";
import axios from "axios";
import store from "@/store/index.js";

/**
 * 로그아웃 함수
 */
export function logout() {
  Swal.fire({
    title: store.getters["customer/getTokenExpired"],
    icon: "warning",
    confirmButtonText: "확인",
  }).then((result) => {
    if (result.value) {
      // 로그인 관련 데이터들 초기화
      store.commit("login/setAdminId", "");
      store.commit("token/setToken", "");
      axios.defaults.headers.common["Authorization"] = "";
    }
  });
}
