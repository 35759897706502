<template>
  <!-- 메인 페이지 -->
  <div>
    <!-- 해더 -->
    <Header />

    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />

          <!-- 내용 -->
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>1:1문의 등록</h3>
                </div>
                <div class="module-body">
                  <div class="profile-head pb_20">
                    <div class="table">
                      <table class="table table-bordered">
                        <colgroup>
                          <col width="20%" />
                          <col width="80%" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>제목</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="basicinput"
                                  placeholder="제목은 5글자 이상 250자 이하로 입력해주세요."
                                  class="span3"
                                  v-model="qTitle"
                                />
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th>노출여부</th>
                            <td>
                              <div class="controls">
                                <label class="radio inline">
                                  <input
                                    type="radio"
                                    name="optionsRadios2"
                                    id="statusYn"
                                    value="Y"
                                    checked=""
                                  />
                                  노출중
                                </label>
                                <label class="radio inline">
                                  <input
                                    type="radio"
                                    name="optionsRadios2"
                                    id="statusYn"
                                    value="N"
                                    v-model="statusYn"
                                  />
                                  노출안함
                                </label>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>구분</th>
                            <td>
                              <div>
                                <select @change="gubonChange($event)">
                                  <option
                                    v-for="(data, i) in gubunList"
                                    :key="data + i"
                                    :value="data.cdId"
                                  >
                                    {{ data.cdName }}
                                  </option>
                                </select>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>내용</th>
                            <td>
                              <textarea
                                cols="110"
                                rows="10"
                                id="qContent"
                                placeholder="내용은 10글자 이상 입력해주세요."
                                v-mdoel="qContent"
                                style="resize: none"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="flex_btn alignjust_right">
                        <a class="btn btn-large btn-warning" @click="goBack"
                          >취소</a
                        >
                        <a class="btn btn-large btn-warning">저장</a>
                      </div>
                      <!---
                      <div class="flex_btn alignjust_right">
                        <a @click="goBack" class="btn btn-large btn-primary"
                          >목록</a
                        >
                      </div>
                      --->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- //.content-->
          </div>
          <!-- //.span9-->
        </div>
      </div>
      <!-- //.container-->
    </div>

    <!-- 아래 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
import axios from "axios";
import Swal from "sweetalert2";
import Vue from "vue";

export default {
  name: "BoarderDetail",
  components: {
    Header,
    Footer,
    LeftMenu,
  },
  data() {
    return {
      qTitle: "",
      qContent: "",
      statusYn: "",
      gubun: "",
      gubunList: "",
    };
  },
  methods: {
    goBack() {
      var data = this;
      data.$router.replace("/boarder/BoarderList");
      [2];
    },

    createQna() {
      var host = Vue.prototype.$hostname;
      var data = this;
      if (this.fTitle.length < 5) {
        Swal.fire({
          title: "제목은 5글자 이상 250자 이하로 입력해주세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      } else if (this.qContent.length < 10) {
        Swal.fire({
          title: "내용은 10글자 이상 입력해주세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      }
      var params = {
        qTitle: this.qTitle,
        qContent: this.qContent,
        statusYn: this.statusYn,
        gubun: this.gubun,
        regId: this.$store.getters["login/getAdminId"],
      };
      axios
        .post(`${host}/admin/qna`, params, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then(function (response) {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              data.$router.replace("/boarder/BoarderList");
            }
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
  },

  created() {
    // qna 리스트에서 gubunList를 가져옴
    this.gubunList = this.$route.params.gubunList;
    this.$route.params.gubunList[0].cdId;
  },
};
</script>
