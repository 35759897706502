<template>
  <!-- 메인 페이지 -->
  <div>
    <!-- 해더 -->
    <Header />

    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />
          <!-- 내용 -->
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>코드관리</h3>
                </div>
                <div class="module-body">
                  <ul class="profile-tab nav nav-tabs">
                    <li :class="CodeActiveToggle == false ? 'active' : ''">
                      <a @click="ClickCodeTopActiveToggle" data-toggle="tab"
                        >상위 코드 관리</a
                      >
                    </li>
                    <li :class="CodeActiveToggle == true ? 'active' : ''">
                      <a @click="ClickCodeActiveToggle" data-toggle="tab"
                        >코드 관리</a
                      >
                    </li>
                  </ul>

                  <div class="profile-tab-content tab-content">
                    <div
                      :class="
                        CodeActiveToggle == false
                          ? 'tab-pane fade active in'
                          : 'tab-pane fade in'
                      "
                      id="Topcodemanagement"
                    >
                      <div class="profile-head pb_20">
                        <div class="table">
                          <table class="table table-bordered">
                            <colgroup>
                              <col width="15%" />
                              <col width="30%" />
                              <col width="15%" />
                              <col width="40%" />
                            </colgroup>
                            <tbody>
                              <tr>
                                <th>상위코드</th>
                                <td>
                                  <div class="controls">
                                    <input
                                      type="text"
                                      id="sTopCode"
                                      placeholder="상위코드를 넣으세요."
                                      class="span3"
                                      v-model="tcTopCode"
                                      @keyup.enter="tcSearch(1)"
                                    />
                                  </div>
                                </td>
                                <th>상위코드명</th>
                                <td>
                                  <input
                                    type="text"
                                    id="sTopCodeNm"
                                    placeholder="상위코드명를 넣으세요."
                                    class="span3"
                                    v-model="tcCodeName"
                                    @keyup.enter="tcSearch(1)"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="flex_btn alignjust_right">
                            <a
                              class="btn btn-large btn-warning"
                              @click="tcSearch(1)"
                              >조회</a
                            >
                            <a
                              class="btn btn-large btn-primary"
                              @click="goCodeMgReg"
                            >
                              등록</a
                            >
                          </div>
                        </div>
                      </div>

                      <div class="table">
                        <table
                          class="table table-striped table-bordered table-condensed"
                        >
                          <colgroup>
                            <col width="5%" />
                            <col width="25%" />
                            <col width="25%" />
                            <col width="15%" />
                            <col width="15%" />
                            <col width="15%" />
                          </colgroup>
                          <thead>
                            <tr>
                              <th style="text-align: center">No</th>
                              <th style="text-align: center">상위코드</th>
                              <th style="text-align: center">상위코드명</th>
                              <th style="text-align: center">등록자</th>
                              <th style="text-align: center">사용상태</th>
                              <th style="text-align: center">등록일</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(pData, i) in tcPayload"
                              :key="pData + i"
                              @click="goCodeMgDetail(pData)"
                            >
                              <td>{{ pData.rownum }}</td>
                              <td>{{ pData.topCdId }}</td>
                              <td>{{ pData.topCdName }}</td>
                              <td>{{ pData.regId }}</td>
                              <td>{{ pData.useYn }}</td>
                              <td>
                                {{
                                  $moment(pData.regDate).format("YYYY-MM-DD")
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="flex_btn alignjust_center mt_10">
                          <nav aria-label="Page navigation ">
                            <ul class="pagination">
                              <li class="page-item">
                                <a
                                  class="page-link"
                                  v-if="tcCurPage - 5 >= 1"
                                  @click="tcPageMove(tcCurPage - 5)"
                                  aria-label="Previous"
                                >
                                  <i
                                    class="fa fa-angle-left"
                                    aria-hidden="true"
                                  ></i>

                                  <span class="sr-only">PREV</span>
                                </a>
                              </li>

                              <li
                                class="page-item"
                                v-for="(pageNo, i) in tcEndPage"
                                :key="'p' + i"
                              >
                                <a
                                  class="page-link"
                                  v-if="pageNo >= tcStartPage"
                                  @click="tcPageMove(pageNo)"
                                  :class="{ page_active: pageNo === tcCurPage }"
                                >
                                  {{ pageNo }}
                                </a>
                              </li>

                              <li
                                class="page-item"
                                v-if="tcPageCnt - tcStartPage >= 5"
                                @click="tcPageMove(tcStartPage + 5)"
                              >
                                <a class="page-link" aria-label="Next">
                                  <i
                                    class="fa fa-angle-right"
                                    aria-hidden="true"
                                  ></i>

                                  <span class="sr-only">NEXT</span>
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>

                    <div
                      :class="
                        CodeActiveToggle == true
                          ? 'tab-pane fade active in'
                          : 'tab-pane fade in'
                      "
                      @click="ClickCodeActiveToggle"
                      id="codemanagement"
                    >
                      <div class="profile-head pb_20">
                        <div class="table">
                          <table class="table table-bordered">
                            <colgroup>
                              <col width="15%" />
                              <col width="30%" />
                              <col width="15%" />
                              <col width="40%" />
                            </colgroup>
                            <tbody>
                              <tr>
                                <th>상위코드</th>
                                <td colspan="3">
                                  <div class="controls">
                                    <input
                                      type="text"
                                      id="sTopCode"
                                      placeholder="상위코드를 넣으세요."
                                      class="sTopCode"
                                      v-model="cTopCode"
                                      @keyup.enter="cSearch(1)"
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>코드</th>
                                <td>
                                  <div class="controls">
                                    <input
                                      type="text"
                                      id="sCode"
                                      placeholder="코드를 넣으세요."
                                      class="span3"
                                      v-model="cCode"
                                      @keyup.enter="cSearch(1)"
                                    />
                                  </div>
                                </td>
                                <th>코드명</th>
                                <td>
                                  <input
                                    type="text"
                                    id="sCodeNm"
                                    placeholder="코드명을 넣으세요."
                                    class="span3"
                                    v-model="cCodeName"
                                    @keyup.enter="cSearch(1)"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="flex_btn alignjust_right">
                            <a
                              class="btn btn-large btn-warning"
                              @click="cSearch(1)"
                              >조회</a
                            >
                            <a
                              class="btn btn-large btn-primary"
                              @click="goCodeReg(tcPayload)"
                              >등록
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="table">
                        <table
                          class="table table-striped table-bordered table-condensed"
                        >
                          <colgroup>
                            <col width="5%" />
                            <col width="20%" />
                            <col width="15%" />
                            <col width="15%" />
                            <col width="15%" />
                            <col width="10%" />
                            <col width="20%" />
                          </colgroup>
                          <thead>
                            <tr>
                              <th style="text-align: center">No</th>
                              <th style="text-align: center">상위코드</th>
                              <th style="text-align: center">코드</th>
                              <th style="text-align: center">코드명</th>
                              <th style="text-align: center">등록자</th>
                              <th style="text-align: center">사용상태</th>
                              <th style="text-align: center">등록일</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(pData, i) in cPayload"
                              :key="pData + i"
                              @click="goDetailPage(pData, tcPayload)"
                            >
                              <td>{{ pData.rownum }}</td>
                              <td>{{ pData.topCdId }}</td>
                              <td>{{ pData.cdId }}</td>
                              <td>{{ pData.cdName }}</td>
                              <td>{{ pData.regId }}</td>
                              <td>{{ pData.useYn }}</td>
                              <td>
                                {{
                                  $moment(pData.regDate).format("YYYY-MM-DD")
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="flex_btn alignjust_center mt_10">
                          <nav aria-label="Page navigation ">
                            <ul class="pagination">
                              <li class="page-item">
                                <a
                                  class="page-link"
                                  v-if="cCurPage - 5 >= 1"
                                  @click="cPageMove(cCurPage - 5)"
                                  aria-label="Previous"
                                >
                                  <i
                                    class="fa fa-angle-left"
                                    aria-hidden="true"
                                  ></i>

                                  <span class="sr-only">PREV</span>
                                </a>
                              </li>

                              <li
                                class="page-item"
                                v-for="(pageNo, i) in cEndPage"
                                :key="'p' + i"
                              >
                                <a
                                  class="page-link"
                                  v-if="pageNo >= cStartPage"
                                  @click="cPageMove(pageNo)"
                                  :class="{ page_active: pageNo === cCurPage }"
                                >
                                  {{ pageNo }}
                                </a>
                              </li>

                              <li
                                class="page-item"
                                v-if="cPageCnt - cStartPage >= 5"
                                @click="cPageMove(cStartPage + 5)"
                              >
                                <a class="page-link" aria-label="Next">
                                  <i
                                    class="fa fa-angle-right"
                                    aria-hidden="true"
                                  ></i>
                                  <span class="sr-only">NEXT</span>
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- //.content-->
          </div>
          <!-- //.span9-->
        </div>
      </div>
      <!-- //.container-->
    </div>

    <!-- 아래 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
import axios from "axios";
import Swal from "sweetalert2";
import Vue from "vue";
import { logout } from "@/class/logout.js";

export default {
  name: "CodeList",
  components: {
    Header,
    Footer,
    LeftMenu,
  },
  data() {
    return {
      CodeActiveToggle: false,

      // 상위코드
      tcCodeName: this.$store.getters["searchCodeStore/getTcCodeName"],
      tcTopCode: this.$store.getters["searchCodeStore/getTcTopCode"],

      // 상위코드 페이지
      tcRangeSize: 0,
      tcCurPage: this.$store.getters["searchCodeStore/getTcCpn"],
      tcStartPage: 0,
      tcEndPage: 0,
      tcPageCnt: 0,

      tcPayload: [],

      // 코드
      cTopCode: this.$store.getters["searchCodeStore/getCTopCode"],
      cCode: this.$store.getters["searchCodeStore/getCCode"],
      cCodeName: this.$store.getters["searchCodeStore/getCCodeName"],

      // 코드 페이지
      cRangeSize: 0,
      cCurPage: this.$store.getters["searchCodeStore/getCCpn"],
      cStartPage: 0,
      cEndPage: 0,
      cPageCnt: 0,

      cPayload: [],
    };
  },
  methods: {
    goDetailPage(pData, tcPayload) {
      this.$router.push({
        name: "CodeDetail",
        params: { pData, tcPayload },
      });
    },
    ClickCodeTopActiveToggle() {
      this.CodeActiveToggle = false;
    },
    ClickCodeActiveToggle() {
      this.CodeActiveToggle = true;
    },
    goCodeMgDetail(pData) {
      this.$router.push({
        name: "CodeMgDetail",
        params: { pData },
      });
    },
    goCodeReg(tcPayload) {
      this.$router.push({
        name: "CodeReg",
        params: { tcPayload },
      });
    },

    goCodeMgReg() {
      this.$router.push("/code/mgReg");
    },

    // 상위 코드 관리
    tcSearch(cpn) {
      // cpn = 현재 페이지
      var data = this;
      var host = Vue.prototype.$hostname;

      this.tcPreTopCode = this.tcTopCode;
      this.tcPreCodeName = this.tcCodeName;
      this.$store.commit("searchCodeStore/setTcCodeName", this.tcCodeName);
      this.$store.commit("searchCodeStore/setTcTopCode", this.tcTopCode);
      this.$store.commit("searchCodeStore/setTcCpn", cpn);

      axios
        .get(`${host}/admin/top-code-list`, {
          params: {
            topCode: this.tcTopCode,
            codeName: this.tcCodeName,
            curPage: cpn,
          },
        })
        .then(function (response) {
          data.tcRangeSize = response.data.paging.rangeSize;
          data.tcPayload = response.data.payload;
          data.tcStartPage = response.data.paging.startPage;
          data.tcEndPage = response.data.paging.endPage;
          data.tcPageCnt = response.data.paging.pageCnt;
          data.tcCurPage = response.data.paging.tcCurPage;
          data.$store.commit(
            "searchCodeStore/setTcCpn",
            response.data.paging.curPage
          );
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },

    tcPageMove(cpn) {
      var data = this;
      var host = Vue.prototype.$hostname;

      this.$store.commit("searchCodeStore/setTcCpn", cpn);
      axios
        .get(`${host}/admin/top-code-list`, {
          params: {
            topCode: this.$store.getters["searchCodeStore/getTcTopCode"],
            codeName: this.$store.getters["searchCodeStore/getTcCodeName"],
            curPage: this.$store.getters["searchCodeStore/getTcCpn"],
          },
        })
        .then(function (response) {
          data.tcRangeSize = response.data.paging.rangeSize;
          data.tcPayload = response.data.payload;
          data.tcStartPage = response.data.paging.startPage;
          data.tcEndPage = response.data.paging.endPage;
          data.tcPageCnt = response.data.paging.pageCnt;
          data.tcCurPage = response.data.paging.curPage;
          data.$store.commit(
            "searchCodeStore/setTcCpn",
            response.data.paging.curPage
          );
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },

    // 코드 관리
    cSearch(cpn) {
      // cpn = 현재 페이지
      var data = this;
      var host = Vue.prototype.$hostname;

      this.$store.commit("searchCodeStore/setCTopCode", this.cTopCode);
      this.$store.commit("searchCodeStore/setCCode", this.cCode);
      this.$store.commit("searchCodeStore/setCCodeName", this.cCodeName);
      this.$store.commit("searchCodeStore/setCCpn", cpn);

      axios
        .get(`${host}/admin/code-list`, {
          params: {
            topCode: this.cTopCode,
            code: this.cCode,
            codeName: this.cCodeName,
            curPage: cpn,
          },
        })
        .then(function (response) {
          data.cRangeSize = response.data.paging.rangeSize;
          data.cPayload = response.data.payload;
          data.cStartPage = response.data.paging.startPage;
          data.cEndPage = response.data.paging.endPage;
          data.cPageCnt = response.data.paging.pageCnt;
          data.cCurPage = response.data.paging.cCurPage;
          data.$store.commit(
            "searchCodeStore/setCCpn",
            response.data.paging.curPage
          );
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },

    cPageMove(cpn) {
      var data = this;
      var host = Vue.prototype.$hostname;

      this.$store.commit("searchCodeStore/setCCpn", cpn);
      axios
        .get(`${host}/admin/code-list`, {
          params: {
            topCode: this.$store.getters["searchCodeStore/getCTopCode"],
            code: this.$store.getters["searchCodeStore/getCCode"],
            codeName: this.$store.getters["searchCodeStore/getCCodeName"],
            curPage: this.$store.getters["searchCodeStore/getCCpn"],
          },
        })
        .then(function (response) {
          data.cRangeSize = response.data.paging.rangeSize;
          data.cPayload = response.data.payload;
          data.cStartPage = response.data.paging.startPage;
          data.cEndPage = response.data.paging.endPage;
          data.cPageCnt = response.data.paging.pageCnt;
          data.cCurPage = response.data.paging.curPage;
          data.$store.commit(
            "searchCodeStore/setCCpn",
            response.data.paging.curPage
          );
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
  },
  created() {
    var data = this;
    var host = Vue.prototype.$hostname;
    axios
      .get(`${host}/admin/top-code-list`, {
        params: {
          topCode: this.$store.getters["searchCodeStore/getTcTopCode"],
          codeName: this.$store.getters["searchCodeStore/getTcCodeName"],
          curPage: this.$store.getters["searchCodeStore/getTcCpn"],
        },
      })
      .then(function (response) {
        data.tcRangeSize = response.data.paging.rangeSize;
        data.tcPayload = response.data.payload;
        data.tcStartPage = response.data.paging.startPage;
        data.tcEndPage = response.data.paging.endPage;
        data.tcPageCnt = response.data.paging.pageCnt;
        data.$store.commit(
          "searchCodeStore/setTcCpn",
          response.data.paging.curPage
        );
      })
      .catch(function (error) {
        if (error.response.status) {
          // 권한이 없을 경우
          if (
            error.response.status === 403 &&
            error.response.data.message === "Forbidden"
          ) {
            Swal.fire({
              title: data.$store.getters["customer/getNoAuthority"],
              icon: "error",
              confirmButtonText: "확인",
            });
            data.$router.push("/");
            return false;

            // 로그인을 안했을 경우
          } else if (
            error.response.status === 403 &&
            data.$store.getters["token/getToken"].length === 0
          ) {
            Swal.fire({
              title: data.$store.getters["customer/getNoLoginMessage"],
              icon: "error",
              confirmButtonText: "확인",
            });
            data.$router.push("/login");
            return false;

            // 새로고침을 눌렀을 경우
          } else if (
            error.response.status === 403 &&
            data.$store.getters["token/getToken"].length !== 0
          ) {
            data.$router.push("/login");
            return false;
          } else if (
            error.response.data.message.includes("The Token has expired") ===
            true
          ) {
            logout();
            data.$router.push("/login");
            return false;
          }
        }
        Swal.fire({
          title: error.response.data.message,
          icon: "error",
          confirmButtonText: "확인",
        });
      });
  },
  mounted() {
    var data = this;
    var host = Vue.prototype.$hostname;
    axios
      .get(`${host}/admin/code-list`, {
        params: {
          topCode: this.$store.getters["searchCodeStore/getCTopCode"],
          code: this.$store.getters["searchCodeStore/getCCode"],
          codeName: this.$store.getters["searchCodeStore/getCCodeName"],
          curPage: this.$store.getters["searchCodeStore/getCCpn"],
        },
      })
      .then(function (response) {
        data.cRangeSize = response.data.paging.rangeSize;
        data.cPayload = response.data.payload;
        data.cStartPage = response.data.paging.startPage;
        data.cEndPage = response.data.paging.endPage;
        data.cPageCnt = response.data.paging.pageCnt;
        data.$store.commit(
          "searchCodeStore/setCCpn",
          response.data.paging.curPage
        );
      })
      .catch(function (error) {
        if (error.response.status) {
          // 권한이 없을 경우
          if (
            error.response.status === 403 &&
            error.response.data.error === "Forbidden"
          ) {
            Swal.fire({
              title: data.$store.getters["customer/getNoAuthority"],
              icon: "error",
              confirmButtonText: "확인",
            }).then(() => {
              data.$router.push("/");
            });
            return false;
            // 로그인을 안했을 경우
          } else if (
            error.response.status === 403 &&
            data.$store.getters["token/getToken"].length === 0
          ) {
            Swal.fire({
              title: data.$store.getters["customer/getNoLoginMessage"],
              icon: "error",
              confirmButtonText: "확인",
            });
            data.$router.push("/login");
            return false;

            // 새로고침을 눌렀을 경우
          } else if (
            error.response.status === 403 &&
            data.$store.getters["token/getToken"].length !== 0
          ) {
            data.$router.push("/login");
            return false;
          } else if (
            error.response.data.message.includes("The Token has expired") ===
            true
          ) {
            logout();
            data.$router.push("/login");
            return false;
          }
        }
        Swal.fire({
          title: error.response.data.message,
          icon: "error",
          confirmButtonText: "확인",
        });
      });
  },
};
</script>
