<template>
  <!-- 회원리스트 -->
  <div>
    <!-- 해더 -->
    <Header />

    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />

          <!-- 내용 -->
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>매거진 설정</h3>
                </div>
                <div class="module-body">
                  <h4><span class="icon-chevron-right"></span> 커버</h4>
                  <div class="table">
                    <table class="table table-bordered">
                      <colgroup>
                        <col width="15%" />
                        <col width="Auto" />
                      </colgroup>

                      <tbody>
                        <tr>
                          <th>소프트 커버</th>
                          <td colspan="1">
                            <input type="text" id="SoftCover" size="1" />
                          </td>
                          <td>
                            <label class="radio inline"> 원</label>
                          </td>
                        </tr>
                        <tr>
                          <th>하드커버</th>
                          <td colspan="1">
                            <input type="text" id="HardCover" size="1" />
                          </td>
                          <td>
                            <label class="radio inline"> 원</label>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="module-body">
                  <h4><span class="icon-chevron-right"></span> 속지</h4>
                  <div class="table">
                    <table class="table table-bordered">
                      <colgroup>
                        <col width="20%" />
                        <col width="30%" />
                        <col width="20%" />
                        <col width="30%" />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>고급 유광지</th>
                          <td colspan="1">
                            <input type="text" id="U" size="1" />
                          </td>
                          <td>
                            <label class="radio inline"> 원</label>
                          </td>
                        </tr>

                        <tr>
                          <th>고급 무광지</th>
                          <td colspan="1">
                            <input type="text" id="Mu" size="1" />
                          </td>
                          <td>
                            <label class="radio inline"> 원</label>
                          </td>
                        </tr>
                        <tr>
                          <th>프리미엄 용지</th>
                          <td colspan="1">
                            <input type="text" id="premium" size="1" />
                          </td>
                          <td>
                            <label class="radio inline"> 원</label>
                          </td>
                        </tr>

                        <tr>
                          <th>레이플랫 용지</th>
                          <td colspan="1">
                            <input type="text" id="premiumX" size="1" />
                          </td>
                          <td>
                            <label class="radio inline"> 원</label>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="module-body">
                  <h4>
                    <span class="icon-chevron-right"></span> 상세이용 안내
                  </h4>
                  <div class="table">
                    <table class="table table-bordered">
                      <colgroup>
                        <col width="10%" />
                        <col width="Auto" />
                      </colgroup>

                      <tr>
                        <th>상품결제 안내</th>
                        <td colspan="1">
                          <textarea
                            cols="110"
                            rows="4"
                            id="pay"
                            :disabled="modRemoveToggle == false"
                            style="resize: none"
                          />
                        </td>
                      </tr>

                      <tr>
                        <th>상품배송 안내</th>
                        <td colspan="1">
                          <textarea
                            cols="110"
                            rows="4"
                            id="delivery"
                            :disabled="modRemoveToggle == false"
                            style="resize: none"
                          />
                        </td>
                      </tr>

                      <tr>
                        <th>수거안내</th>
                        <td colspan="1">
                          <textarea
                            cols="110"
                            rows="4"
                            id="collect"
                            :disabled="modRemoveToggle == false"
                            style="resize: none"
                          />
                        </td>
                      </tr>

                      <tr>
                        <th>교환/반품 안내</th>
                        <td colspan="1">
                          <textarea
                            cols="110"
                            rows="4"
                            id="Change/return"
                            :disabled="modRemoveToggle == false"
                            style="resize: none"
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="module-body">
                  <h4>
                    <span class="icon-chevron-right"></span> 제공안내 안내
                  </h4>
                  <div class="table">
                    <button>등록 폼 필요</button>
                  </div>
                  <H5
                    >! 설정 즉시 적용 되며 이전 주문건에 대해서는 적용되지
                    않습니다.
                  </H5>
                </div>
              </div>
            </div>
            <!-- //.content-->
          </div>
          <!-- //.span9-->
        </div>
      </div>
      <!-- //.container-->
    </div>

    <!-- 아래 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";

export default {
  name: "MagazineSetting",
  components: {
    Header,
    Footer,
    LeftMenu,
  },
};
</script>