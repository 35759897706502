export default {
  namespaced: true,
  state: {
    cpn: 1,
    orderId: "",
    orderName: "",
    orderState: "",
  },
  getters: {
    getCpn(state) {
      return state.cpn;
    },
    getOrderId(state) {
      return state.orderId;
    },
    getOrderName(state) {
      return state.orderName;
    },
    getOrderState(state) {
      return state.orderState;
    },
  },
  mutations: {
    setCpn(state, payload) {
      state.cpn = payload;
    },
    setOrderId(state, payload) {
      state.orderId = payload;
    },
    setOrderName(state, payload) {
      state.orderName = payload;
    },
    setOrderState(state, payload) {
      state.orderState = payload;
    },
  },
};
