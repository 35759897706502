import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueMoment from "vue-moment";
import store from "./store";
import VueCookies from "vue-cookies";
//axios.defaults.headers.post['Access-Control-Allow-Origin'] = '/api';
//axios.defaults.headers.post['Access-Control-Allow-Headers'] = 'Content-Type, Authorization, Content-Length, X-Requested-With'

//connect server:true local:false

Vue.use(VueMoment);
Vue.use(VueCookies);
Vue.config.productionTip = false;
Vue.prototype.$hostname = "https://www.ddangkongstory.com:50031";
Vue.prototype.$redirectUri = "https://www.ddangkongstory.com";
// Vue.prototype.$hostname = "http://localhost:50031";
// Vue.prototype.$redirectUri = "http://localhost:8080";
new Vue({
  el: "#app",
  store,
  router: router,
  render: (h) => h(App),
}).$mount("#app");
