<template>
  <!-- 회원리스트 -->
  <div>
    <!-- 해더 -->
    <Header />

    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />

          <!-- 내용 -->
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>내 정보</h3>
                </div>
                <div class="module-body">
                  <div class="profile-head pb_20">
                    <div class="table">
                      <table class="table table-bordered">
                        <colgroup>
                          <col width="15%" />
                          <col width="85%" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>이름</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="admin_name"
                                  placeholder=""
                                  class="span3"
                                  v-model="admin_name"
                                  :readonly="modRemoveToggle == false"
                                />
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th>전화번호(ID)</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="admin_phone1"
                                  placeholder="010"
                                  class="w10"
                                  maxlength="3"
                                  v-model="admin_phone1"
                                  oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                                  :readonly="modRemoveToggle == false"
                                />-
                                <input
                                  type="text"
                                  id="admin_phone2"
                                  placeholder="0000"
                                  class="w10"
                                  maxlength="4"
                                  v-model="admin_phone2"
                                  oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                                  :readonly="modRemoveToggle == false"
                                />-
                                <input
                                  type="text"
                                  id="admin_phone3"
                                  class="w10"
                                  placeholder="0000"
                                  maxlength="4"
                                  v-model="admin_phone3"
                                  oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                                  :readonly="modRemoveToggle == false"
                                />
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th>비밀번호</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="password"
                                  id="admin_pwd"
                                  placeholder=""
                                  class="span3"
                                  v-model="admin_pwd"
                                  :readonly="modRemoveToggle == false"
                                />
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th>비밀번호 확인</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="password"
                                  id="admin_pwdChecking"
                                  placeholder=""
                                  class="span3"
                                  v-model="admin_pwdChecking"
                                  :readonly="modRemoveToggle == false"
                                />
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th>부서</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="admin_dept"
                                  placeholder=""
                                  class="span3"
                                  v-model="admin_dept"
                                  :readonly="modRemoveToggle == false"
                                />
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th>직책</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="admin_title"
                                  placeholder=""
                                  class="span3"
                                  v-model="admin_title"
                                  :readonly="modRemoveToggle == false"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>관리자 등급</th>
                            <td>
                              <div class="controls">
                                <select v-model="admin_level">
                                  <option value="1">관리자</option>
                                  <option value="2">총괄관리자</option>
                                  <option value="3">직원</option>
                                </select>
                              </div>
                            </td>
                          </tr>

                          <th>상태</th>
                          <td>
                            <div class="controls">
                              <label class="radio inline">
                                <input
                                  type="radio"
                                  name="open_yn"
                                  id="open_yn"
                                  value="Y"
                                  checked=""
                                  :disabled="modRemoveToggle == false"
                                />
                                접속가능
                              </label>
                              <label class="radio inline">
                                <input
                                  type="radio"
                                  name="open_yn"
                                  id="open_yn"
                                  value="N"
                                  :disabled="modRemoveToggle == false"
                                />
                                접속불가
                              </label>
                            </div>
                          </td>
                        </tbody>
                      </table>
                      <div class="flex_btn alignjust_right">
                        <a
                          v-show="!modRemoveToggle"
                          class="btn btn-large btn-info"
                          @click="ClickmodRemoveToggle"
                          >수정</a
                        >
                        <a
                          v-show="modRemoveToggle"
                          class="btn btn-large btn-info"
                          @click="ClickmodRemoveToggle"
                          >저장</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- //.content-->
          </div>
          <!-- //.span9-->
        </div>
      </div>
      <!-- //.container-->
    </div>

    <!-- 아래 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";

export default {
  name: "AdminDetail",
  components: {
    Header,
    Footer,
    LeftMenu,
  },
  data() {
    return {
      modRemoveToggle: false,
      admin_name: "관리자 이름", //관리자 이름
      admin_phone1: "010", //관리자 전화번호
      admin_phone2: "2222", //관리자 전화번호
      admin_phone3: "3333", //관리자 전화번호
      admin_pwd: "관리자 비밀번호", //관리자 비밀번호
      admin_pwdChecking: "관리자 비밀번호 확인", // 관리자 비밀번호 확인
      admin_dept: "관리자 부서", //관리자 부서
      admin_title: "직책", //직책
      admin_level: "관리자", //관리자 등급
      admin_status: "관리자 상태", //관리자 상태

      code_yn: "N",
    };
  },

  methods: {
    goBack() {
      this.$router.go(-1);
      [2];
    },
    ClickmodRemoveToggle() {
      this.modRemoveToggle = !this.modRemoveToggle;
    },
  },
};
</script>
