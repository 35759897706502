<template>
  <div class="span3">
    <div class="sidebar">
      <ul class="widget widget-menu unstyled">
        <!--li class="active">
          <a @click="goDashBoard"><i class="menu-icon icon-dashboard"></i>대시보드</a> //메인과 동일하므로 생략해도 됨.
        </li-->
        <li>
          <a @click="goCustomerList"
            ><i class="menu-icon icon-inbox"></i>회원관리</a
          >
        </li>
        <li>
          <a @click="goBabyList"
            ><i class="menu-icon icon-inbox"></i>아기 등록현황</a
          >
        </li>
        <li>
          <a @click="goDiaryList"
            ><i class="menu-icon icon-inbox"></i>다이어리 관리</a
          >
        </li>
        <li>
          <a @click="goMagazineList"
            ><i class="menu-icon icon-inbox"></i>매거진 관리</a
          >
        </li>

        <li>
          <a
            :class="magazineorderToggle == false ? 'collapsed' : ''"
            data-toggle="collapse"
            @click="goOrderList"
          >
            <i class="menu-icon icon-inbox"></i>
            매거진 주문 관리
          </a>
          <ul
            id="togglePages3"
            :class="
              magazineorderToggle == false
                ? 'collapse unstyled'
                : 'collapse in unstyled'
            "
          >
            <!-- <li>
              <a @click="goOrderList"> <i class="icon-inbox"></i>주문관리</a>
            </li> -->
            <!--li>
              <a @click="goDesignList"><i class="icon-inbox"></i>배송관리 </a>
            </li>
            <li>
              <a @click="goDesignList"
                ><i class="icon-inbox"></i>취소/교환/반품/환불
              </a>
            </li-->
            <!-- <li>
              <a @click="goMagazineSetting"
                ><i class="icon-inbox"></i>매거진 설정
              </a>
            </li> -->
          </ul>
        </li>

        <!-- <li>
          <a
            :class="reviewToggle == false ? 'collapsed' : ''"
            data-toggle="collapse"
            @click="clickreviewToggle"
          >
            <i class="menu-icon icon-tasks"></i>
            <i class="icon-chevron-down pull-right"></i>
            <i class="icon-chevron-up pull-right"></i>
            리뷰관리
          </a>
          <ul
            id="togglePages3"
            :class="
              reviewToggle == false
                ? 'collapse unstyled'
                : 'collapse in unstyled'
            "
          >
            <li>
              <a @click="goDesignList"
                ><i class="icon-inbox"></i>매거진 주문
              </a>
            </li>
          </ul>
        </li> -->
        <!-- <li>
          <a @click="goDesignList"
            ><i class="menu-icon icon-inbox"></i>커뮤니티</a
          >
        </li> -->
        <!--li>
          <a @click="goDesignList"
            ><i class="menu-icon icon-inbox"></i>프리미엄 비용 설정</a
          >
        </li-->
        <!--li>
          <a
            :class="magazineToggle == false ? 'collapsed' : ''"
            data-toggle="collapse"
            @click="clickMagazineToggle"
          >
            <i class="menu-icon icon-tasks"></i>
            <i class="icon-chevron-down pull-right"></i>
            <i class="icon-chevron-up pull-right"></i>
            디자인 관리
          </a>
          <ul
            id="togglePages3"
            :class="
              magazineToggle == false
                ? 'collapse unstyled'
                : 'collapse in unstyled'
            "
          >
            <li>
              <a @click="goDesignList"><i class="icon-inbox"></i>배너 관리 </a>
            </li>
            <li>
              <a @click="goDesignList"><i class="icon-inbox"></i>팝업 관리 </a>
            </li>
          </ul>
        </li-->
        <!--li>
          <a @click="goDesignList"
            ><i class="menu-icon icon-inbox"></i>마케팅</a
          >
        </li-->
        <li>
          <a
            :class="customerToggle == false ? 'collapsed' : ''"
            data-toggle="collapse"
            @click="clickcustomerToggle"
          >
            <i class="menu-icon icon-tasks"></i>
            <i class="icon-chevron-down pull-right"></i>
            <i class="icon-chevron-up pull-right"></i>
            고객센터 관리
          </a>
          <ul
            id="togglePages4"
            :class="
              customerToggle == false
                ? 'collapse unstyled'
                : 'collapse in unstyled'
            "
          >
            <li>
              <a @click="goNoticeList"><i class="icon-inbox"></i>공지사항 </a>
            </li>
            <li>
              <a @click="goFAQList"><i class="icon-inbox"></i>FAQ </a>
            </li>
            <li>
              <a @click="goBoarderList"><i class="icon-inbox"></i>1:1 문의 </a>
            </li>
          </ul>
        </li>

        <li>
          <a @click="goPolicyList"
            ><i class="menu-icon icon-inbox"></i>약관 관리</a
          >
        </li>
        <li>
          <a @click="goAdminList"
            ><i class="menu-icon icon-inbox"></i>관리자 관리</a
          >
        </li>
        <li>
          <a @click="goCodeList"
            ><i class="menu-icon icon-inbox"></i>코드 관리</a
          >
        </li>
        <li>
          <a @click="logout"><i class="menu-icon icon-signout"></i>Logout </a>
        </li>
      </ul>
    </div>
    <!-- //.sidebar-->
  </div>
  <!-- //.span3-->
</template>

<script>
import { storeClear } from "@/class/storeClear.js";
import axios from "axios";
export default {
  name: "LeftMenu",
  data() {
    return {
      magazineorderToggle: false, // 매거진 주문 관리
      reviewToggle: false, // 리뷰
      magazineToggle: false, // 디자인 관리
      customerToggle: false, // 고객 센터
    };
  },
  methods: {
    logout() {
      this.$store.commit("login/setAdminId", "");
      this.$store.commit("token/setToken", "");
      axios.defaults.headers.common["Authorization"] = "";

      // 로그인 관련 데이터들 초기화 시키고 login 페이지로 이동
      this.$router.push("/login");
    },
    clickmagazineorderToggle() {
      this.magazineorderToggle =
        this.magazineorderToggle == false ? true : false;
    },

    clickreviewToggle() {
      this.reviewToggle = this.reviewToggle == false ? true : false;
    },

    clickMagazineToggle() {
      this.magazineToggle = this.magazineToggle == false ? true : false;
    },

    clickcustomerToggle() {
      this.customerToggle = this.customerToggle == false ? true : false;
    },

    goMainPage() {
      //메인
      storeClear();
      this.$router.push("/");
    },

    goCustomerList() {
      storeClear();
      this.$router.push("/customer/list");
    },

    goCustomerDetail() {
      this.$router.push("/customer/detail");
    },

    goCodeList() {
      storeClear();
      this.$router.push("/code/list");
    },

    goCodedetail() {
      storeClear();
      this.$router.push("/code/detail");
    },

    goBabyList() {
      storeClear();
      this.$router.push("/baby/list");
    },

    goDiaryList() {
      storeClear();
      this.$router.push("/diary/list");
    },

    goDiaryDetail() {
      storeClear();
      this.$router.push("/diary/detail");
    },

    goMagazineList() {
      storeClear();
      this.$router.push("/magazine/list");
    },

    goMagazineDetail() {
      storeClear();
      this.$router.push("/magazine/detail");
    },

    goMagazineSetting() {
      storeClear();
      this.$router.push("/magazine/setting");
    },

    goOrderList() {
      storeClear();
      this.$router.push("/order/list");
    },

    goOrderdetail() {
      storeClear();
      this.$router.push("/order/detail");
    },

    goReceiverInfo() {
      storeClear();
      this.$router.push("/order/info");
    },

    goAdminList() {
      storeClear();
      this.$router.push("/admin/list");
    },

    goAdmindetail() {
      storeClear();
      this.$router.push("/admin/detail");
    },

    goPolicyList() {
      storeClear();
      this.$router.push("/policy/list");
    },

    goPolicyList2() {
      storeClear();
      this.$router.push("/policy/list");
    },

    goPolicydetail() {
      storeClear();
      this.$router.push("/policy/detail");
    },

    goFAQList() {
      storeClear();
      this.$router.push("/boarder/FAQList");
    },

    goFAQDetail() {
      storeClear();
      this.$router.push("/boarder/FAQDetail");
    },

    goBoarderList() {
      storeClear();
      this.$router.push("/boarder/BoarderList");
    },

    goBoarderdetail() {
      storeClear();
      this.$router.push("/boarder/BoarderDetail");
    },

    goNoticeList() {
      storeClear();
      this.$router.push("/boarder/NoticeList");
    },

    goNoticDetail() {
      storeClear();
      this.$router.push("/boarder/NoticeDetail");
    },

    goDesignList() {
      storeClear();
      this.$router.push("/design/list");
    },

    goDashBoard() {
      storeClear();
      this.$router.push("/");
    },
  },
};
</script>
