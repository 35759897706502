<template>
  <!-- 회원리스트 -->
  <div>
    <!-- 해더 -->
    <Header />

    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />

          <!-- 내용 -->
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>주문 관리</h3>
                </div>
                <div class="module-body">
                  <div class="profile-head pb_20">
                    <div class="table">
                      <table class="table table-bordered">
                        <colgroup>
                          <col width="15%" />
                          <col width="30%" />
                          <col width="15%" />
                          <col width="40%" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <!-- <th>상품명</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="goodsName"
                                  v-model="productName"
                                  class="span3"
                                />
                              </div>
                            </td> -->
                            <th>주문자</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="order_name"
                                  v-model="orderName"
                                  class="span3"
                                  @keyup.enter="search(1)"
                                />
                              </div>
                            </td>
                            <th>주문번호</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="order_id"
                                  class="span3"
                                  v-model="orderId"
                                  @keyup.enter="search(1)"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>주문상태</th>
                            <td colspan="3">
                              <div class="controls">
                                <label class="radio inline">
                                  <input
                                    type="radio"
                                    name="optionsRadios1"
                                    id="order_state"
                                    value=""
                                    checked=""
                                    v-model="orderState"
                                  />
                                  전체
                                </label>
                                <label
                                  class="radio inline"
                                  v-for="(gubun, i) in gubunList"
                                  :key="'g' + i"
                                >
                                  <input
                                    type="radio"
                                    name="optionsRadios1"
                                    id="order_state"
                                    :value="gubun.cdId"
                                    v-model="orderState"
                                  />
                                  {{ gubun.cdName }}
                                </label>
                              </div>
                            </td>
                          </tr>
                          <!-- <tr>
                            <th>결제방법</th>
                            <td>
                              <div class="controls" id="pay_way"></div>
                            </td>
                            <th>운송장번호</th>
                            <td>
                              <span>
                                <select class="w50" id="deliver_id">
                                  <option>배송사 선택</option>
                                  <option></option>
                                  <option></option>
                                  <option></option>
                                </select>
                                <input
                                  type="text"
                                  id="delivery_info"
                                  class="w50"
                                />
                              </span>
                            </td>
                          </tr> -->
                        </tbody>
                      </table>
                      <div class="flex_btn alignjust_right">
                        <a class="btn btn-large btn-warning" @click="search(1)"
                          >조회</a
                        >
                        <a
                          class="btn btn-large btn-warning"
                          :href="url"
                          target="_blank"
                          style="color: white"
                          >엑셀 다운로드</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="module-body">
                  <div class="table">
                    <table
                      class="table table-striped table-bordered table-condensed"
                    >
                      <colgroup>
                        <col width="3%" />
                        <col width="11%" />
                        <col width="10%" />
                        <col width="8%" />
                        <col width="20%" />
                        <col width="8%" />
                        <col width="10%" />
                        <col width="10%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th style="text-align: center">No</th>
                          <th style="text-align: center">주문일</th>
                          <th style="text-align: center">주문번호</th>
                          <th style="text-align: center">주문자</th>
                          <th style="text-align: center">포토북</th>
                          <th style="text-align: center">수량</th>
                          <th style="text-align: center">결제 금액</th>
                          <!-- <th>결제 방법</th>
                          <th>결제 상태</th> -->
                          <th style="text-align: center">주문 상태</th>
                          <!-- <th>운송장정보</th> -->
                          <th style="text-align: center">요청사항</th>
                          <!-- <th>제작현황</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(pData, i) in payload"
                          :key="pData + i"
                          @click="goDetailPage(pData.orderId)"
                        >
                          <td>{{ pData.rownum }}</td>
                          <td style="text-align: center">
                            {{ $moment(pData.orderDate).format("YYYY-MM-DD") }}
                          </td>
                          <td style="text-align: center">
                            {{ pData.orderId }}
                          </td>
                          <td style="text-align: center">
                            {{ pData.orderName }}
                          </td>
                          <td>
                            {{ pData.magazineTitle }}
                          </td>
                          <td style="text-align: center">
                            {{ pData.orderNum }}
                          </td>
                          <td style="text-align: right">
                            {{ pData.orderPrice }}
                          </td>
                          <!-- <td></td>
                          <td></td> -->
                          <td>
                            <p v-if="pData.orderState === '01'">{{ "주문" }}</p>
                            <p v-else-if="pData.orderState === '02'">
                              {{ "결제" }}
                            </p>
                            <p v-else-if="pData.orderState === '03'">
                              {{ "배송" }}
                            </p>
                            <p v-else-if="pData.orderState === '04'">
                              {{ "배송완료" }}
                            </p>
                            <p v-else-if="pData.orderState === '05'">
                              {{ "환불신청" }}
                            </p>
                            <p v-else-if="pData.orderState === '06'">
                              {{ "환불완료" }}
                            </p>
                            <p v-else-if="pData.orderState === '07'">
                              {{ "구매취소" }}
                            </p>
                          </td>
                          <!-- <td></td> -->
                          <td>{{ pData.requestedTerm }}</td>
                          <!-- <td></td> -->
                        </tr>
                      </tbody>
                    </table>

                    <div class="flex_btn alignjust_center mt_10">
                      <nav aria-label="Page navigation ">
                        <ul class="pagination">
                          <li class="page-item">
                            <a
                              class="page-link"
                              v-if="curPage - 5 >= 1"
                              @click="pageMove(curPage - 5)"
                              aria-label="Previous"
                            >
                              <i
                                class="fa fa-angle-left"
                                aria-hidden="true"
                              ></i>

                              <span class="sr-only">PREV</span>
                            </a>
                          </li>

                          <li
                            class="page-item"
                            v-for="(pageNo, i) in endPage"
                            :key="'p' + i"
                          >
                            <a
                              class="page-link"
                              v-if="pageNo >= startPage"
                              @click="pageMove(pageNo)"
                              :class="{ page_active: pageNo === curPage }"
                            >
                              {{ pageNo }}
                            </a>
                          </li>

                          <li
                            class="page-item"
                            v-if="pageCnt - startPage >= 5"
                            @click="pageMove(startPage + 5)"
                          >
                            <a class="page-link" aria-label="Next">
                              <i
                                class="fa fa-angle-right"
                                aria-hidden="true"
                              ></i>

                              <span class="sr-only">NEXT</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- //.content-->
          </div>
          <!-- //.span9-->
        </div>
      </div>
      <!-- //.container-->
    </div>

    <!-- 아래 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
import axios from "axios";
import Swal from "sweetalert2";
import Vue from "vue";
import { logout } from "@/class/logout.js";

export default {
  name: "OrderList",
  components: {
    Header,
    Footer,
    LeftMenu,
  },
  data() {
    return {
      orderId: this.$store.getters["searchOrderStore/getOrderId"],
      orderName: this.$store.getters["searchOrderStore/getOrderName"],
      orderState: this.$store.getters["searchOrderStore/getOrderState"],

      orderStateList: "",

      // 페이지
      rangeSize: 0,
      curPage: this.$store.getters["searchOrderStore/getCpn"],
      startPage: 0,
      endPage: 0,
      pageCnt: 0,

      // 게시판 글
      payload: [],

      url: "",
      gubunList: [],
    };
  },
  methods: {
    goDetailPage(orderId) {
      this.$router.push({
        name: "OrderDetail",
        params: {
          orderId: orderId,
        },
      });
    },

    search(cpn) {
      // cpn = 현재 페이지
      var data = this;
      var host = Vue.prototype.$hostname;
      this.$store.commit("searchOrderStore/setOrderId", this.orderId);
      this.$store.commit("searchOrderStore/setOrderName", this.orderName);
      this.$store.commit("searchOrderStore/setOrderState", this.orderState);
      axios
        .get(`${host}/admin/order-list`, {
          params: {
            orderId: this.orderId,
            orderName: this.orderName,
            orderState: this.orderState,
            curPage: cpn,
          },
        })
        .then(function (response) {
          data.rangeSize = response.data.paging.rangeSize;
          data.payload = response.data.payload;
          data.startPage = response.data.paging.startPage;
          data.endPage = response.data.paging.endPage;
          data.pageCnt = response.data.paging.pageCnt;
          data.curPage = response.data.paging.curPage;
          this.$store.commit(
            "searchOrderStore/setCpn",
            response.data.paging.curPage
          );
          data.url = `${host}/admin/order-excel-download?productList=${encodeURI(
            JSON.stringify(data.payload)
          )}`;
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },

    pageMove(cpn) {
      var data = this;
      var host = Vue.prototype.$hostname;

      this.$store.commit("searchOrderStore/setCpn", cpn);
      axios
        .get(`${host}/admin/order-list`, {
          params: {
            orderId: this.$store.getters["searchOrderStore/getOrderId"],
            orderName: this.$store.getters["searchOrderStore/getProductName"],
            orderState: this.$store.getters["searchOrderStore/getOrderState"],
            curPage: this.$store.getters["searchOrderStore/getCpn"],
          },
        })
        .then(function (response) {
          data.rangeSize = response.data.paging.rangeSize;
          data.payload = response.data.payload;
          data.startPage = response.data.paging.startPage;
          data.endPage = response.data.paging.endPage;
          data.pageCnt = response.data.paging.pageCnt;
          data.curPage = response.data.paging.curPage;
          this.$store.commit(
            "searchOrderStore/setCpn",
            response.data.paging.curPage
          );
          data.url = `${host}/admin/order-excel-download?productList=${encodeURI(
            JSON.stringify(data.payload)
          )}`;
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
  },

  created() {
    var data = this;
    var host = Vue.prototype.$hostname;
    axios
      .get(`${host}/admin/order-list`, {
        params: {
          orderId: this.$store.getters["searchOrderStore/getOrderId"],
          orderName: this.$store.getters["searchOrderStore/getProductName"],
          orderState: this.$store.getters["searchOrderStore/getOrderState"],
          curPage: this.curPage,
        },
      })
      .then(function (response) {
        data.rangeSize = response.data.paging.rangeSize;
        data.payload = response.data.payload;
        data.startPage = response.data.paging.startPage;
        data.endPage = response.data.paging.endPage;
        data.pageCnt = response.data.paging.pageCnt;
        data.$store.commit(
          "searchOrderStore/setCpn",
          response.data.paging.curPage
        );
        data.url = `${host}/admin/order-excel-download?productList=${encodeURI(
          JSON.stringify(data.payload)
        )}`;
        data.gubunList = response.data.gubun;
      })
      .catch(function (error) {
        if (error.response.status) {
          // 권한이 없을 경우
          if (
            error.response.status === 403 &&
            error.response.data.message === "Forbidden"
          ) {
            Swal.fire({
              title: data.$store.getters["customer/getNoAuthority"],
              icon: "error",
              confirmButtonText: "확인",
            });
            data.$router.push("/");
            return false;

            // 로그인을 안했을 경우
          } else if (
            error.response.status === 403 &&
            data.$store.getters["token/getToken"].length === 0
          ) {
            Swal.fire({
              title: data.$store.getters["customer/getNoLoginMessage"],
              icon: "error",
              confirmButtonText: "확인",
            });
            data.$router.push("/login");
            return false;

            // 새로고침을 눌렀을 경우
          } else if (
            error.response.status === 403 &&
            data.$store.getters["token/getToken"].length !== 0
          ) {
            data.$router.push("/login");
            return false;
          } else if (
            error.response.data.message.includes("The Token has expired") ===
            true
          ) {
            logout();
            data.$router.push("/login");
            return false;
          }
        }
        Swal.fire({
          title: error.response.data.message,
          icon: "error",
          confirmButtonText: "확인",
        });
      });
  },
};
</script>
