export default {
  namespaced: true,
  state: {
    cpn: 1,
    boardTitle: "",
    type: "",
  },
  getters: {
    getCpn(state) {
      return state.cpn;
    },
    getBoardTitle(state) {
      return state.boardTitle;
    },
    getType(state) {
      return state.type;
    },
  },
  mutations: {
    setCpn(state, payload) {
      state.cpn = payload;
    },
    setBoardTitle(state, payload) {
      state.boardTitle = payload;
    },
    setType(state, payload) {
      state.type = payload;
    },
  },
};
