<template>
  <!-- 회원리스트 -->
  <div>
    <!-- 해더 -->
    <Header />

    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />
          <!-- 내용 -->
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>약관 등록</h3>
                </div>
                <div class="module-body">
                  <div class="profile-head pb_20">
                    <div class="table">
                      <table class="table table-bordered">
                        <colgroup>
                          <col width="20%" />
                          <col width="80%" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>분류</th>
                            <td id="type">
                              <div>
                                <select @change="gubonChange($event)">
                                  <option
                                    v-for="(data, i) in gubunList"
                                    :key="data + i"
                                    :value="data.cdId"
                                  >
                                    {{ data.cdName }}
                                  </option>
                                </select>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th>제목</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="title"
                                  placeholder="제목"
                                  class="span3"
                                  v-model="title"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>내용</th>
                            <td>
                              <textarea
                                cols="110"
                                rows="10"
                                id="content"
                                placeholder="내용"
                                v-model="content"
                                style="resize: none"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="flex_btn alignjust_right">
                        <a
                          class="btn btn-large btn-warning"
                          @click="createPolicy"
                          >등록</a
                        >
                        <a @click="goBack" class="btn btn-large btn-primary"
                          >목록</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- //.content-->
          </div>
          <!-- //.span9-->

          <!-- 내용 -->
        </div>
      </div>
      <!-- //.container-->
    </div>

    <!-- 아래 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
import axios from "axios";
import Swal from "sweetalert2";
import Vue from "vue";

export default {
  name: "PolicyDetail",
  components: {
    Header,
    Footer,
    LeftMenu,
  },

  data() {
    return {
      title: "",
      gubun: "",
      content: "",

      gubunList: [],
    };
  },

  methods: {
    goBack() {
      this.$router.push("/policy/list");
    },

    gubonChange(event) {
      this.gubun = event.target.value;
    },

    createPolicy() {
      var host = Vue.prototype.$hostname;
      var data = this;
      var params = {
        title: this.title,
        gubun: this.gubun,
        content: this.content,
      };

      axios
        .post(`${host}/admin/policy`, params, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then(function (response) {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              data.$router.replace("/policy/list");
            }
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
  },

  created() {
    this.gubunList = this.$route.params.gubunList;
    this.gubun = this.$route.params.gubunList[0].cdId;
  },
};
</script>
