<template>
  <!-- 메인 페이지 -->
  <div>
    <!-- 해더 -->
    <Header />

    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />

          <!-- 내용 -->
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>1:1문의 상세</h3>
                </div>
                <div class="module-body">
                  <div class="profile-head pb_20">
                    <div class="table">
                      <table class="table table-bordered">
                        <colgroup>
                          <col width="20%" />
                          <col width="80%" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>제목</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="basicinput"
                                  class="span3"
                                  v-model="qTitle"
                                  :disabled="true"
                                />
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th>등록일</th>
                            <td>
                              <span>{{
                                $moment(regDate).format("YYYY-MM-DD")
                              }}</span>
                            </td>
                          </tr>
                          <tr>
                            <th>구분</th>
                            <td>
                              <span>{{ cdName }}</span>
                            </td>
                          </tr>
                          <tr>
                            <th>상태</th>
                            <td>
                              <span>
                                {{ statusYn === "Y" ? "답변완료" : "답변대기" }}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <th>내용</th>
                            <td>
                              <textarea
                                cols="110"
                                rows="10"
                                id="n_content"
                                :disabled="true"
                                v-model="qContent"
                                style="resize: none"
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>답변</th>
                            <td>
                              <textarea
                                cols="110"
                                rows="10"
                                id="n_answer"
                                v-model="qAnswer"
                                :disabled="modRemoveToggle == false"
                                style="resize: none"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="flex_btn alignjust_right">
                        <a
                          v-show="!modRemoveToggle"
                          class="btn btn-large btn-info"
                          @click="ClickmodRemoveToggle"
                          >답변하기</a
                        >

                        <a
                          v-show="modRemoveToggle"
                          class="btn btn-large btn-info"
                          @click="updateQna"
                          >답변저장</a
                        >
                        <a class="btn btn-large btn-danger" @click="deleteQna"
                          >삭제</a
                        >

                        <a class="btn btn-large btn-primary" @click="goBack"
                          >목록</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- //.content-->
          </div>
          <!-- //.span9-->
        </div>
      </div>
      <!-- //.container-->
    </div>

    <!-- 아래 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
import axios from "axios";
import Vue from "vue";
import Swal from "sweetalert2";

export default {
  name: "BoarderDetail",
  components: {
    Header,
    Footer,
    LeftMenu,
  },
  data() {
    return {
      modRemoveToggle: false,
      qSeq: "",
      qTitle: "",
      qContent: "",
      fileNm: "",
      filePath: "",
      qAnswer: "",
      qOpnYn: "",
      regId: "",
      originalFileId: "", // 원본 파일명
      gubun: "", // 구분
      statusYn: "", // 답변 여부
      cdName: "",
      regDate: "",

      // url: "",
    };
  },
  methods: {
    goBack() {
      var data = this;
      data.$router.replace("/boarder/BoarderList");
      [2];
    },

    ClickmodRemoveToggle() {
      this.modRemoveToggle = !this.modRemoveToggle;
    },

    updateQna() {
      if (this.qAnswer.length < 5) {
        Swal.fire({
          title: "답변은 5글자 이상 입력해주세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      } else if (this.statusYn === "N" && this.qAnswer.length >= 5) {
        // 답변을 안한 상태이고 답변을 했을 경우 답변 완료로 업데이트
        this.statusYn = "Y";
      }
      var data = this;
      var host = Vue.prototype.$hostname;
      var params = {
        qSeq: this.qSeq,
        qAnswer: this.qAnswer,
        statusYn: this.statusYn,
        modId: this.$store.getters["login/getAdminId"],
      };
      axios
        .put(`${host}/admin/qna`, params, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then(function (response) {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              data.$router.replace("/boarder/BoarderList");
            }
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },

    deleteQna() {
      var data = this;
      var host = Vue.prototype.$hostname;
      axios
        .delete(`${host}/admin/qna/${encodeURI(this.qSeq)}`)
        .then(function (response) {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              data.$router.replace("/boarder/BoarderList");
            }
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              data.$router.replace("/boarder/BoarderList");
            }
          });
        });
    },
  },
  created() {
    // var host = Vue.prototype.$hostname;
    this.qSeq = this.$route.params.pData.qSeq;
    this.qTitle = this.$route.params.pData.qtitle;
    this.qContent = this.$route.params.pData.qcontent;
    this.fileNm = this.$route.params.pData.fileNm;
    this.filePath = this.$route.params.pData.filePath;
    this.qAnswer = this.$route.params.pData.qAnswer;
    this.qOpnYn = this.$route.params.pData.qpnYn;
    this.originalFileId = this.$route.params.pData.originalFileId;
    this.gubun = this.$route.params.pData.gubun;
    this.cdName = this.$route.params.pData.cdName;
    this.regDate = this.$route.params.pData.regDate;
    this.statusYn = this.$route.params.pData.statusYn;
    this.existFile =
      this.$route.params.pData.originalFileId !== null ? true : false;
    this.gubun = this.$route.params.pData.gubun;
    // this.url = `${host}/file/download/qna/${this.$route.params.pData.qseq}`;
  },
};
</script>
