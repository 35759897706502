<template>
  <!-- 회원리스트 -->
  <div>
    <!-- 해더 -->
    <Header />

    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />

          <!-- 내용 -->
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>약관 관리</h3>
                </div>
                <div class="module-body">
                  <div class="profile-head pb_20">
                    <div class="table">
                      <table class="table table-bordered">
                        <colgroup>
                          <col width="15%" />
                          <col width="30%" />
                          <col width="15%" />
                          <col width="40%" />
                          <col width="15%" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>분류</th>
                            <td>
                              <select @change="gubonChange($event)">
                                <option value="">선택하세요.</option>
                                <option
                                  v-for="(data, i) in gubunList"
                                  :key="data + i"
                                  :value="data.cdName"
                                  :selected="gubun === data.cdId"
                                >
                                  {{ data.cdName }}
                                </option>
                              </select>
                            </td>
                            <th>등록일</th>
                            <td>
                              <input
                                class="w43"
                                type="date"
                                id="startRegDate"
                                v-model="startRegDate"
                              />
                              ~
                              <input
                                class="w43"
                                type="date"
                                id="endRegDate"
                                v-model="endRegDate"
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>제목</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="title"
                                  placeholder=""
                                  v-model="title"
                                  class="span3"
                                  @keyup.enter="search(1)"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="flex_btn alignjust_right">
                        <a class="btn btn-large btn-warning" @click="search(1)"
                          >조회</a
                        >
                        <a
                          @click="goCreatePolicy"
                          class="btn btn-large btn-warning"
                          >등록</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="module-body">
                  <div class="table">
                    <table
                      class="table table-striped table-bordered table-condensed"
                    >
                      <colgroup>
                        <col width="*" />
                        <col width="*" />
                        <col width="*" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th style="text-align: center">No</th>
                          <th style="text-align: center">제목</th>
                          <th style="text-align: center">분류</th>
                          <th style="text-align: center">날짜</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(pData, i) in payload"
                          :key="pData + i"
                          @click="goDetailPage(pData)"
                        >
                          <td>{{ pData.rownum }}</td>
                          <td>{{ pData.title }}</td>
                          <td>{{ pData.cdName }}</td>
                          <td>
                            {{ $moment(pData.regDate).format("YYYY-MM-DD") }}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div class="flex_btn alignjust_center mt_10">
                      <nav aria-label="Page navigation ">
                        <ul class="pagination">
                          <li class="page-item">
                            <a
                              class="page-link"
                              v-if="curPage - 5 >= 1"
                              @click="pageMove(curPage - 5)"
                              aria-label="Previous"
                            >
                              <i
                                class="fa fa-angle-left"
                                aria-hidden="true"
                              ></i>

                              <span class="sr-only">PREV</span>
                            </a>
                          </li>

                          <li
                            class="page-item"
                            v-for="(pageNo, i) in endPage"
                            :key="'p' + i"
                          >
                            <a
                              class="page-link"
                              v-if="pageNo >= startPage"
                              @click="pageMove(pageNo)"
                              :class="{ page_active: pageNo === curPage }"
                            >
                              {{ pageNo }}
                            </a>
                          </li>

                          <li
                            class="page-item"
                            v-if="pageCnt - startPage >= 5"
                            @click="pageMove(startPage + 5)"
                          >
                            <a class="page-link" aria-label="Next">
                              <i
                                class="fa fa-angle-right"
                                aria-hidden="true"
                              ></i>

                              <span class="sr-only">NEXT</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- //.content-->
          </div>
          <!-- //.span9-->
        </div>
      </div>
      <!-- //.container-->
    </div>

    <!-- 아래 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
import axios from "axios";
import Swal from "sweetalert2";
import Vue from "vue";
import { logout } from "@/class/logout.js";

export default {
  name: "PolicyList",
  components: {
    Header,
    Footer,
    LeftMenu,
  },
  data() {
    return {
      gubun: this.$store.getters["searchPolicyStore/getGubun"],
      title: this.$store.getters["searchPolicyStore/getTitle"],
      startRegDate: this.$store.getters["searchPolicyStore/getStartRegDate"],
      endRegDate: this.$store.getters["searchPolicyStore/getEndRegDate"],

      preGubun: "",
      preTitle: "",
      preStartRegDate: "",
      preEndRegDate: "",

      gubunList: [],

      // 페이지
      rangeSize: 0,
      curPage: this.$store.getters["searchPolicyStore/getCpn"],
      startPage: 0,
      endPage: 0,
      pageCnt: 0,

      // 게시판 글
      payload: [],
    };
  },
  methods: {
    goDetailPage(pData) {
      this.$router.push({
        name: "PolicyDetail",
        params: {
          policy: pData,
          gubunList: this.gubunList,
        },
      });
    },
    goCreatePolicy() {
      this.$router.push({
        name: "PolicyReg",
        params: {
          gubunList: this.gubunList,
        },
      });
    },

    gubonChange(event) {
      this.gubun = event.target.value;
    },

    search(cpn) {
      // cpn = 현재 페이지
      var data = this;
      var host = Vue.prototype.$hostname;

      if (this.startRegDate.length !== 0 && this.endRegDate.length === 0) {
        Swal.fire({
          title: "등록일",
          text: "등록일 끝나는 날짜를 입력하세요",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      } else if (
        this.endRegDate.length !== 0 &&
        this.startRegDate.length === 0
      ) {
        Swal.fire({
          title: "등록일",
          text: "등록일 시작하는 날짜를 입력하세요",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      }

      this.$store.commit("searchPolicyStore/setGubun", this.gubun);
      this.$store.commit("searchPolicyStore/setTitle", this.title);
      this.$store.commit(
        "searchPolicyStore/setStartRegDate",
        this.startRegDate
      );
      this.$store.commit("searchPolicyStore/setEndRegDate", this.endRegDate);
      this.$store.commit("searchPolicyStore/setCpn", cpn);
      axios
        .get(`${host}/admin/policy-list`, {
          params: {
            type: this.gubun,
            boardTitle: this.title,
            startRegDate:
              this.startRegDate.length !== 0
                ? new Date(this.startRegDate).toISOString()
                : null,

            endRegDate:
              this.endRegDate.length !== 0
                ? new Date(this.endRegDate).toISOString()
                : null,
            curPage: cpn,
          },
        })
        .then(function (response) {
          data.rangeSize = response.data.paging.rangeSize;
          data.payload = response.data.payload;
          data.startPage = response.data.paging.startPage;
          data.endPage = response.data.paging.endPage;
          data.pageCnt = response.data.paging.pageCnt;
          data.curPage = response.data.paging.curPage;
          data.$store.commit(
            "searchPolicyStore/setCpn",
            response.data.paging.curPage
          );
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },

    pageMove(cpn) {
      var data = this;
      var host = Vue.prototype.$hostname;

      this.$store.commit("searchPolicyStore/setCpn", cpn);
      axios
        .get(`${host}/admin/policy-list`, {
          params: {
            gubun: this.$store.getters["searchPolicyStore/gubun"],
            title: this.$store.getters["searchPolicyStore/title"],
            startRegDate: this.$store.getters["searchPolicyStore/startRegDate"],
            endRegDate: this.$store.getters["searchPolicyStore/endRegDate"],
            curPage: this.$store.getters["searchPolicyStore/getCpn"],
          },
        })
        .then(function (response) {
          data.rangeSize = response.data.paging.rangeSize;
          data.payload = response.data.payload;
          data.startPage = response.data.paging.startPage;
          data.endPage = response.data.paging.endPage;
          data.pageCnt = response.data.paging.pageCnt;
          data.curPage = response.data.paging.curPage;
          data.$store.commit(
            "searchPolicyStore/setCpn",
            response.data.paging.curPage
          );
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
  },

  created() {
    var data = this;
    var host = Vue.prototype.$hostname;
    axios
      .get(`${host}/admin/policy-list`, {
        params: {
          gubun: this.$store.getters["searchPolicyStore/gubun"],
          title: this.$store.getters["searchPolicyStore/title"],
          startRegDate: this.$store.getters["searchPolicyStore/startRegDate"],
          endRegDate: this.$store.getters["searchPolicyStore/endRegDate"],
          curPage: this.$store.getters["searchPolicyStore/getCpn"],
        },
      })
      .then(function (response) {
        data.rangeSize = response.data.paging.rangeSize;
        data.payload = response.data.payload;
        data.startPage = response.data.paging.startPage;
        data.endPage = response.data.paging.endPage;
        data.pageCnt = response.data.paging.pageCnt;
        data.gubunList = response.data.gubunList;
        data.$store.commit(
          "searchPolicyStore/setCpn",
          response.data.paging.curPage
        );
      })
      .catch(function (error) {
        if (error.response.status) {
          // 권한이 없을 경우
          if (
            error.response.status === 403 &&
            error.response.data.message === "Forbidden"
          ) {
            Swal.fire({
              title: data.$store.getters["customer/getNoAuthority"],
              icon: "error",
              confirmButtonText: "확인",
            });
            data.$router.push("/");
            return false;

            // 로그인을 안했을 경우
          } else if (
            error.response.status === 403 &&
            data.$store.getters["token/getToken"].length === 0
          ) {
            Swal.fire({
              title: data.$store.getters["customer/getNoLoginMessage"],
              icon: "error",
              confirmButtonText: "확인",
            });
            data.$router.push("/login");
            return false;

            // 새로고침을 눌렀을 경우
          } else if (
            error.response.status === 403 &&
            data.$store.getters["token/getToken"].length !== 0
          ) {
            data.$router.push("/login");
            return false;
          } else if (
            error.response.data.message.includes("The Token has expired") ===
            true
          ) {
            logout();
            data.$router.push("/login");
            return false;
          }
        }
        Swal.fire({
          title: error.response.data.message,
          icon: "error",
          confirmButtonText: "확인",
        });
      });
  },
};
</script>
