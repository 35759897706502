<template>
  <!-- 회원리스트 -->

  <div>
    <!-- 해더 -->

    <Header />

    <!-- /navbar -->

    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->

          <LeftMenu />

          <!-- 내용 -->

          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>회원 상세화면</h3>
                </div>

                <div class="module-body">
                  <h4>
                    <span class="icon-chevron-right"></span>
                    기본정보
                  </h4>

                  <div class="table">
                    <table class="table table-bordered">
                      <colgroup>
                        <col width="20%" />

                        <col width="30%" />

                        <col width="20%" />

                        <col width="30%" />
                      </colgroup>

                      <tbody>
                        <tr>
                          <th>프로필 이미지</th>

                          <img
                            v-if="removeProfile === 'N'"
                            width="100"
                            height="100"
                            v-bind:src="'data:image/jpeg;base64,' + imageBytes"
                            alt="프로필 이미지"
                          />

                          <input
                            v-if="removeProfile === 'Y'"
                            id="file"
                            name="file"
                            type="file"
                            accept="image/*"
                            @change="checkFile"
                          />
                          <td colspan="3">
                            <a
                              v-if="
                                removeProfile === 'N' &&
                                modRemoveToggle === true
                              "
                              class="btn btn-large btn-danger"
                              id="btn_del"
                              @click="
                                removeProfile === 'N'
                                  ? (removeProfile = 'Y')
                                  : (removeProfile = 'N')
                              "
                            >
                              이미지 삭제 또는 수정
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <th>양육자</th>

                          <td>
                            <input
                              type="text "
                              v-model="userName"
                              :disabled="modRemoveToggle == false"
                            />
                          </td>

                          <th>이메일</th>

                          <td>
                            <input
                              type="text "
                              v-model="userEmail"
                              :disabled="modRemoveToggle == false"
                            />
                          </td>
                        </tr>

                        <tr>
                          <th>전화번호</th>

                          <td>
                            <input
                              type="text "
                              v-model="userPhone"
                              :disabled="modRemoveToggle == false"
                            />
                          </td>

                          <th>최근 활동일</th>

                          <td>
                            <label class="w60" type="date" id="loginLastDt">
                              {{ $moment(loginLastDt).format("YYYY-MM-DD") }}
                            </label>
                          </td>
                        </tr>

                        <tr>
                          <th>마케팅 정보 동의</th>

                          <td>
                            <div class="controls">
                              <label class="radio inline">
                                <input
                                  type="radio"
                                  name="markingYn"
                                  id="markingYn"
                                  value="Y"
                                  v-model="markingYn"
                                  :disabled="modRemoveToggle == false"
                                />
                                예
                              </label>

                              <label class="radio inline">
                                <input
                                  type="radio"
                                  name="markingYn"
                                  id="markingYn"
                                  value="N"
                                  v-model="markingYn"
                                  :disabled="modRemoveToggle == false"
                                />
                                아니오
                              </label>
                            </div>
                          </td>

                          <th>회원 가입일</th>

                          <td>
                            <label class="w60" type="date" id="regDate">
                              {{ $moment(regDate).format("YYYY-MM-DD") }}
                            </label>
                          </td>
                        </tr>

                        <tr>
                          <th>가입 방법</th>

                          <td>
                            <label class="radio inline">
                              <input
                                type="radio"
                                name="joinType"
                                id="joinType"
                                value="app"
                                v-model="joinType"
                                :disabled="modRemoveToggle == false"
                              />
                              직접가입
                            </label>

                            <label class="radio inline">
                              <input
                                type="radio"
                                name="joinType"
                                id="joinType"
                                value="kakao"
                                v-model="joinType"
                                :disabled="modRemoveToggle == false"
                              />
                              카카오(간편가입)
                            </label>
                          </td>

                          <th>프리미엄 서비스</th>

                          <td>
                            <label class="radio inline">
                              <input
                                type="radio"
                                name="premiumYn"
                                id="premiumYn"
                                value="Y"
                                v-model="premiumYn"
                                :disabled="modRemoveToggle == false"
                              />
                              이용중
                            </label>

                            <label class="radio inline">
                              <input
                                type="radio"
                                name="premiumYn"
                                id="premiumYn"
                                value="N"
                                v-model="premiumYn"
                                :disabled="modRemoveToggle == false"
                              />
                              이용안함
                            </label>
                          </td>
                        </tr>

                        <tr>
                          <th>사용용량</th>
                          <td>
                            <select
                              name=""
                              id=""
                              v-model="useVolume"
                              :disabled="modRemoveToggle == false"
                            >
                              <option value="">없음</option>
                              <option
                                v-for="(volume, i) in volumeList"
                                :key="'v' + i"
                                :value="volume.cdId"
                              >
                                {{ volume.cdName }}
                              </option>
                            </select>
                          </td>

                          <th>&nbsp;</th>

                          <td>&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="module-body">
                  <h4>
                    <span class="icon-chevron-right"></span>
                    상태변경
                  </h4>

                  <div class="table">
                    <table class="table table-bordered">
                      <colgroup>
                        <col width="20%" />

                        <col width="30%" />

                        <col width="20%" />

                        <col width="30%" />
                      </colgroup>

                      <tbody>
                        <tr>
                          <th>상태변경</th>

                          <td colspan="3">
                            <div class="controls">
                              <label class="radio inline">
                                <input
                                  type="radio"
                                  name="statueYn"
                                  id="statueYn"
                                  value="Y"
                                  v-model="statueYn"
                                  :disabled="modRemoveToggle == false"
                                />
                                이용중
                              </label>

                              <label class="radio inline">
                                <input
                                  type="radio"
                                  name="statueYn"
                                  id="statueYn"
                                  value="N"
                                  v-model="statueYn"
                                  :disabled="modRemoveToggle == false"
                                />
                                이용중지
                              </label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="module-body">
                  <h4>
                    <span class="icon-chevron-right"></span>
                    프리미엄 서비스 결제 현황
                  </h4>

                  <div class="table">
                    <table class="table table-bordered">
                      <colgroup>
                        <col width="10%" />

                        <col width="30%" />

                        <col width="20%" />

                        <col width="auto" />
                      </colgroup>

                      <thead>
                        <tr>
                          <th>번호</th>

                          <th>결제일</th>

                          <th>잔여기간</th>

                          <th>카드번호</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <th>{{ Seq }}</th>

                          <td>
                            <input
                              type="text "
                              :value="Service_Day"
                              :disabled="true"
                            />
                          </td>

                          <td>
                            <input
                              type="text "
                              :value="remaining_time"
                              :disabled="true"
                            />
                          </td>

                          <td>
                            <input
                              type="text "
                              :value="card_num"
                              :disabled="true"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="flex_btn alignjust_right">
                    <a
                      v-show="!modRemoveToggle"
                      class="btn btn-large btn-info"
                      @click="ClickmodRemoveToggle"
                    >
                      수정
                    </a>

                    <a
                      v-show="modRemoveToggle"
                      class="btn btn-large btn-info"
                      @click="modifyUser"
                      id="btn_save"
                    >
                      저장
                    </a>

                    <a
                      class="btn btn-large btn-danger"
                      id="btn_del"
                      @click="deleteUser"
                    >
                      삭제
                    </a>

                    <a
                      class="btn btn-large btn-primary"
                      @click="goBack"
                      id="btn_list"
                    >
                      목록
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <!-- //.content-->
          </div>

          <!-- //.span9-->
        </div>
      </div>

      <!-- //.container-->
    </div>

    <!-- 아래 -->

    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";
import axios from "axios";
import Swal from "sweetalert2";
import Vue from "vue";

export default {
  name: "CustomerDetail",
  components: {
    Header,
    Footer,
    LeftMenu,
  },

  data() {
    return {
      modRemoveToggle: false,

      userName: "", //양육자
      preUserEmail: "", // 수정하기 전 이메일
      userEmail: "", //이메일
      userPhone: "", //전화번호
      loginLastDt: "", //최근 활동일
      regDate: "", //회원 가입일
      markingYn: "", //마케팅 정보 동의 Y: 예, N:아니오
      joinType: "", //직접가입(app) / 간편가입(카카오) kakao
      premiumYn: "", //프리미엄 서비스 "Y:이용중","N:이용안함"
      adminUsage: "", //사용 용량
      statueYn: "", //상태 변경 "Y/N
      useVolume: "", //사용 용량
      removeProfile: "N", // 이미지 삭제, 변경 여부
      profileId: "", // 파일 ID
      profilePath: "", // 파일 path

      //===========//프리미엄 서비스 결제 현황=================================
      //Service_Statue: "", //프리미엄 서비스 결제 현황
      Seq: "",
      Service_Day: "", //결제일
      remaining_time: "", //잔여 기간
      card_num: "", //카드 번호

      imageBytes: "",

      volumeList: [],
    };
  },
  methods: {
    goBack() {
      var data = this;
      data.$router.replace("/customer/list");
      [2];
    },

    ClickmodRemoveToggle() {
      this.modRemoveToggle = !this.modRemoveToggle;
    },

    // 파일 확장자, 사이즈 체크
    checkFile() {
      // files 로 해당 파일 정보 얻기.
      var file = document.getElementById("file").files;

      // 파일 사이즈
      let maxSize = 15 * 1024 * 1024; // 15MB
      let fileLength = file.length;
      let addFileCapacity = 0;

      // file[0].name 은 파일명 입니다.
      // 정규식으로 확장자 체크
      if (!/\.(gif|jpg|jpeg|png|tiff|tif)$/i.test(file[0].name)) {
        Swal.fire({
          title:
            "gif, jpg, jpeg, png, tiff, tif 파일만 선택해 주세요.\n\n현재 파일 : " +
            file[0].name,
          icon: "warning",
          confirmButtonText: "확인",
        });
        document.getElementById("file").value = null;
      }

      for (let i = 0; i < fileLength; i++) {
        addFileCapacity = addFileCapacity + file[i].size;
      }
      if (addFileCapacity > maxSize) {
        Swal.fire({
          title: "파일 용량 제한은 15MB입니다.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        document.getElementById("file").value = null;
        return false;
      }

      // 체크를 통과했다면 종료.
      return;
    },

    // 수정
    modifyUser() {
      var data = this;
      var host = Vue.prototype.$hostname;
      if (this.userName.length < 1) {
        Swal.fire({
          title: "이름을 입력하세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      } else if (
        // 이메일 유효성 검사
        !/^[A-Za-z0-9_\\.\\-]+@[A-Za-z0-9\\-]+\.[A-Za-z0-9\\-]+/.test(
          this.userEmail
        )
      ) {
        Swal.fire({
          title: "이메일 형식을 확인하세요.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      }

      // 전화번호 유효성 검사
      if (!/^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/.test(this.userPhone)) {
        Swal.fire({
          title: "유효하지 않은 전화번호 입니다.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        return false;
      }
      // else if (this.user_pwd.length < 8) {
      //   // 비밀번호 유효성 검사
      //   Swal.fire({
      //     title: "비밀번호를 8자리 이상 입력하세요.",
      //     icon: "warning",
      //     confirmButtonText: "확인",
      //   });
      //   return false;
      // }

      const formData = new FormData();
      const file = document.getElementById("file");
      if (file !== null) {
        formData.append("file", file.files[0]);
      }
      formData.append("userName", this.userName);
      formData.append("preUserEmail", this.preUserEmail);
      formData.append("userEmail", this.userEmail);
      formData.append("userPhone", this.userPhone);
      // formData.append("loginLastDt", this.loginLastDt);
      // formData.append("regDate", this.regDate);
      formData.append("joinType", this.joinType);
      formData.append("premiumYn", this.premiumYn);
      formData.append("statusYn", this.statueYn);
      formData.append("markingYn", this.markingYn);
      formData.append("useVolume", this.useVolume);
      formData.append("modId", this.$store.getters["login/getAdminId"]);
      formData.append("removeProfile", this.removeProfile);
      formData.append("profileId", this.profileId);
      formData.append("profilePath", this.profilePath);

      axios
        .put(`${host}/admin/user`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          data.ClickmodRemoveToggle;
          Swal.fire({
            title: response.data.message,
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              data.$router.replace("/customer/list");
            }
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },

    // 삭제
    deleteUser() {
      var data = this;
      var host = Vue.prototype.$hostname;
      axios
        .delete(`${host}/admin/user/` + this.userEmail)
        .then(function (response) {
          data.ClickmodRemoveToggle;
          Swal.fire({
            title: response.data.message,
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              data.$router.replace("/customer/list");
            }
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
  },

  created() {
    if (this.$route.params.userEmail === undefined) {
      return false;
    }

    var data = this;
    var host = Vue.prototype.$hostname;
    axios
      .get(`${host}/admin/user`, {
        params: {
          userEmail: this.$route.params.userEmail,
        },
      })
      .then(function (response) {
        console.log(response);
        data.userName = response.data.user.userName;
        data.preUserEmail = response.data.user.userEmail;
        data.userEmail = response.data.user.userEmail;
        data.markingYn = response.data.user.markingYn;
        data.joinType = response.data.user.joinType;
        data.premiumYn = response.data.user.premiumYn;
        data.useVolume =
          response.data.user.useVolume === null
            ? ""
            : response.data.user.useVolume;
        data.statueYn = response.data.user.statusYn;
        data.userPhone = response.data.user.userPhone;
        data.profileId = response.data.user.profileId;
        data.profilePath = response.data.user.profilePath;
        // 이미지
        data.imageBytes = response.data.encodedImage;

        data.regDate = response.data.user.regDate;

        data.loginLastDt = response.data.user.loginLastDt;

        data.volumeList = response.data.volume;
      })
      .catch(function (error) {
        if (error.response.status) {
          // 권한이 없을 경우
          if (
            error.response.status === 403 &&
            error.response.data.message === "Forbidden"
          ) {
            Swal.fire({
              title: data.$store.getters["customer/getNoAuthority"],
              icon: "error",
              confirmButtonText: "확인",
            });
            data.$router.push("/");
            return false;

            // 로그인을 안했을 경우
          } else if (
            error.response.status === 403 &&
            data.$store.getters["token/getToken"].length === 0
          ) {
            Swal.fire({
              title: data.$store.getters["customer/getNoLoginMessage"],
              icon: "error",
              confirmButtonText: "확인",
            });
            data.$router.push("/login");
            return false;

            // 새로고침을 눌렀을 경우
          } else if (
            error.response.status === 403 &&
            data.$store.getters["token/getToken"].length !== 0
          ) {
            data.$router.push("/login");
            return false;
          }
        }

        // 새로고침 했을 때 에러
        if (error.response.status === 400) {
          return false;
        }

        Swal.fire({
          title: error.response.data.message,
          icon: "error",
          confirmButtonText: "확인",
        });
      });
  },
};
</script>
