<template>
  <div>
    <!-- VIEWS -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
/*Fonts and icons */
@import "./assets/css/font-awesome.css";
/* CSS Files -*/
@import "./assets/css/bootstrap-responsive.min.css";
@import "./assets/css/bootstrap.min.css";
/* CSS Add */
@import "./assets/css/plugin.css";
@import "./assets/css/style_admin.css";
@import "./assets/css/theme.css";
</style>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
