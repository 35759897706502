<template>
  <!-- 회원리스트 -->
  <div>
    <!-- 해더 -->
    <Header />

    <!-- /navbar -->
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <!-- 왼쪽 메뉴 -->
          <LeftMenu />

          <!-- 내용 -->
          <div class="span9">
            <div class="content">
              <div class="module">
                <div class="module-head">
                  <h3>다이어리 등록</h3>
                </div>
                <div class="module-body">
                  <div class="profile-head pb_20">
                    <div class="table">
                      <table class="table table-bordered">
                        <colgroup>
                          <col width="15%" />
                          <col width="30%" />
                          <col width="15%" />
                          <col width="40%" />
                        </colgroup>
                        <tbody>
                          <h5>등록자 정보</h5>
                          <tr>
                            <th>양육자 이름</th>
                            <td>
                              <div class="controls">
                                <input
                                  type="text"
                                  id="parents_Name"
                                  placeholder=""
                                  class="span3"
                                />
                              </div>
                            </td>
                            <th>이메일</th>
                            <td>
                              <input type="text" :value="email" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="module-body">
                  <div class="table">
                    <table
                      class="table table-striped table-bordered table-condensed"
                    >
                      <colgroup>
                        <col width="*" />
                        <col width="*" />
                        <col width="*" />
                      </colgroup>
                      <thead>
                        <h5>아기 정보</h5>
                        <tr>
                          <th>아기 이름</th>
                          <th>성별</th>
                          <th>생년월일</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><input type="text" Value="홍길동" /></td>
                          <td><input type="text" Value="남" /></td>
                          <td>
                            <input class="w60" type="date" id="datepicker1" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="flex_btn alignjust_center mt_10">
                      <nav aria-label="Page navigation "></nav>
                    </div>
                  </div>
                  <div class="table">
                    <table class="table table-bordered">
                      <colgroup>
                        <col width="*%" />
                        <col width="*%" />
                      </colgroup>
                      <tbody>
                        <h5>사진/동영상</h5>
                        <table width="300" height="200"></table>
                        <h5>◎상태 변경</h5>
                        <tr>
                          <th>상태변경</th>
                          <td>
                            <div class="controls">
                              <label class="radio inline">
                                <input
                                  type="radio"
                                  name="optionsRadios2"
                                  id="open_yn"
                                  value="option1"
                                  checked=""
                                />
                                노출중
                              </label>
                              <label class="radio inline">
                                <input
                                  type="radio"
                                  name="optionsRadios2"
                                  id="open_yn"
                                  value="option2"
                                />
                                노출 중지
                              </label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="flex_btn alignjust_right">
                    <a class="btn btn-large btn-warning">저장</a>
                    <a @click="goBack" class="btn btn-large btn-primary"
                      >목록</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- //.content-->
          </div>
          <!-- //.span9-->
        </div>
      </div>
      <!-- //.container-->
    </div>

    <!-- 아래 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeftMenu from "../../components/LeftMenu";

export default {
  name: "DiaryDetail",
  components: {
    Header,
    Footer,
    LeftMenu,
  },
  methods: {
    goBack() {
      var data = this;
      data.$router.replace("/diary/list");
      [2];
    },
  },
};
</script>
