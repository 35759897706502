export default {
  namespaced: true,
  state: {
    cpn: 1,
    admName: "",
    admDept: "",
    admLevel: "",
    admTitle: "",
  },
  getters: {
    getCpn(state) {
      return state.cpn;
    },
    getAdmName(state) {
      return state.admName;
    },
    getAdmDept(state) {
      return state.admDept;
    },
    getAdmLevel(state) {
      return state.admLevel;
    },
    getAdmTitle(state) {
      return state.admTitle;
    },
  },
  mutations: {
    setCpn(state, payload) {
      state.cpn = payload;
    },
    setAdmName(state, payload) {
      state.admName = payload;
    },
    setAdmDept(state, payload) {
      state.admDept = payload;
    },
    setAdmLevel(state, payload) {
      state.admLevel = payload;
    },
    setAdmTitle(state, payload) {
      state.admTitle = payload;
    },
  },
};
